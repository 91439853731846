.employeeBenefits {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 700px;
  //   border: 1px solid red;
  height: 100%;
  overflow-y: scroll;

  & > .backBtn {
    border: 1px solid var(--main-color);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: var(--main-color);
    font-weight: 800;
    width: max-content;
    padding: 0.5rem;
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
  }

  & > .title {
    font-size: 1.5rem;
    font-weight: 550;
    padding-bottom: 0.5rem;
  }

  & > .desc {
    font-size: 0.9rem;
    color: rgba(var(--font-color), 0.2);
  }

  & > .policyDetails {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    padding: 1rem;
    border-radius: 10px;
    cursor: pointer;
    background: var(--opacity-main) 4d;
    transition: all linear 0.3s;

    & > .totalSum {
      & > :nth-child(1) {
        font-size: 0.9rem;
      }
      & > :nth-child(2) {
        font-size: 1.2rem;
        font-weight: 550;
        padding-top: 3px;
      }
    }
    & > .duration {
      & > :nth-child(1) {
        font-size: 0.8rem;
      }
      & > :nth-child(2) {
        font-size: 0.8rem;
        font-weight: 550;
        padding-top: 6px;
        color: var(--main-color);
        transition: all linear 0.3s;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  & > .allBenefits {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    // overflow-y: scroll;

    & > .title {
      font-size: 1rem;
      font-weight: 550;
      padding: 1rem 0;
    }

    & > .eachBenefit {
      display: flex;
      align-items: center;
      gap: 15px;
      padding: 1rem;
      border: 1px solid rgb(233, 230, 242);
      border-radius: 10px;
      cursor: pointer;
      transition: all linear 0.3s;

      //   &:hover {
      //     .desc {
      //       overflow: auto;
      //       display: block;
      //     }
      //   }

      & > .suitIcon {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        border-radius: 10px;
        aspect-ratio: 1/1;
        color: white;
        background: var(--main-color);

        & > svg {
          width: 20px;
          height: 20px;
        }
      }

      & > .content {
        & > .title {
          font-size: 1rem;
          font-weight: 550;
        }

        & > .desc {
          font-size: 0.9rem;
          padding-top: 5px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
        }

        & > .addBtn {
          font-size: 1rem;
          font-weight: 550;
        }
      }
    }
  }
}

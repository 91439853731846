:root {
  --main-color: #010961;
  --opacity-main: #344b72;
  --main-red: #840000;
  --font-color: #636161;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  color: var(--font-color);
  font-family: "Montserrat";
  scrollbar-width: none;
  -ms-overflow-style: none;
}

label {
  all: unset;
}

p {
  all: unset;
}

.routing {
  display: flex;
  height: 100vh;
  background: var(--main-color);
}

.routing > :nth-child(1) {
  width: 22.5%;
  min-width: 22.5%;
}

.routing > :nth-child(2) {
  min-width: 77.5%;
  width: -webkit-fill-available;
}

::-webkit-scrollbar {
  display: none;
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
  -webkit-appearance: none; /* WebKit (Safari/Chrome) */
  -ms-appearance: none; /* Internet Explorer/Edge */
  appearance: none; /* Standard syntax */
}

/* .react-multi-carousel-list {
  left: calc(4% + 1px);
  right: calc(4% + 1px);
} */

/* .react-multiple-carousel__arrow--left {
  left: calc(10% + 1px);
} */

/* general components */

.rahaGreenBtn {
  padding: 0.5rem 1rem;
  color: white;
  background: var(--main-color);
  font-weight: 520;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  transition: all linear 0.3s;
}

.rahaGreenBtn:hover {
  scale: 1.05;
}

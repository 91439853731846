.employeeLogin {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 500px;

  & > .title {
    font-size: 1.5rem;
    font-weight: 550;
    padding-bottom: 0.5rem;
  }

  & > :nth-child(2) {
    display: flex;
    gap: 1rem;

    & > div {
      width: 60px;
      height: 60px;

      & > input {
        padding: 1rem;
        width: 60px !important;
        height: 60px;
        border: none;
        outline: none;
        border-radius: 10px;
        font-size: 1rem;
        aspect-ratio: 1/1;
        position: relative;
        background: rgb(248, 248, 248);
      }
    }
  }

  & > .otpBtn {
    display: flex;
    gap: 5px;
    align-items: center;
    font-size: 0.9rem;

    & > :nth-child(2) {
      color: var(--main-color);
      cursor: pointer;
      transition: all linear 0.3s;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  & > .loginBtn {
    border-radius: 5px;
    padding: 0.5rem;
    width: 100%;
    font-size: 1.1rem;
    background: var(--main-color);
    font-weight: 550;
    color: white;
    cursor: pointer;
    text-align: center;
    margin-top: 2rem;
  }
}

.profile {
  height: 100%;

  & > .navbar {
    padding: 0rem 2rem;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    & > .leftContainer {
      display: flex;

      & > .navbarOption {
        padding: 1.7rem 1.5rem;
        font-size: 1rem;
        font-weight: 400;
        width: max-content;
        cursor: pointer;
      }
      & > .navbarOptionSelected {
        color: var(--main-color);
        font-weight: 600;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          height: 4px;
          left: 0;
          right: 0;
          bottom: 0;
          border-radius: 35px 35px 0 0;
          background: var(--main-color);
        }
      }
    }

    & > .rightContainer {
    }
  }

  & > .brokerageDetails {
    padding: 2rem 2.5rem;
    padding-bottom: 0;
    // overflow-y: scroll;
    height: calc(100% - 5rem);
    background: #8080800d;

    & > .title {
      font-size: 1.5rem;
      font-weight: 500;
      margin-bottom: 2.5rem;
      padding-top: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      & > .btnsContainer {
        font-size: 0.8rem;
        font-weight: 450;

        & > div {
          background: var(--main-color);
          border: 1px solid var(--main-color);
          padding: 0.8rem 2rem;
          border-radius: 35px;
          color: white;
          cursor: pointer;
          transition: all linear 0.3s;

          &:hover {
            color: var(--main-color);
            background: white;
          }
        }
      }
    }

    & > .dropdownItems {
      display: flex;
      align-items: flex-start;
      gap: 1.5rem;
    }

    & > .allLeads {
      border: 1px solid #e5e5e5;
      border-radius: 35px 35px 0 0;
      margin-top: 2rem;
      height: calc(100% - 5rem);
      overflow: hidden;
      background: white;

      & > .nav {
        padding: 0.75rem 1rem;
        border-bottom: 1px solid #e5e5e5;
        // border-bottom: none;
        border-radius: 35px 35px 0 0;
        display: flex;
        gap: 1rem;
        justify-content: space-between;
        align-items: center;

        & > .searchDiv {
          border: 1px solid #e5e5e5;
          border-radius: 35px;
          position: relative;
          width: max-content;

          & > input {
            border-radius: 35px;
            outline: none;
            border: none;
            padding: 0.5rem 3rem;
            font-size: 0.8rem;
            width: 100%;
            max-width: 300px;

            :focus {
              border: 2px solid var(--main-color);
            }

            &::placeholder {
              opacity: 0.4;
            }
          }

          & > svg {
            position: absolute;
            top: 50%;
            left: 1rem;
            transform: translate(0%, -50%);
            height: 15px;
            width: 15px;
          }
        }

        & > .actionBtn {
          display: flex;
          align-items: center;
          gap: 1rem;
          font-size: 0.9rem;
          overflow-y: scroll;

          // &::-webkit-scrollbar {
          //   display: block;
          //   width: 7px; /* Set the width of the table's scrollbar */
          //   position: absolute;
          //   right: 0;
          //   top: 0;
          //   bottom: -3px;
          //   height: 3px;
          // }

          // &::-webkit-scrollbar-thumb {
          //   background-color: var(
          //     --font-color
          //   ); /* Set the color of the scrollbar thumb */
          //   border-radius: 5px; /* Set the border-radius of the thumb */
          //   height: 80px;
          //   transition: all linear 0.3s;
          //   cursor: pointer;
          // }

          // &::-webkit-scrollbar-thumb:hover {
          //   background-color: var(
          //     --main-color
          //   ); /* Set the color when the scrollbar thumb is hovered */
          // }

          & > div {
            border: 1px solid #e5e5e5;
            border-radius: 35px;
            padding: 0.5rem 2rem;
            font-weight: 500;
            cursor: pointer;
            white-space: nowrap;
            transition: all ease-in 0.2s;

            &:hover {
              background: #e5e5e5;
              border: 1px solid var(--main-color);
              color: var(--main-color);
            }
          }

          & > :nth-child(2) {
            color: white;
            background: var(--main-color);
            border: 1px solid var(--main-color);
          }
        }
      }

      // & > .profileTable {
      //   overflow: auto;

      //   & > .header {
      //     display: flex;

      //     & > div {
      //       min-width: 15%;
      //       height: 100%;
      //       border-left: 0.25px solid #e5e5e5;
      //       border-right: 0.25px solid #e5e5e5;
      //       border-bottom: 0.5px solid #e5e5e5;
      //       padding: 1rem;
      //       white-space: nowrap;
      //       width: max-content;
      //     }

      //     & > :nth-child(1) {
      //       width: 10%;
      //       min-width: 10%;
      //     }
      //     & > :nth-child(4) {
      //       min-width: 25%;
      //       width: 25%;
      //     }
      //   }

      //   & > .body {
      //     display: flex;

      //     & > div {
      //       min-width: 15%;
      //       height: 100%;
      //       border-left: 0.25px solid #e5e5e5;
      //       border-right: 0.25px solid #e5e5e5;
      //       border-bottom: 0.5px solid #e5e5e5;
      //       padding: 1rem;
      //       white-space: nowrap;
      //       width: max-content;
      //     }

      //     & > :nth-child(1) {
      //       width: 10%;
      //       min-width: 10%;
      //     }

      //     & > :nth-child(4) {
      //       min-width: 25%;
      //       width: 25%;
      //     }

      //     & > .detailedItem {
      //       & > :nth-child(1) {
      //         width: 1.5rem;
      //         height: 1.5rem;
      //       }

      //       & > :nth-child(2) {
      //         display: flex;
      //         flex-direction: column;
      //         gap: 0.5rem;
      //         justify-content: space-between;

      //         & > .title {
      //           font-size: 1rem;
      //         }
      //         & > .desc {
      //           font-size: 0.8rem;
      //         }
      //       }
      //     }
      //   }
      // }
    }
  }
}

//custom dropdown

.eachCustomDropdown {
  position: relative;
  min-width: 200px;
  height: 3rem;
  cursor: pointer;
  transition: all linear 0.3s;

  & > .inputContainer {
    padding: 1rem 1.5rem;
    border-radius: 35px;
    border: 1px solid #e5e5e566;
    font-size: 0.8rem;
    background: white;
    width: 100%;
    max-width: 500px;
    position: absolute;
    top: 0rem;
    z-index: 2;
    max-height: 400px;
    overflow-y: scroll;
    transition: all ease-in 0.2s;

    & > span {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &:focus {
      outline: var(--main-color);
      border: 1.5px solid var(--main-color);
      background: white;
    }

    & > ul {
      list-style: none;
      cursor: pointer;
      margin: 0;
      margin-top: 1rem;

      & > li {
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: 1rem 0rem;

        & > img {
          width: 20px;
          height: 20px;
          border-radius: 50%;
        }
      }

      & > li:has(input) {
        padding: 0;

        & > input {
          height: 100%;
          width: 100%;
          border: none;
          outline: none;
          padding: 0.75rem 0rem;
          color: var(--font-color);
          background: transparent;
          transition: all ease-in 0.2s;

          :focus {
            border: 2px solid var(--main-color);
          }
        }
      }
    }

    ::-webkit-scrollbar {
      width: 0.5em; /* Adjust the width as needed */
      background-color: transparent; /* Adjust the background color as needed */
      display: none;
    }

    ::-webkit-scrollbar-thumb {
      background-color: transparent; /* Adjust the thumb color as needed */
      display: none;
    }
  }
}

// src/Table.scss
.tableContainer {
  overflow-y: auto;
  height: calc(100% - 4rem);
  width: 100%; /* Set the container width to 100% or any other desired value */
  overflow-x: auto; /* Allow horizontal scrolling */
  // border-left: 0.25px solid #e5e5e5;
  // border-right: 0.25px solid #e5e5e5;
  position: relative;

  &::-webkit-scrollbar {
    display: block;
    width: 7px; /* Set the width of the table's scrollbar */
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    height: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(
      --font-color
    ); /* Set the color of the scrollbar thumb */
    border-radius: 5px; /* Set the border-radius of the thumb */
    height: 80px;
    transition: all linear 0.3s;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: var(
      --main-color
    ); /* Set the color when the scrollbar thumb is hovered */
  }

  table {
    border-collapse: collapse;
    min-width: 100%; /* Set the table width to 110% to ensure horizontal scrollbar */
    margin-bottom: 20px;
    table-layout: fixed; /* Ensures equal column width for horizontally scrolling table */
    width: max-content;

    th,
    td {
      border: 1px solid #dddddd;
      text-align: left;
      white-space: normal;
      word-wrap: break-word;
      padding: 0.75rem 1rem;
      position: relative;
      display: flex;
      align-items: center;
      border-right: none;
      border-top: none;
      font-size: 0.85rem;

      & > input {
        width: 1.25rem;
        height: 1.25rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    th {
      white-space: nowrap;
      font-weight: 550;
      font-size: 0.85rem;
      padding: 0.7rem 1rem;
    }

    thead {
      background: #e1e1e1;
      & > tr {
        & > th {
          border-top: none;
        }
      }
    }

    tr {
      & > :nth-child(1) {
        border-left: none;
        width: 50px;
      }

      & > :last-child {
        border-right: none;
      }
    }

    tr {
      display: grid;
      grid-template-columns: min-content repeat(12, 1fr);

      th,
      td {
      }
    }

    tr > :first-child {
      /* Set the width you want for the first child */
      width: 50px; /* Replace 200px with your desired width */
      /* Position the first child in the first column */
      grid-column: 1;
    }

    .detailedItem {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      justify-content: flex-start;

      & > :nth-child(1) {
        padding: 0.5rem;
        border: 0.5px solid #e5e5e5;
        border-radius: 50%;
        width: 3rem;
        height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;

        & > img {
          width: 70%;
          height: 70%;
        }
      }

      & > :nth-child(2) {
        display: flex;
        flex-direction: column;
        gap: 0.2rem;

        & > :nth-child(1) {
          font-size: 0.9rem;
          font-weight: 550;
          white-space: nowrap;
        }
        & > :nth-child(2) {
          font-size: 0.8rem;
          font-weight: 400;
        }
      }
    }

    td {
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
      // overflow: hidden;
      transition: all linear 0.3s;

      &:hover {
        background: #8080800d;
      }
    }
  }
}

.sidebarMenu {
  width: 45%;
  max-width: 500px;
  position: absolute;
  top: 1px;
  bottom: 0;
  right: -150%;
  background: white;
  border-left: 0.25px solid #e5e5e5;
  border-top: 0.25px solid #e5e5e5;
  border-bottom: 0.25px solid #e5e5e5;
  transition: all linear 0.3s;

  & > .eachItemDetailed {
    height: 100%;
    overflow-y: scroll;
    padding-bottom: 2rem;

    & > .emptyCard {
      height: 100px;
      padding: 1rem;

      & > div {
        font-size: 0.9rem;
        font-weight: 500;
        color: white;
        cursor: pointer;
        transition: all linear 0.3s;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    & > .allDetails {
      position: relative;
      padding: 0 1rem;

      & > .mainDetail {
        display: flex;
        gap: 1rem;
        transform: translateY(-30px);

        & > .eachPolicy {
          border: 1px solid #e5e5e5;
          background: white;
          border-radius: 25px;
          padding: 1rem;
          width: 100%;

          & > .headDiv {
            height: 80px;
            display: flex;
            gap: 1.2rem;

            & > .imageDiv {
              height: 100%;
              aspect-ratio: 1/1;
              border: 1px solid #e5e5e5;
              border-radius: 10px;
              display: flex;
              justify-content: center;
              align-items: center;

              & > img {
                width: 100%;
                object-fit: contain;
              }
            }

            & > .companyDetails {
              display: flex;
              flex-direction: column;
              gap: 3px;
              justify-content: space-evenly;
              padding: 10px;

              & > .title {
                font-size: 0.95rem;
                font-weight: 550;
              }

              & > .company {
                font-size: 0.85rem;
              }
            }
          }

          & > .desc {
            font-size: 1rem;
            padding: 2rem 0;
          }

          & > .moreDetails {
            display: flex;
            justify-content: space-between;
            align-items: center;

            & > .amount {
              & > :nth-child(1) {
                font-size: 0.8rem;
                font-weight: 450;
              }
              & > :nth-child(2) {
                font-size: 1rem;
                font-weight: 600;
                padding-top: 3px;
              }
            }

            & > .btn {
              border: 1px solid #e5e5e5;
              padding: 0.35rem 1.5rem;
              border-radius: 35px;
              font-size: 0.8rem;
              font-weight: 600;
              cursor: pointer;
              display: flex;
              justify-content: center;
              align-items: center;
              transition: all linear 0.3s;

              &:hover {
                color: white;
                background: var(--main-color);
                border: 1px solid var(--main-color);
              }
            }
          }
        }

        & > img {
          background: white;
          border-radius: 20px;
          width: 90px;
          height: 90px;
          border: 0.5px solid #e5e5e5;
        }

        & > div {
          line-height: 2;
          margin-top: auto;
          padding-bottom: 5px;

          & > :nth-child(1) {
            font-size: 1rem;
            font-weight: 500;
          }
          & > :nth-child(2) {
            font-size: 0.75rem;
          }
        }
      }

      & > .desc {
        margin-top: 1rem;

        & > .searchDiv {
          margin-bottom: 2rem;

          & > input {
            padding: 1rem 1.5rem;
            background: #e5e5e540;
            border-radius: 35px;
            outline: none;
            border: none;
            width: 100%;
            font-size: 0.9rem;
          }
        }

        & > .allDesc {
          & > div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            // gap: 25px;
            margin-bottom: 2rem;

            & > :nth-child(1) {
              color: var(--main-color);
              font-size: 2rem;
              font-weight: 600;
              text-align: center;
            }

            & > :nth-child(2) {
              font-size: 0.9rem;
              line-height: 1.5;
              width: 85%;
            }
          }
        }

        & > .title {
          font-size: 0.9rem;
          font-weight: 500;
        }

        & > .para {
          font-size: 0.85rem;
          margin-top: 1.2rem;
          line-height: 1.6;
        }
      }

      & > .offerings {
        margin-top: 2.5rem;

        & > .title {
          font-size: 0.9rem;
          font-weight: 500;
        }

        & > .allOfferings {
          margin-top: 1.2rem;

          & > .eachOffering {
            margin-bottom: 1rem;
            border: 1px solid #e5e5e5;
            border-radius: 20px;
            padding: 1rem;
            display: flex;
            gap: 1rem;

            & > :nth-child(1) {
              width: 100px;
              height: 100px;
              padding: 1rem;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 10px;

              & > svg {
                height: 100%;
              }
            }

            & > :nth-child(2) {
              display: flex;
              flex-direction: column;
              justify-content: space-evenly;

              & > :nth-child(1) {
                & > .title {
                  font-size: 0.9rem;
                  font-weight: 500;
                }

                & > .learnBtn {
                  font-size: 0.7rem;
                  font-weight: 500;
                  text-decoration: underline;
                  margin-top: 10px;
                  cursor: pointer;
                  transition: all linear 0.3s;

                  &:hover {
                    color: #04bfda;
                  }
                }
              }

              & > .priceDiv {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: auto;

                & > :nth-child(1) {
                  font-size: 0.9rem;
                  font-weight: 500;

                  & > span {
                    text-decoration: line-through;
                    opacity: 0.5;
                    margin-left: 5px;
                  }
                }

                & > .btn {
                  font-size: 0.6rem;
                  padding: 7px 1.5rem;
                  border-radius: 35px;
                  cursor: pointer;
                  border: 1px solid #e5e5e5;
                  transition: all linear 0.3s;

                  &:hover {
                    background: #04bfda;
                    color: white;
                    border: 1px solid #04bfda;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  & > .allWellnessItems {
    padding: 1.5rem;

    & > .title {
      font-size: 1.2rem;
      font-weight: 550;
      padding: 1rem 0 2rem 0;
    }

    & > .allItems {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      justify-content: space-between;
      gap: 1.5rem;
      flex-wrap: wrap;

      & > .eachWellnessItem {
        max-width: 150px;
        cursor: pointer;
        transition: all linear 0.3s;

        & > img {
          width: 100%;
          border-radius: 30px;
          aspect-ratio: 1/1;
          transition: all linear 0.3s;
          border: 0.5px solid #e5e5e5;
        }

        & > div {
          font-size: 1rem;
          font-weight: 500;
          padding: 1rem 0;
          text-align: center;
          transition: all linear 0.3s;
        }

        &:hover {
          & > img {
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
          }

          & > div {
            font-weight: 550;
          }
        }
      }
    }
  }

  & > .dependentsContainer {
    padding: 2rem;

    & > .title {
      font-size: 1.4rem;
      font-weight: 500;
    }

    & > .allDependents {
      margin-top: 2rem;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      & > .eachDependent {
        font-size: 0.9rem;
        font-weight: 500;
        border: 1px solid #e5e5e5;
        border-radius: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2rem 1.5rem;
        transition: all linear 0.3s;

        &:hover {
          border: 1px solid var(--main-color);
        }
      }
    }
  }
}

.careerContainer {
  height: 100vh;
  display: grid;
  grid-template-columns: 30% 70%;
}
.leftSide {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url("./office.jpeg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.rightSide {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: center;
  padding: 0px 20%;
}
.subTitle {
  color: var(--main-color);
  font-family: "Avenir", "Sen", sans-serif;
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  line-height: 65px; /* 185.714% */
}
.title {
  color: var(--opacity-main);
  font-family: "Avenir", "Sen", sans-serif;
  font-size: 45px;
  font-style: normal;
  font-weight: 600;
  line-height: 55px; /* 122.222% */
  padding-bottom: 2rem;
  padding-top: 1rem;
}
.text {
  color: var(--main-color);
  font-family: "Avenir", "Sen", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px; /* 181.818% */
}

// media query
@media (max-width: 768px) {
  .careerContainer {
    // grid-template-columns: 100%;
    display: flex;
    flex-direction: column;
  }
  .leftSide {
    // display: none;
  }
  .rightSide {
    padding: 0px 10%;
  }
  .title {
    font-size: 35px;
    line-height: 45px;
  }
  .subTitle {
    font-size: 25px;
    line-height: 35px;
  }
  .text {
    font-size: 18px;
    line-height: 28px;
  }
}

.homeMobile {
  & > .landingContainer {
    height: 100vh;
    background: var(--main-color);
    display: flex;
    justify-content: center;
    padding-top: 175px;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;

    & > .landingImage {
      width: calc(100% - 20px);
      object-fit: contain;
    }

    & > .title {
      font-size: 2.25rem;
      font-weight: 550;
      color: white;
      text-align: center;
      margin-top: 1rem;
      line-height: 1.2;
    }

    & > .description {
      font-size: 1.25rem;
      font-weight: 550;
      color: white;
      text-align: center;
      margin-top: 1.2rem;
    }

    & > .requestBtn {
      border: 1.5px solid white;
      border-radius: 35px;
      cursor: pointer;
      margin-top: 1.2rem;
      width: max-content;
      padding: 0.75rem 1.5rem;
      font-size: 1.2rem;
      font-weight: 550;
      display: flex;
      align-items: center;
      background: white;
      color: var(--main-color);
      gap: 1rem;

      & > svg {
        fill: var(--main-color);
        margin-left: 15px;
      }
    }
  }
}

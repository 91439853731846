.adminResetPasswordContainer {
  position: fixed;
  inset: 0;
  z-index: 10;
  background: #00000080;
  display: flex;
  justify-content: center;
  align-items: center;

  & > .formContainer {
    padding: 2rem;
    aspect-ratio: 16 / 5;
    background: white;
    border-radius: 7px;
    min-width: 650px;

    & > .title {
      font-size: 1.2rem;
      font-weight: 520;
    }

    & > .inputFields {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1.5rem;
      margin: 2rem 0;

      .noInput {
        pointer-events: none;
      }

      & > .passwordInput {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        position: relative;

        & > .title {
          font-size: 0.9rem;
          margin-bottom: 10px;
          font-weight: 500;
        }

        & > .inputContainer {
          position: relative;

          & > .inputBox {
            padding: 1rem 1.5rem;
            border-radius: 10px;
            border: 1px solid rgba(229, 229, 229, 0.4);
            font-size: 0.8rem;
            background: #ffffff;
            width: 100%;
          }

          & > .validationIcon {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: #dc3027;
            position: absolute;
            top: 50%;
            right: 1rem;
            transform: translate(-50%, -50%);
          }
        }
      }
    }

    & > .submitBtn {
      padding: 0.8rem 1.5rem;
      border-radius: 10px;
      font-size: 1rem;
      font-weight: 520;
      cursor: pointer;
      background: var(--main-color);
      color: white;
      text-align: center;
    }

    & > .notClickable {
      pointer-events: none;
      opacity: 0.6;
    }
  }
}

.eachInput {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;

  & > .asteriskIcon {
    position: absolute;
    right: 10px;
    top: 10px;
    height: 10px;
  }

  & > .title {
    font-size: 0.9rem;
    margin-bottom: 10px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > span {
      cursor: pointer;

      & > svg {
        font-size: 0.9rem;
      }
    }
  }

  & > div {
    display: flex;
    gap: 0rem;
    align-items: center;
    position: relative;

    & > .submitBtn {
      font-size: 0.8rem;
      position: absolute;
      padding: 6.5px 1.2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      color: white;
      background: var(--main-color);
      top: 50%;
      right: 10px;
      transform: translate(0%, -50%);
      cursor: pointer;
    }

    & > img {
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
    }

    & > .inputContainer {
      padding: 1rem 1.5rem;
      border-radius: 10px;
      border: 1px solid #e5e5e566;
      font-size: 0.8rem;
      background: #ffffff;
      // margin-top: 1rem;
      width: 100%;

      &:focus {
        // outline: var(--main-color);
        background: white;
        border: 1px solid var(--main-color);
      }
    }
  }
}

.eachInputDatePicker {
  & > .title {
    font-size: 0.9rem;
    position: relative;
    // width: max-content;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    & > span {
      cursor: pointer;

      & > svg {
        font-size: 0.9rem;
      }
    }

    & > img {
      position: absolute;
      top: 1px;
      right: -0.95rem;
      width: 0.5rem;
      height: 0.5rem;
    }
  }

  & > .inputContainer {
    color: var(--font-color);
    padding: 1rem 1.5rem;
    border-radius: 10px;
    border: 1px solid rgba(229, 229, 229, 0.4);
    font-size: 0.8rem;
    background: white;
    margin-top: 1rem;
    width: 100%;
    max-width: 500px;
    transition: all linear 0.3s;

    // &:focus {
    //   background: white;
    //   border: 1.5px solid var(--main-color);
    // }
    // &:hover {
    //   background: white;
    //   border: 1.5px solid var(--main-color);
    // }
  }

  & > .asteriskIcon {
    position: absolute;
    right: 10px;
    top: 10px;
    height: 10px;
  }
}

.eachCustomDropdown {
  position: relative;
  min-width: 200px;
  // height: 3.2rem;
  cursor: pointer;
  width: 100%;
  transition: all linear 0.3s;

  & > .title {
    font-size: 0.9rem;
    position: relative;
    // width: max-content;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 63px;

    & > span {
      cursor: pointer;

      & > svg {
        font-size: 0.9rem;
      }
    }
  }

  & > .inputContainer {
    padding: 0.95rem 1.5rem;
    border-radius: 10px;
    // border: 1px solid #e1e1e1;
    font-size: 0.8rem;
    background: white;
    width: 100%;
    position: absolute !important;
    top: 34px;
    z-index: 2;
    max-height: 400px;
    overflow-y: scroll;
    transition: all ease-in 0.2s;

    & > span {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &:focus {
      outline: var(--main-color);
      background: white;
      border: 1.5px solid var(--main-color);
    }

    & > ul {
      list-style: none;
      cursor: pointer;
      margin: 0;
      margin-top: 1rem;

      & > li {
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: 1rem 0rem;

        & > img {
          width: 20px;
          height: 20px;
          border-radius: 50%;
        }
      }

      & > li:has(input) {
        padding: 0;

        & > input {
          height: 100%;
          width: 100%;
          border: none;
          outline: none;
          padding: 0.75rem 0rem;
          color: var(--font-color);
          background: transparent;
          transition: all ease-in 0.2s;

          :focus {
            border: 2px solid var(--main-color);
          }
        }
      }
    }

    ::-webkit-scrollbar {
      width: 0.5em; /* Adjust the width as needed */
      background-color: transparent; /* Adjust the background color as needed */
      display: none;
    }

    ::-webkit-scrollbar-thumb {
      background-color: transparent; /* Adjust the thumb color as needed */
      display: none;
    }
  }

  & > .asteriskIcon {
    position: absolute;
    right: 10px;
    top: 10px;
    height: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .eachCustomDropdown {
    & > .inputContainer {
      border: 1px solid #e1e1e1;
    }
  }
}

.discoverTab {
  height: 100%;
  overflow-y: scroll;

  & > .subTabs {
    display: flex;
    gap: 1rem;

    & > div {
      font-size: 1rem;
      cursor: pointer;
      padding-bottom: 5px;
      transition: all linear 0.3s;
    }

    & > .selectedTab {
      border-bottom: 1px solid var(--font-color);
    }
  }

  & > .filterDiv {
    display: flex;
    justify-content: space-between;
    margin: 2rem 0;

    & > .searchDiv {
      display: flex;
      border: 1px solid #e5e5e5;
      border-radius: 10px;
      width: 60%;
      max-width: 550px;

      & > .searchDropdown {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem 1rem;
        width: 100px;
        background: white;
        border-radius: 10px 0 0 10px;
        border-right: 1px solid #e5e5e5;
      }

      & > .inputContainer {
        width: -webkit-fill-available;
        padding: 0.5rem 1rem;
        border-radius: 10px;
        outline: none;
        border: none;
      }
    }

    & > .locationFilter {
      display: flex;
      align-items: center;
      font-size: 0.9rem;
      gap: 5px;

      & > label {
      }

      & > .city {
        font-weight: 550;
        text-decoration: underline;
        cursor: pointer;
      }

      & > svg {
        cursor: pointer;
      }
    }
  }

  & > .allTests {
    // display: flex;
    // flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    justify-content: space-between;
    gap: 1.5rem;
    gap: 1rem;

    & > .eachTest {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      background: #e1e1e1;
      border-radius: 10px;
      aspect-ratio: 1/1;
      width: 100%;
      //   min-width: 150px;
      padding: 1.2rem;
      transition: all linear 0.3s;

      & > img {
        height: 5rem;
        margin-bottom: 10px;
      }

      & > .title {
        font-size: 0.95rem;
        text-align: center;
        font-weight: 500;
      }
    }

    & > .selectedTest {
      background: var(--main-color);
      color: white;
    }
  }

  & > .buytests {
    margin-top: 2rem;

    & > .title {
      font-size: 1rem;
      font-weight: 550;
      margin-bottom: 2rem;
    }

    & > .allTestsBuy {
      display: flex;
      gap: 1.2rem;
      overflow-x: scroll;
      padding-bottom: 2rem;

      & > .eachTest {
        border: 1px solid #e1e1e1;
        border-radius: 10px;

        & > .testDesc {
          padding: 1.2rem;
          display: flex;
          gap: 10px;

          & > img {
            border-radius: 10px;
            height: 100%;
            aspect-ratio: 1/1;
          }

          & > :nth-child(2) {
            font-size: 0.9rem;
            display: flex;
            flex-direction: column;
            gap: 5px;

            & > .title {
              font-weight: 550;
            }

            & > .desc {
            }

            & > .price {
              display: flex;
              justify-content: flex-end;
              font-weight: 550;
              margin-top: auto;
            }
          }
        }

        & > .btnsContainer {
          display: flex;
          font-size: 0.9rem;
          border-top: 1px solid #e1e1e1;

          & > div {
            width: 50%;
            text-align: center;
            cursor: pointer;
            font-weight: 550;
            padding: 1rem;
          }

          & > :nth-child(1) {
            border-right: 1px solid #e1e1e1;
          }
        }
      }
    }
  }
}

.clientOnboardingForm {
  overflow-y: scroll;
  height: 100%;
  padding-bottom: 2rem;

  & > .title {
    font-size: 1.5rem;
    font-weight: 550;
    margin-bottom: 1rem;
  }

  .subTitles {
    font-size: 1.2rem;
    font-weight: 550;
    margin-top: 1rem;
  }

  .desc {
    font-size: 0.8rem;
  }

  .tableContainer {
    overflow: scroll;

    .tableParent {
      // width: 100%;
      border: 1px solid #e5e5e5;
      border-radius: 10px;
      margin-top: 1rem;
      overflow: scroll;

      thead {
        background: #e1e1e1;
        border-radius: 10px 10px 0 0;
        width: 140%;

        & > tr {
          border-radius: 10px 10px 0 0;
        }
      }

      tbody {
        width: 140%;
        & > tr {
          border-top: 1px solid #e5e5e5;
          background: white;
        }
      }

      th,
      td {
        padding: 1rem 0.5rem;
        white-space: nowrap;
      }

      th {
        text-align: left;
        font-weight: 550;
      }

      tr {
        display: grid;
        grid-template-columns: 40px 100px repeat(5, 1fr) 60px;
        padding: 0 0.5rem;

        & > :first-child {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          font-size: 1.2rem;
        }

        & > :nth-child(2) {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        & > :last-child {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          font-size: 1.2rem;
        }
      }
    }
  }

  & > .formFields {
    font-size: 0.9rem;

    .flexContainer {
      display: flex;
      align-items: center;
      gap: 1rem;

      & > div {
        width: 50%;
      }
    }

    .eachFieldEqual {
      display: flex;
      align-items: center;
      gap: 1.2rem;

      & > .title {
        width: max-content;
        font-size: 0.9rem;
        font-weight: 550;
        text-align: left;
      }

      & > div {
        width: -webkit-fill-available;
      }
    }

    & > .basicDetails {
      display: flex;
      gap: 1rem;

      & > .companyDetails {
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
        width: 50%;

        & > .logoUpload {
          display: flex;
          align-items: center;
          gap: 1rem;

          & > span {
            font-weight: 500;
          }
        }
      }

      & > .documentForm {
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        & > .flexContainerThree {
          display: flex;
          gap: 1rem;
          align-items: center;

          & > svg {
            font-size: 1.5rem;
          }

          & > div {
            width: 45%;
          }
        }
      }
    }

    & > .newSection {
      margin-top: 1rem;

      & > .policyFields {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1.2rem;
        margin-top: 1rem;

        & > .eachField {
          display: flex;
          align-items: center;
          gap: 1rem;

          & > .title {
            width: 150px;
            font-size: 0.9rem;
            font-weight: 550;
            text-align: left;
          }

          & > div {
            width: -webkit-fill-available;
          }
        }
      }

      & > .footerBoxes {
        display: flex;
        gap: 1.2rem;

        & > div {
          display: flex;
          gap: 1rem;
          width: 50%;
          padding: 1rem;
          border-radius: 10px;
          background: #d9d9d94d;
        }

        & > :nth-child(1) {
          & > :nth-child(1) {
            display: flex;
            flex-direction: column;
            gap: 1rem;
          }
        }

        & > :nth-child(2) {
          flex-direction: column;
        }
        .eachField {
          display: flex;
          align-items: center;
          gap: 1rem;

          & > .title {
            width: 150px;
            font-size: 0.9rem;
            font-weight: 550;
            text-align: left;
          }

          & > div {
            width: -webkit-fill-available;
          }
        }
      }
    }

    & > .checkBox {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin: 2rem 0;

      & > p {
        font-size: 1rem;
        margin-bottom: 0;
      }

      & > div {
        display: flex;
        gap: 10px;
        align-items: center;

        & > label {
          display: flex;
          gap: 5px;
          align-items: center;
        }
      }
    }
  }
}

.mobileNavbar {
  position: relative;

  & > .mainContainer {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background: var(--main-color);

    & > img {
      height: 27px;
      object-fit: contain;
    }

    & > div {
      & > svg {
        font-size: 1.25rem;
        color: white;
      }
    }
  }

  & > .navContainer {
    height: calc(100vh - 50px);
    background: white;
    padding: 2rem 10px;
    position: absolute;
    z-index: 2;
    top: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    & > .eachNavigation {
      color: var(--main-color);
      font-weight: 510;
      font-size: 1.2rem;
      background: var(--main-color);
      color: white;
      border-radius: 8px;
      padding: 0.7rem 1rem;
      text-align: center;
      margin-top: auto;
    }
  }
}

.newQuote {
  & > .loginNavbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    padding: 0 2rem;

    & > img {
      height: 50%;
    }

    & > .backBtn {
      cursor: pointer;
      font-size: 1rem;
      font-weight: 500;
      transition: all linear 0.3s;

      &:hover {
        color: var(--main-color);
      }
    }
  }

  & > .mainGrid {
    height: calc(100vh - 140px);
    background: var(--main-color);
    display: flex;
    // flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    // overflow-y: scroll;

    & > .rightSide {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      height: 100%;
      // min-width: 35%;
      flex-direction: column;
      padding-top: 2rem;
      padding-left: 2rem;
      overflow: hidden;

      & > .welcomeContainer {
        color: white;
        // text-align: center;
        display: flex;
        flex-direction: column;
        // justify-content: center;
        // align-items: center;
        padding-bottom: 1rem;

        & > .desc {
          font-size: 2rem;
          font-weight: 520;
          margin-top: 0.6rem;
          width: 90%;
          max-width: 400px;
        }
      }

      & > img {
        width: 100%;
        margin-top: auto;
        max-height: 60%;
      }
    }

    & > .formParent {
      padding: 1rem;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 65%;
      min-width: 700px;
      height: 100%;
      padding-right: 2rem;
      position: relative;

      & > .formContainer {
        background: #fff;
        border-radius: 20px;
        box-shadow: 0px 4px 4px 0px #00000040;
        display: flex;
        //   max-height: calc(100% - 100px);
        //   overflow-y: scroll;
        width: 95%;
        // max-height: 95%;

        & > .loadingContainer {
          position: absolute;
          inset: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 9;
          pointer-events: none;

          & > img {
            height: 5rem;
            animation: heartbeat 1.3s infinite;
          }
          @keyframes heartbeat {
            0% {
              transform: scale(0.75);
            }
            20% {
              transform: scale(1);
            }
            40% {
              transform: scale(0.75);
            }
            60% {
              transform: scale(1);
            }
            80% {
              transform: scale(0.75);
            }
            100% {
              transform: scale(0.75);
            }
          }
        }

        & > .leftContainer {
          width: 70%;
          color: #4c4c4c;
          // padding-bottom: 2rem;
          // height: 100%;
          position: relative;
          padding-bottom: 4rem;

          & > .header {
            padding: 0.8rem 1.2rem;
            font-size: 1.25rem;
            font-weight: 520;
            border-bottom: 1px solid #c9c6c6;
          }

          & > .form {
            padding: 1rem 1.2rem;
            display: flex;
            flex-direction: column;
            gap: 0.55rem;
            // grid-template-areas:
            //   "companyName firstName lastName"
            //   "depName depName renewal dueDate"
            //   " designation designation"
            //   "email email phoneNumber phoneNumber"
            //   "employeeSize famDefinition famDefinition famDefinition"
            //   "pincode city city state";
            width: 100%;

            .threeFields {
              display: flex;
              gap: 0.55rem;
              align-items: center;
              justify-content: space-between;

              & > div {
                width: 33.33%;

                & > div {
                  & > input {
                    border: 1px solid #e1e1e1;
                  }
                }
              }
            }

            .twoFields {
              display: flex;
              gap: 0.55rem;
              align-items: center;
              justify-content: space-between;

              & > div {
                width: 50%;

                & > div {
                  & > input {
                    border: 1px solid #e1e1e1;
                  }
                }
              }
            }
            .fourFields {
              display: flex;
              gap: 0.55rem;
              align-items: center;
              justify-content: space-between;

              & > div {
                width: 25%;
                min-width: unset;

                & > div {
                  & > input {
                    border: 1px solid #e1e1e1;
                  }
                }
              }

              & > :last-child {
                & > div {
                  min-width: unset;

                  input {
                    margin-top: 0 !important;
                  }
                }
              }
            }

            .mobileNumberFields {
              display: flex;
              gap: 10px;
              align-items: center;
              justify-content: space-between;

              & > :nth-child(1) {
                width: 60px;

                input {
                  padding: 1rem !important;
                }
              }

              & > :nth-child(2) {
                width: calc(100% - 60px);
              }
            }

            li {
              padding: 5px 0 !important;
              margin-bottom: 7px !important;

              input {
                padding: 5px 0 !important;
              }
            }

            // & > div {
            //   // padding: 1rem 3rem;
            //   // border: 1px solid #c9c6c6;
            //   border-radius: 7px;
            //   height: unset;
            //   min-width: 120px;

            //   li {
            //     input {
            //       border: none;
            //     }
            //   }

            //   & > div {
            //     border-radius: 7px;
            //     // padding: 0.6rem 1rem !important;
            //   }

            //   span {
            //     text-overflow: ellipsis;
            //     white-space: nowrap;
            //     overflow: hidden;
            //   }

            //   input {
            //     border: 1.5px solid #e1e1e1;
            //     padding: 0.6rem 1rem !important;
            //   }

            //   li {
            //     padding: 5px 0 !important;
            //     margin-bottom: 7px !important;

            //     input {
            //       padding: 0 !important;
            //     }
            //   }
            // }

            // & > :nth-child(1) {
            //   grid-area: depName;

            //   & > div {
            //     padding: 0.6rem 1rem !important;
            //   }
            // }
            // & > :nth-child(2) {
            //   grid-area: renewal;

            //   & > div {
            //     padding: 0.6rem 1rem !important;
            //   }
            // }
            // & > :nth-child(3) {
            //   grid-area: dueDate;

            //   & > div {
            //     height: 2.6rem;
            //     & > div {
            //       border-radius: 7px;
            //       padding: 0.6rem 1rem !important;
            //     }
            //   }

            //   input {
            //     margin-top: 0;
            //     padding: 0.6rem 1rem !important;
            //   }
            // }
            // & > :nth-child(4) {
            //   grid-area: firstName;
            // }
            // & > :nth-child(5) {
            //   grid-area: lastName;
            // }
            // & > :nth-child(6) {
            //   grid-area: companyName;
            // }
            // & > :nth-child(7) {
            //   grid-area: designation;
            // }
            // & > :nth-child(8) {
            //   grid-area: email;
            // }
            // & > :nth-child(9) {
            //   grid-area: phoneNumber;
            //   padding: 0;
            //   display: flex;
            //   gap: 8px;
            //   border: none;

            //   & > div {
            //     border-radius: 7px;
            //   }

            //   & > :nth-child(1) {
            //     width: 105px;
            //   }

            //   & > :nth-child(2) {
            //     width: -webkit-fill-available;
            //   }
            // }
            // & > :nth-child(10) {
            //   grid-area: employeeSize;
            // }
            // & > :nth-child(11) {
            //   grid-area: famDefinition;

            //   & > div {
            //     padding: 0.6rem 1rem !important;
            //   }
            // }
            // & > :nth-child(12) {
            //   grid-area: pincode;
            // }
            // & > :nth-child(13) {
            //   grid-area: city;
            // }
            // & > :nth-child(14) {
            //   grid-area: state;
            // }
          }

          & > .checkBox {
            font-size: 0.9rem;
            font-weight: 400;
            display: flex;
            gap: 10px;
            margin: 0.2rem 0;
            margin-left: 1.4rem;

            & > input {
              width: 1.7rem;
            }

            & > div {
              & > span {
                color: #d0004b;
                cursor: pointer;

                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }

          & > .submitBtn {
            padding: 0.4rem 2rem;
            background: var(--main-color);
            color: #ffffff;
            border-radius: 35px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            width: max-content;
            // margin-left: auto;
            // margin-right: 1.2rem;
            font-weight: 500;
            margin-top: auto;
            font-size: 0.9rem;
            position: absolute;
            right: 1.2rem;
            bottom: 2rem;
          }

          & > .successMessage {
            position: absolute;
            inset: 0;
            z-index: 5;
            background: white;
            border-radius: 35px;
            padding: 2rem 2.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 1rem;

            & > img {
              width: 60px;
            }

            & > .title {
              font-size: 1.5rem;
              font-weight: 500;
              text-align: center;
            }

            & > .desc {
              font-size: 1.2rem;
              font-weight: 550;
              color: var(--main-color);
              text-align: center;
            }

            & > .stepsList {
              // width: max-content;
              line-height: 1.85;
              font-size: 0.95rem;
              margin-bottom: 0;

              & > li {
                & > span {
                  & > span {
                    color: var(--main-color);
                    font-weight: 550;
                    padding: 2px;
                    cursor: pointer;
                    border-bottom: 1.5px solid transparent;
                    transition: all linear 0.1s;

                    &:hover {
                      border-bottom: 1.5px solid var(--main-color);
                    }
                  }
                }
              }
            }
          }
        }

        & > .rightContainer {
          width: 30%;
          // height: 100%;
          border-left: 1px solid #c9c6c6;
          padding: 7px;
          border-radius: 15px;

          & > .topDivider {
            background: var(--main-color);
            border-radius: 15px 15px 0 0;
            height: 100px;
            position: relative;

            & > img {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translateX(-50%);
              height: 120px;
              width: 120px;
              border-radius: 50%;
            }
          }

          & > .bottomContent {
            padding: 0 1rem;
            padding-top: 90px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 1rem;
            height: calc(100% - 100px);
            padding-bottom: calc(2rem - 7px);

            & > .name {
              font-size: 1.2rem;
              font-weight: 550;
              text-align: center;
            }

            & > .desc {
              font-size: 0.9rem;
              text-align: center;
            }

            & > .btnsContainer {
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 0.6rem;

              & > div {
                padding: 0.4rem 2rem;
                text-align: center;
                border-radius: 35px;
                font-size: 0.9rem;
                font-weight: 500;
                cursor: pointer;
                transition: all linear 0.3s;
                display: flex;
                //   justify-content: center;
                align-items: center;
                width: max-content;

                & > img {
                  width: 12px;
                  height: 12px;
                  margin-right: 10px;
                }

                &:hover {
                  scale: 1.05;
                }
              }

              & > :nth-child(1) {
                border: 1px solid #e5e5e5;
              }
              & > :nth-child(2) {
                background: var(--main-color);
                color: #ffffff;
              }
            }
          }
        }
      }
    }
  }

  & > .loginFooter {
    background: var(--font-color);
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2.5px;
    font-size: 0.65rem;
    font-weight: 450;
    color: white;
  }
}

@media screen and (max-width: 951px) {
  .newQuote {
    & > .mainGrid {
      & > .rightSide {
        display: none;
      }
    }
  }
}

.tempLoginEmployees {
  display: flex;
  height: 100vh;
  width: 100vw;

  .leftSide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 50%;
    position: relative;
    height: 100%;

    & > .headerContainer {
      position: absolute;
      top: 2.5rem;
      left: 2.5rem;
      right: 2.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > img {
        height: 4rem;
      }

      & > .stepTracker {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 3rem;
        font-size: 0.9rem;
        width: 50%;
        max-width: 250px;

        &::before {
          content: "";
          position: absolute;
          height: 1.5px;
          width: 100%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: #e1e1e1;
        }

        & > div {
          width: 2rem;
          height: 2rem;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          font-weight: 500;
          background: #e1e1e1;

          & > .stepTitle {
            position: absolute;
            bottom: -3.5rem;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 0.7rem;
            text-align: center;
          }
        }

        & > .currentStep {
          background: var(--main-color);
          color: white;

          & > .stepTitle {
            color: var(--font-color);
          }
        }
      }
    }

    & > .submitBtn {
      margin-top: auto;
      padding: 0.6rem 2.5rem;
      border-radius: 35px;
      background: var(--main-color);
      color: white;
      font-weight: 500;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: auto;
      margin-bottom: 2rem;
      margin-left: 2rem;
      transition: all linear 0.3s;

      &:hover {
        scale: 1.05;
      }
    }
  }

  .rightSide {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-width: 50%;
    padding: 1rem;

    & > .mainContainer {
      width: 100%;
      height: 100%;
      background: var(--main-color);
      border-radius: 20px;
      padding: 3rem 2.5rem;
      padding-bottom: 1rem;
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2rem;

      & > .title {
        font-size: 2rem;
        font-weight: 550;
      }

      & > .desc {
        font-size: 1.2rem;
        font-weight: 500;
        text-align: center;
      }

      & > .toggleSections {
        display: flex;
        justify-content: center;
        gap: 1rem;
        width: 100%;

        & > div {
          background: inherit;
          border: 1.5px solid white;
          border-radius: 35px;
          color: white;
          padding: 0.5rem 1.2rem;
          width: max-content;
          font-size: 0.8rem;
          font-weight: 550;
          height: max-content;
          white-space: nowrap;
          text-align: center;
          cursor: pointer;
        }

        & > .selectedToggle {
          color: var(--main-color);
          background: white;
        }
      }

      & > .sectionDetails {
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        & > .eachDetail {
          background: rgba(255, 255, 255, 0.6);
          width: 100%;
          padding: 1rem;
          border-radius: 35px;
          font-size: 1rem;
          font-weight: 550;
          line-height: 1.6;
          display: flex;
          gap: 1.5rem;
          align-items: center;

          & > img {
            height: 100%;
            object-fit: contain;
          }

          & > span {
            color: var(--main-color);
          }
        }
      }

      & > .allInsurance {
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
        width: 100%;
        overflow-y: scroll;
        padding-right: 10px;

        &::-webkit-scrollbar {
          display: block;
          width: 7px; /* Set the width of the table's scrollbar */
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          height: 7px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: var(
            --font-color
          ); /* Set the color of the scrollbar thumb */
          border-radius: 5px; /* Set the border-radius of the thumb */
          height: 80px;
          transition: all linear 0.3s;
          cursor: pointer;
        }

        &::-webkit-scrollbar-thumb:hover {
          background-color: var(
            --main-color
          ); /* Set the color when the scrollbar thumb is hovered */
        }

        & > .eachInsurance {
          margin: 1rem 0;
          width: 100%;

          & > .title {
            font-weight: 520;
            margin: 1rem 0;
          }

          & > .boxContainer {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            padding: 1rem;
            border-radius: 10px;
            background: #ffffff99;
            color: var(--main-color);
            padding-bottom: 1.5rem;

            & > .eachItem {
              font-size: 0.9rem;
              font-weight: 520;

              & > .title {
                margin: 0.5rem 0;
              }

              & > .valueItem {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0.6rem 1rem;
                background: #ffffff99;
                border-radius: 10px;

                & > span {
                  display: flex;
                  align-items: center;
                }
              }
            }
          }
        }
      }

      & > .allProducts {
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
        width: 100%;
        // overflow-y: scroll;
        padding-right: 10px;
        height: calc(100% - 13rem);

        &::-webkit-scrollbar {
          display: block;
          width: 7px; /* Set the width of the table's scrollbar */
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          height: 7px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: var(
            --font-color
          ); /* Set the color of the scrollbar thumb */
          border-radius: 5px; /* Set the border-radius of the thumb */
          height: 80px;
          transition: all linear 0.3s;
          cursor: pointer;
        }

        &::-webkit-scrollbar-thumb:hover {
          background-color: var(
            --main-color
          ); /* Set the color when the scrollbar thumb is hovered */
        }

        th,
        td {
          padding: 0.6rem 1rem;
          display: flex;
          // justify-content: center;
          align-items: center;
        }

        & > thead {
          background: rgba(255, 255, 255, 0.4);
          color: var(--main-color);
          display: flex;
          align-items: left;

          & > :nth-child(1),
          :nth-child(2) {
            width: 40%;
            text-align: left;
          }

          & > :last-child {
            width: 20%;
            text-align: center;

            & > input {
              height: 2rem;
              border: 1px solid white;
              background: var(--main-color);
            }
          }
        }

        & > tbody {
          overflow-y: scroll;
          & > tr {
            display: flex;
            width: 100%;

            & > :nth-child(1),
            :nth-child(2) {
              width: 40%;
              text-align: left;
            }

            & > :last-child {
              width: 20%;
              text-align: center;

              & > input {
                height: 2rem;
                border: 1px solid white;
                background: var(--main-color);
              }
            }
          }
        }
      }
    }

    & > .successContainer {
      background: white;
      width: 45%;
      height: 90%;
      border-radius: 20px;
      padding: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2rem;

      & > img {
        height: 6rem;
      }

      & > .title {
        font-size: 1.5rem;
        font-weight: 520;
      }

      & > ol {
        list-style: decimal;
        font-size: 1rem;
      }

      & > .submitBtn {
        margin-top: 2rem;
        padding: 0.6rem 2.5rem;
        border-radius: 35px;
        background: var(--main-color);
        color: white;
        font-weight: 500;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: max-content;
        transition: all linear 0.3s;

        &:hover {
          scale: 1.05;
        }
      }
    }
  }

  .inputFields {
    width: 60%;
    max-width: 400px;

    .conditions {
      width: 100%;
      margin-top: 1.5rem;
      background: #fffdf2;
      border-radius: 15px;
      display: flex;
      flex-direction: column;

      .eachCondition {
        display: flex;
        width: 100%;
        padding: 10px 23px;
        align-items: center;
        justify-content: space-between;
        font-size: 0.75rem;
        font-weight: 450;
        .indicator {
          width: 7px;
          height: 7px;
          background: rgba(220, 48, 39, 0.37);
          border: none;
          border-radius: 50%;
        }
      }
    }

    & > .requirmentsBtn {
      text-align: center;
      margin-top: 2rem;
      text-decoration: underline;
      font-size: 1rem;
      cursor: pointer;
      transition: all linear 0.3s;

      &:hover {
        scale: 1.05;
      }
    }

    & > .backBtn {
      width: 100%;
      padding: 0.75rem 1rem;
      color: var(--font-color);
      border: 1px solid #e5e5e5;
      font-weight: 500;
      border-radius: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 1.2rem;
      cursor: pointer;
      font-size: 1.2rem;
      transition: all ease 0.2s;

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  .userDetailsContainer {
    padding: 1rem;
    border-radius: 10px;
    background: #f5f5f5;
    overflow-y: auto;
    width: calc(100% - 2rem);
    // margin: auto;

    & > .title {
      font-size: 0.9rem;
      font-weight: 500;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 1rem 0;
    }

    & > .eachDetails {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-bottom: 2rem;

      & > .threeDivContainer {
        display: flex;
        gap: 1rem;
        align-items: center;
        justify-content: space-between;

        & > :nth-child(1) {
          width: 50%;
        }

        & > :nth-child(2) {
          width: 25%;

          & > div {
            position: relative;
            & > input {
              margin-top: 0;
            }
          }

          & > input {
            margin-top: 0;
          }
        }

        & > :nth-child(3) {
          width: 25% !important;

          & > input {
            margin-top: 0;
          }
        }

        & > .ageBox {
          padding: 1rem 1.5rem;
          border-radius: 10px;
          border: 1px solid rgba(229, 229, 229, 0.4);
          font-size: 0.8rem;
          background: #ffffff;
        }
      }

      & > .twoDivContainer {
        display: flex;
        gap: 1rem;
        align-items: center;
        justify-content: space-between;

        & > :nth-child(1) {
          width: 50%;
        }

        & > :nth-child(2) {
          width: 50%;
        }
      }

      & > .singleDivContainer {
        display: flex;
        gap: 1rem;
        align-items: center;
        justify-content: space-between;

        & > :nth-child(1) {
          width: 30%;
        }

        & > .ageBox {
          padding: 1rem 1.5rem;
          border-radius: 10px;
          border: 1px solid rgba(229, 229, 229, 0.4);
          font-size: 0.8rem;
          background: #ffffff;
        }
      }
    }

    & > .submitBtn {
      margin-top: auto;
      padding: 0.6rem 2.5rem;
      border-radius: 35px;
      background: var(--main-color);
      color: white;
      font-weight: 500;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: auto;
      margin-bottom: 2rem;
      width: max-content;
      transition: all linear 0.3s;

      &:hover {
        scale: 1.05;
      }
    }
  }

  .input1 {
    width: 100%;
    height: 7%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #ccc;
    border-radius: 35px;
    padding: 1rem 3rem;
    font-size: 1.9vh;
    input {
      border: none;
      width: 100%;
      &::placeholder {
        font-size: 1.9vh;
      }
    }
    .closeImg {
      cursor: pointer;
      &:hover {
        transform: scale(1.05);
        transition: all 0.3s ease-in-out;
      }
    }
    .indicator {
      width: 10px;
      height: 10px;
      background: #dc3027;
      border: none;
      border-radius: 50%;
    }
    .eyeImg {
      cursor: pointer;
      &:hover {
        transform: scale(1.05);
        transition: all 0.3s ease-in-out;
      }
    }
  }

  .conditions {
    width: 50%;
    margin-top: 1rem;
    margin-bottom: 2rem;
    background: #fffdf2;
    border-radius: 15px;
    display: flex;
    flex-direction: column;

    .eachCondition {
      display: flex;
      width: 100%;
      padding: 15px 23px;
      align-items: center;
      justify-content: space-between;
      font-size: 0.9rem;
      font-weight: 450;
      .indicator {
        width: 10px;
        height: 10px;
        background: rgba(220, 48, 39, 0.37);
        border: none;
        border-radius: 50%;
      }
    }
  }

  .singleInputContainer {
    width: 100%;
    position: relative;

    & > svg {
      position: absolute;
      font-size: 1.6rem;
      color: #e5e5e5;
      top: 50%;
      right: 15px;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }
  }

  .input2 {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 35px;
    padding: 1rem 2rem;
    font-size: 1.9vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    input {
      border: none;
      width: 85%;
      &::placeholder {
        font-size: 1.9vh;
      }
    }
    .eyeImg {
      cursor: pointer;
      &:hover {
        transform: scale(1.05);
        transition: all 0.3s ease-in-out;
      }
    }
  }

  .forgotPassword {
    background: white;
    -webkit-background-clip: text;
    background-clip: text;
    text-fill-color: transparent;
    font-size: 1.9vh;
    width: 50%;
    text-align: right;

    div {
      color: var(--font-color);
      cursor: pointer;
      background: white;
      -webkit-background-clip: text;
      background-clip: text;
      text-fill-color: transparent;
      &:hover {
        transform: scale(1.05);
        transition: all 0.3s ease-in-out;
      }
    }
  }

  .loginButton {
    background: var(--main-color);
    width: 100%;
    padding: 0.75rem 1rem;
    color: white;
    font-weight: 500;
    border-radius: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    cursor: pointer;
    font-size: 1.2rem;
    transition: all ease 0.2s;

    &:hover {
      transform: scale(1.05);
    }
  }

  .buttonDiv {
    display: flex;
    width: 50%;
    margin-top: 1rem;
    justify-content: space-between;
    .gbBtn {
      width: 49%;
      padding: 3%;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      font-size: 1.9vh;
      color: var(--font-color);
      border: 1px solid #e5e5e5;
      font-weight: 600;
      &:hover {
        transform: scale(1.05);
        transition: all 0.3s ease-in-out;
      }
    }
  }

  .loginButtonDisabled {
    background: linear-gradient(90deg, #81cbd7 0%, #3d4394 100%);
    width: 50%;
    padding: 2%;
    color: white;
    font-weight: 700;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 85px;
    cursor: pointer;
    opacity: 0.5;
    font-size: 1.9vh;
  }

  .googleLogin {
    width: 50%;
    padding: 2%;
    font-weight: 600;
    border-radius: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    margin-top: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--font-color);
    font-size: 1.9vh;
    cursor: pointer;
    transition: all ease 0.2s;

    &:hover {
      transform: scale(1.05);
    }
  }

  .rightlabel {
    background: linear-gradient(90deg, #3d4394 0%, #81cbd7 91.36%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-weight: 600;
    font-size: 2vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .rightCard {
    padding: 4% 4%;
    margin-top: 4%;
    border: double 1px transparent;
    border-radius: 25px;
    background-image: linear-gradient(white, white),
      radial-gradient(circle at top left, #81cbd7, #3d4394);
    background-origin: border-box;
    background-clip: padding-box, border-box;
    height: 18%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &:hover {
      transform: scale(1.05);
      transition: all 0.3s ease-in-out;
      cursor: pointer;
    }
  }

  .rightCardnoHover {
    padding: 4% 4%;
    margin-top: 4%;
    border: double 1px transparent;
    border-radius: 25px;
    background-image: linear-gradient(white, white),
      radial-gradient(circle at top left, #81cbd7, #3d4394);
    background-origin: border-box;
    background-clip: padding-box, border-box;
  }

  .rightCardTitle {
    background: linear-gradient(90deg, #3d4394 0%, #81cbd7 91.36%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-weight: 600;
    font-size: 2vh;
  }
  .rightCardSubTitle {
    font-weight: 400;
    font-size: 1.6vh;
    color: var(--font-color);
    margin-top: 2%;
    line-height: 25px;
  }
  .rightCardActive {
    padding: 4% 4%;
    margin-top: 4%;
    border: double 1px transparent;
    border-radius: 25px;
    background-image: linear-gradient(white, white),
      radial-gradient(circle at top left, #81cbd7, #3d4394);
    background-origin: border-box;
    background-clip: padding-box, border-box;
    height: 18%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &:hover {
      transform: scale(1.05);
      transition: all 0.3s ease-in-out;
      cursor: pointer;
    }
  }

  .options {
    height: 75px;
    display: flex;
    align-items: center;
    padding-left: 35px;
    border: 0.5px solid #e5e5e5;
    margin-bottom: 4%;
    border-radius: 15px;
    font-size: 1.7vh;
    &:hover {
      transform: scale(1.05);
      transition: all 0.3s ease-in-out;
      cursor: pointer;
    }
  }

  .optionsDisabled {
    height: 75px;
    display: flex;
    align-items: center;
    padding-left: 35px;
    border: 0.5px solid #e5e5e5;
    margin-bottom: 4%;
    border-radius: 15px;
    font-size: 1.7vh;
  }

  // Mobile UI Styles

  .mobileLogo {
    border-bottom: 1px solid #e5e5e5;
    padding: 5% 20%;
    background-color: white;
    height: 9vh;
    margin-bottom: 10vh;
    img {
      width: 100%;
    }
  }

  .stepContainer {
    height: 65vh;
    position: relative;
    .AffiliatePrompt {
      width: 100%;
      display: flex;
      gap: 10px;
      padding: 1rem 1.5rem;
      background: #fffdf2;
      border-radius: 15px;
      margin-bottom: 2vh;
      font-size: 0.85rem;
    }
  }
  .pageTitle {
    font-size: 2vh;
    font-weight: 600;
    color: var(--font-color);
    // margin-top: 10%;
    margin-bottom: 10%;
  }
  .optionCards {
    display: flex;
    align-items: center;
    margin-bottom: 5%;
    border: 1px solid #e5e5e5;
    border-radius: 15px;
    height: 84px;
    padding-left: 30px;
    font-size: 1.8vh;
    font-weight: 600;
    input {
      border: none;
      width: 90%;
      height: 100%;
    }
  }
  .buttonWrapper {
    position: absolute;
    bottom: 20px;
    width: 100%;
    // background-color: red;
  }
  .buttonWrapper1 {
    margin-top: 10vh;
  }
  .backButton {
    background-color: var(--main-color);
    border-radius: 15px;
    width: 100%;
    height: 67px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 700;
    font-size: 20px;
  }
  .continueButton {
    background: var(--font-color);
    border-radius: 15px;
    width: 100%;
    height: 67px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 20px;
  }
  .loadingComponent {
    position: absolute;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    background-color: black;
    opacity: 0.4;
  }
  .redDot {
    width: 12px;
    height: 12px;
    background-color: red;
    border-radius: 50%;
  }

  .greenDot {
    width: 12px;
    height: 12px;
    background-color: green;
    border-radius: 50%;
  }
  .passwordChecklist {
    background-color: #fffdf2;
    border-radius: 15px;
    padding: 20px;
    position: relative;

    .eachChecklist {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      .indicator {
        width: 10px;
        height: 10px;
        background: rgba(220, 48, 39, 0.37);
        border: none;
        border-radius: 50%;
      }
    }
  }
  .checklistLabel {
    text-decoration: underline;
  }
  .otpInputWrapper {
    width: 75%;
    justify-content: space-between;
    > div {
      flex: 0 0 22%;
      height: 60px;
      .otpInput {
        width: 80% !important;
        height: 100%;
        border: 2px solid #e5e5e5;
        border-radius: 8px;
        font-size: 28px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .tempLoginEmployees {
    & > .leftSide {
      gap: 1.5rem;
      margin: auto;
      width: 100%;

      & > .headerContainer {
        flex-direction: column;
        gap: 2rem;

        & > .stepTracker {
          // display: none;
        }
      }

      & > img {
        margin-top: 8rem;
      }

      & > .inputFields {
        width: 95%;
        max-width: 380px;
        margin: 0 auto;
      }

      & > .userDetailsContainer {
        background: none;

        & > .eachDetails {
          & > .threeDivContainer,
          .twoDivContainer {
            flex-direction: column;

            & > div {
              width: 100% !important;
            }
          }
        }
      }
    }
    & > .rightSide {
      display: none;
    }
  }
}

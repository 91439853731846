.visitLayout {
  display: flex;
  height: 100vh;

  & > div {
    width: 50%;
    padding: 2rem;
  }

  & > .leftContainer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    // border: 1px solid blue;
    padding-top: 6rem;

    & > .logo {
      width: 20%;
      max-width: 120px;
      object-fit: contain;
      position: absolute;
      top: 1rem;
      right: 1rem;
    }
  }

  & > .rightContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5rem;
    background: var(--main-color);
    color: white;

    & > .title {
      font-size: 2.5rem;
      font-weight: 600;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        width: 20%;
        height: 5px;
        background: white;
        bottom: -15px;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    & > .allSteps {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 1.5rem;
      width: 100%;

      & > div {
        width: 15%;
        min-width: 120px;
      }

      & > .eachStep {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        & > .stepImage {
          width: 100%;
          object-fit: contain;
        }

        & > .stepDirection {
          display: flex;
          align-items: center;
          flex-direction: column;
          gap: 20px;

          & > .title {
            font-size: 1.2rem;
            font-weight: 550;
            position: relative;

            &::after {
              content: "";
              position: absolute;
              width: 20%;
              height: 2.5px;
              background: white;
              bottom: -5px;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }

          & > .desc {
            text-align: center;
            font-size: 0.9rem;
            font-weight: 500;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
          }
        }
      }
    }
  }
}

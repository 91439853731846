.newQuote {
  padding: 2rem;
  height: calc(100% - 5rem);
  background: rgba(229, 229, 229, 0.2117647059);

  & > .allQuotes {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: 0.9rem;
    height: 100%;
    overflow-y: scroll;

    & > .eachQuote {
      background: white;
      border-radius: 10px;
      // padding: 1rem;
      border: 1px solid #e1e1e1;

      & > .quotesHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.5rem;
        cursor: pointer;
        // border-radius: 10px 10px 0 0;
        font-weight: 520;
        border: 1px solid #e1e1e1;
        border-radius: 10px;
      }

      & > .quoteReview,
      .configurations,
      .members,
      .preEnrollment,
      .rfqHistory {
        padding: 1.5rem;
        // position: relative;

        & > .uploadLoading {
          position: fixed;
          background: #00000080;
          z-index: 7;
          inset: 0;
          display: flex;
          justify-content: center;
          align-items: center;

          & > div {
            padding: 2rem;
            aspect-ratio: 1/1;
            background: white;
            font-weight: 520;
            text-align: center;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            font-size: 1.2rem;

            & > img {
              height: 5rem;
              object-fit: contain;
            }
          }
        }

        & > .title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 1.2rem;
          font-weight: 550;
          margin: 2rem 0;

          & > svg {
            width: 3rem;
            height: 3rem;
            margin-left: auto;
            stroke: var(--main-color) !important;
          }
        }

        & > .selectedType {
          display: flex;
          gap: 1.5rem;
          margin-bottom: 2rem;

          & > button {
            all: unset;
            padding: 0.6rem 1.5rem;
            border-radius: 5px;
            font-size: 0.9rem;
            font-weight: 500;
            cursor: pointer;
            border: 1px solid #e1e1e1;
            display: none;
            transition: all linear 0.3s;

            &:hover {
              border: 1px solid var(--main-color);
            }
          }

          & > .selectedBtn {
            background: var(--main-color);
            color: white;
            border: 1px solid var(--main-color);
          }
        }

        & > .fieldsContainer {
          display: flex;
          flex-direction: column;
          gap: 1rem;

          & > .singleInput {
            max-width: 300px;
          }

          & > .selectInsuredType,
          .selectOtherOptions {
            display: flex;
            gap: 1.2rem;
            margin: 1.2rem 0;

            & > div {
              position: relative;
              width: 200px;

              & > label {
                display: flex;
                align-items: center;
                gap: 0.75rem;
                position: relative;
                white-space: nowrap;
                left: 0;
                top: 0;
                transform: translate(0, 0);

                & > :last-child {
                  margin-left: 1rem;
                }
              }
            }
          }

          & > .selectOtherOptions {
            display: flex;
            gap: 1rem;
            align-items: center;
            max-width: 600px;

            & > div {
              & > label {
                & > :last-child {
                  font-size: 0.9rem;
                  margin-left: 2.5rem;
                }
              }
            }
          }

          & > .sumInsuredInputs {
            max-width: 400px;

            & > .gradedInputs {
              display: flex;
              flex-direction: column;
              gap: 1rem;

              & > .eachGrade {
                display: flex;
                // justify-content: space-between;
                gap: 1rem;
                align-items: center;

                & > .addInput {
                  visibility: hidden;
                  pointer-events: none;
                }
              }

              & > :last-child {
                & > .addInput {
                  visibility: visible;
                  pointer-events: unset;
                }

                // & > div {
                //   pointer-events: none;
                // }
              }
            }
          }

          & > .listContainer {
            list-style: disc;
            margin-top: 1rem;
            margin-left: 1.5rem;
            width: calc(100% - 3rem);

            & > li {
              padding: 10px 0;
              margin: 0;
            }
          }

          & > .manyInputs {
            & > .title {
              font-size: 0.9rem;
              margin-bottom: 10px;
              font-weight: 500;
            }

            & > .gridContainer {
              display: grid;
              gap: 1rem;
              grid-template-columns: repeat(3, 1fr);
            }
          }

          & > .switchTable {
            tr {
              display: grid;
              gap: 1rem;
              grid-template-columns: 250px repeat(3, 1fr);
              //   align-items: center;

              & > td {
                padding: 0.5rem 1.2rem;

                img {
                  cursor: pointer;
                  transition: all linear 0.3s;

                  &:hover {
                    scale: 1.25;
                  }
                }
              }

              & > :nth-child(2),
              :nth-child(3),
              :nth-child(4) {
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }

        & > .uploadAndDownloads {
          margin: 1rem 0;

          & > .title {
            font-size: 0.9rem;
            margin-bottom: 1rem;
          }

          & > .filesContainer {
            display: flex;
            gap: 1rem;

            & > div {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding: 2rem;
              gap: 1.2rem;
              border: 1px solid #e1e1e1;
              border-radius: 10px;
              cursor: pointer;
              aspect-ratio: 1/1;
              width: 200px;
              text-align: center;
              position: relative;
              transition: all linear 0.3s;

              &:hover {
                scale: 1.05;
              }

              label {
                height: 100%;
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 1rem;
                justify-content: center;
                align-items: center;
              }

              img {
                height: 2.2rem;
                object-fit: contain;
              }

              .uploadFile {
                position: absolute;
                inset: 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                & > div {
                  height: 100%;
                  visibility: hidden;

                  & > label {
                    height: 100%;
                  }
                }
              }
            }
          }
        }

        & > .successContainer {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          width: 80%;
          gap: 1rem;
          margin: 0 auto;
          margin-bottom: 1rem;

          & > .title {
            font-size: 1.2rem;
            font-weight: 550;
          }

          & > p {
            font-size: 0.9rem;
          }

          & > .externalContainer {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            & > div {
              display: flex;
              gap: 0.5rem;
              align-items: center;
              cursor: pointer;

              &:hover {
                text-decoration: underline;
                font-weight: 500;
                color: var(--main-color);
              }
            }
          }
        }

        & > .preEnrollmentContainer {
          display: flex;
          gap: 3rem;

          & > :nth-child(1) {
            width: 40%;
            display: flex;
            flex-direction: column;
            gap: 3rem;
            align-items: center;

            & > img {
              width: 100%;
            }

            & > .externalContainer {
              display: flex;
              flex-direction: column;
              gap: 1rem;

              & > div {
                display: flex;
                gap: 0.5rem;
                align-items: center;
                cursor: pointer;

                &:hover {
                  text-decoration: underline;
                  font-weight: 500;
                  color: var(--main-color);
                }
              }
            }
          }

          & > :nth-child(2) {
            width: 60%;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 1rem;

            & > .detailsContainer {
              display: flex;
              flex-direction: column;
              gap: 1rem;
              justify-content: center;
              align-items: center;
              padding: 1.5rem;
              border: 1px solid #e1e1e1;
              border-radius: 10px;
              cursor: pointer;

              & > .title {
                font-size: 0.9rem;
              }

              & > .count {
                font-size: 1.5rem;
                font-weight: 500;
              }
            }
          }
        }

        & > .preEnrollmentBtns {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 2rem;

          & > button {
            all: unset;
            cursor: pointer;
            background: var(--main-color);
            color: white;
            padding: 0.6rem 1.5rem;
            font-weight: 500;
            border-radius: 10px;
            transition: all linear 0.3s;

            &:hover {
              scale: 1.05;
            }
          }
        }

        & > .configBtns {
          display: flex;
          gap: 1rem;
          align-items: center;
          margin-left: auto;
          justify-content: flex-end;
          margin-top: 1.5rem;

          & > button {
            all: unset;
            padding: 0.6rem 1.5rem;
            border: 1px solid #e1e1e1;
            border-radius: 10px;
            font-size: 1rem;
            cursor: pointer;
            color: white;
            font-weight: 500;
            font-size: 0.9rem;
            transition: all linear 0.3s;

            &:hover {
              scale: 1.05;
            }
          }

          & > :nth-child(1) {
            color: var(--font-color);
            border: 1px solid #e1e1e1;
          }
          & > :nth-child(2) {
            background: var(--main-color);
          }

          & > .unClickableBtn {
            opacity: 0.5;
            pointer-events: none;
          }
        }

        .normalFlexContainer {
          display: flex;
        }

        .btnsContainer {
          display: flex;
          gap: 1rem;
          align-items: center;
          margin-left: auto;
          transition: all linear 0.3s;

          &:hover {
            scale: 1.05;
          }

          & > button {
            all: unset;
            padding: 0.6rem 1.5rem;
            border: 1px solid #e1e1e1;
            border-radius: 10px;
            font-size: 1rem;
            cursor: pointer;
            color: white;
            font-weight: 500;
          }

          & > :nth-child(1) {
            background: var(--font-color);
          }
          & > :nth-child(2) {
            background: var(--main-color);
            border: none;
          }
        }
      }
    }

    .emptyPoliciesDiv {
      padding: 1rem;
      border-radius: 10px;
      // border: 1px solid #e1e1e1;
      // box-shadow: 0px 4px 4px 0px #00000040;
      background: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;

      & > div {
        width: 80%;
        text-align: center;
        font-weight: 500;
        margin-bottom: 2rem;
      }

      & > .btnsContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        font-size: 0.9rem;

        & > button {
          all: unset;
          padding: 0.6rem 1.5rem;
          width: 200px;
          cursor: pointer;
          border-radius: 10px;
          background: var(--main-color);
          color: white;
          font-weight: 520;
          // width: max-content;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          transition: all linear 0.3s;

          &:hover {
            scale: 1.05;
          }
        }

        & > :nth-child(2) {
          background: white;
          color: var(--font-color);
          border: 1px solid #e1e1e1;
        }
      }
    }
  }
}

.newQuoteMini {
  padding: 0;
  background: white;
  height: 100%;
}

.tableContainer {
  overflow-y: auto;
  // height: calc(100% - 14rem);
  width: 100%; /* Set the container width to 100% or any other desired value */
  // overflow-x: auto; /* Allow horizontal scrolling */
  // border-left: 0.25px solid #e5e5e5;
  // border-right: 0.25px solid #e5e5e5;
  position: relative;
  // border: 1px soli50pxd #dddddd;

  &::-webkit-scrollbar {
    display: block;
    width: 7px; /* Set the width of the table's scrollbar */
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    height: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(
      --font-color
    ); /* Set the color of the scrollbar thumb */
    border-radius: 5px; /* Set the border-radius of the thumb */
    height: 80px;
    transition: all linear 0.3s;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: var(
      --main-color
    ); /* Set the color when the scrollbar thumb is hovered */
  }

  table {
    border-collapse: collapse;
    min-width: 100%; /* Set the table width to 110% to ensure horizontal scrollbar */
    margin-bottom: 20px;
    table-layout: fixed; /* Ensures equal column width for horizontally scrolling table */
    width: max-content;
    height: 100%;

    th,
    td {
      // border: 1px solid #dddddd;
      text-align: left;
      white-space: normal;
      word-wrap: break-word;
      padding: 0.75rem 1rem;
      position: relative;
      display: flex;
      align-items: center;
      border-right: none;
      border-top: none;
      font-size: 0.85rem;

      img {
        transition: all linear 0.3s;

        &:hover {
          scale: 1.05;
        }
      }

      & > input {
        width: 1.25rem;
        height: 1.25rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      & > .processing {
        background: #f8cd70;
        color: white;
        padding: 0.5rem 1.5rem;
        border-radius: 10px;
        font-weight: 500;
      }

      & > .processed {
        background: var(--opacity-main);
        color: white;
        padding: 0.5rem 1.5rem;
        border-radius: 10px;
        font-weight: 500;
      }

      & > .rejected {
        background: #ea0f0f;
        color: white;
        padding: 0.5rem 1.5rem;
        border-radius: 10px;
        font-weight: 500;
      }
    }

    th {
      white-space: nowrap;
      font-weight: 550;
      font-size: 0.85rem;
      padding: 0.7rem 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > svg {
        cursor: pointer;
      }
    }

    thead {
      background: #e1e1e1;
      & > tr {
        & > th {
          border-top: none;
        }
      }

      .mobileHeader {
        & > th {
          white-space: unset !important;
        }
      }
    }

    .statusBtnMobile {
      white-space: unset !important;
      padding: 10px !important;
    }

    tbody {
      position: relative;
      background: white;

      & > tr {
        border-bottom: 1px solid #dddddd;
        // border-top: none;
        position: relative;
      }

      & > :last-child {
        border: none;

        & > td {
          background: none !important;
        }

        .addNew {
          all: unset;
          padding: 0.6rem 1rem;
          width: 100%;
          background: #e5e5e5;
          font-weight: 520;
          cursor: pointer;
          text-align: center;
          border-radius: 35px;
        }
      }
    }

    tr {
      & > :nth-child(1) {
        border-left: none;
        width: 50px;
      }
    }

    tr {
      display: grid;
      grid-template-columns: min-content repeat(15, 1fr);
    }

    tr > :first-child {
      /* Set the width you want for the first child */
      width: 50px; /* Replace 200px with your desired width */
      /* Position the first child in the first column */
      grid-column: 1;
    }

    .detailedItem {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      justify-content: flex-start;

      & > :nth-child(1) {
        padding: 0.5rem;
        border: 0.5px solid #e5e5e5;
        border-radius: 50%;
        width: 3rem;
        height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;

        & > img {
          width: 70%;
          height: 70%;
        }
      }

      & > :nth-child(2) {
        display: flex;
        flex-direction: column;
        gap: 0.2rem;

        & > :nth-child(1) {
          font-size: 0.9rem;
          font-weight: 550;
          white-space: nowrap;
        }
        & > :nth-child(2) {
          font-size: 0.8rem;
          font-weight: 400;
        }
      }
    }

    td {
      position: relative;
      transition: all linear 0.3s;

      .statusBtn {
        height: 95%;
        padding: 5px 1.5rem;
        border-radius: 5px;
        cursor: pointer;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
        font-weight: 500;
        font-size: 0.85rem;
      }

      .rejectedBtn {
        background: #df0404;
      }

      .reviewBtn {
        background: #7b61ff;
      }
      .requestedBtn {
        background: #ffcd71;
      }
      .acceptedBtn {
        background: var(--opacity-main);
      }

      &:hover {
        background: #8080800d;
      }

      & > .dropdownContent {
        position: absolute;
        top: 70%;
        left: 55%;
        padding: 1rem 0;
        background: white;
        z-index: 6;
        border: 1px solid #e1e1e1;
        box-shadow: 0px 4px 4px 0px #00000040;
        border-radius: 10px;
        min-width: 250px;

        & > .eachFilterItem {
          padding: 0.7rem 1.5rem;
          font-size: 1rem;
          font-weight: 400;
          cursor: pointer;
          white-space: nowrap;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          width: 100%;
          // padding-right: 4rem;

          & > svg {
            rotate: -90deg;
          }

          & > .subDropdown {
            display: none;
            min-width: 250px;
            left: 100%;
            top: 0;
            color: var(--font-color);
          }

          &:hover {
            color: var(--main-color);

            & > .subDropdown {
              display: block;
            }
          }
        }
      }

      .dropdownContent {
        position: absolute;
        top: 70%;
        left: 55%;
        padding: 1rem 0;
        background: white;
        z-index: 6;
        border: 1px solid #e1e1e1;
        box-shadow: 0px 4px 4px 0px #00000040;
        border-radius: 10px;
        min-width: 250px;

        & > .eachFilterItem {
          padding: 0.7rem 1.5rem;
          font-size: 1rem;
          font-weight: 400;
          cursor: pointer;
          white-space: nowrap;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          width: 100%;
          // padding-right: 4rem;

          & > span {
            display: flex;
            gap: 10px;
            align-items: center;

            & > svg {
              height: 1rem;
              width: 1rem;
            }
          }

          & > svg {
            rotate: -90deg;
          }

          & > .subDropdown {
            display: none;
            min-width: 250px;
            left: 100%;
            top: 0;
            color: var(--font-color);
          }

          &:hover {
            color: var(--main-color);

            & > .subDropdown {
              display: block;
            }
          }
        }
      }
    }

    .desc {
      font-size: 0.85rem;
      padding: 1.5rem 0.5rem;
      width: 90%;

      & > .title {
        font-weight: 520;
        margin: 0.5rem 0;
      }

      & > .para {
      }
    }

    .respondRFQ {
      padding: 1.5rem 0.5rem;
      display: flex;
      flex-direction: column;
      gap: 0.75rem;

      & > .title {
        font-size: 0.9rem;
        font-weight: 520;
        padding: 0.5rem;
      }

      & > .selectOptions {
        display: flex;
        gap: 1rem;

        & > div {
          padding: 0.6rem 1.5rem;
          border-radius: 10px;
          border: 1px solid #e1e1e1;
          font-weight: 500;
          cursor: pointer;
        }

        & > .selectedOption {
          background: var(--main-color);
          color: white;
        }
      }

      & > .submitbtn {
        padding: 0.6rem 1.2rem;
        border-radius: 10px;
        background: var(--main-color);
        color: white;
        font-size: 0.9rem;
        font-weight: 500;
        cursor: pointer;
        border: none;
        outline: none;
        width: max-content;
        transition: all linear 0.3s;

        &:hover {
          scale: 1.05;
        }
      }
    }

    .editContainer {
      border-bottom: 0.5px solid #e5e5e5;
    }
  }
}

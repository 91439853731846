.typeSection {
  height: 100vh;
  width: 100%;

  & > .topSection {
    height: 65vh;
    background: var(--main-color);
    display: flex;
    align-items: flex-end;
    justify-content: center;

    & > .contentBox {
      padding: 0 5rem;
      max-width: 1400px;
      margin: 0 auto;
      color: white;
      font-weight: 600;
      padding-top: 180px;
      padding-bottom: 50px;
      padding: 0 1.5rem;
      height: calc(100% - 170px);
      display: flex;
      justify-content: center;
      overflow: hidden;

      & > :nth-child(1) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 60%;
        gap: 4%;

        & > div {
          width: 80%;
          max-width: 600px;
        }

        & > .title {
          font-size: 2.25rem;
          animation: slideInFromLeft 1s ease forwards;
          animation-delay: 0.2s;
          transform: translateX(-500%);
          white-space: nowrap;
          
        }

        & > .desc {
          font-size: 1.5rem;
          line-height: 1.35;
          margin-top: 0.5rem;
          animation: slideInFromLeft 1s ease forwards;
          animation-delay: 0.4s;
          transform: translateX(-500%);
        }

        & > .btnsContainer {
          display: flex;
          align-items: center;
          gap: 2rem;

          & > .requestBtn {
            border: 1.5px solid white;
            border-radius: 35px;
            cursor: pointer;
            margin-top: 20px;
            width: max-content;
            padding: 0.5rem 1.5rem;
            font-size: 1rem;
            display: flex;
            white-space: nowrap;
            align-items: center;
            animation: slideInFromLeft 1s ease forwards;
            animation-delay: 0.8s;
            transform: translateX(-500%);

            & > svg {
              fill: white;
              margin-left: 15px;
            }

            &:hover {
              background: white;
              color: var(--main-color);

              & > svg {
                fill: var(--main-color);
              }
            }
          }

          & > .buyBtn {
            border: 1.5px solid white;
            border-radius: 35px;
            cursor: pointer;
            margin-top: 20px;
            width: max-content;
            padding: 0.5rem 1.5rem;
            font-size: 1rem;
            display: flex;
            align-items: center;
            animation: slideInFromLeft 1s ease forwards;
            animation-delay: 0.8s;
            color: var(--main-color);
            background: white;
            white-space: nowrap;
            transform: translateX(-500%);

            & > svg {
              fill: var(--main-color);
              margin-left: 15px;
            }

            &:hover {
              background: var(--main-color);
              color: white;

              & > svg {
                fill: white;
              }
            }
          }

          & > div {
            transition: all linear 0.3s;

            &:hover {
              scale: 1.05;
            }
          }
        }
      }

      & > :nth-child(2) {
        width: 40%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        animation: slideInFromRight 1.5s ease forwards;
        transform: translateX(500%);

        & > img {
          width: 65%;
          object-fit: contain;
        }
      }
    }
  }

  & > .bottomSection {
    height: 35vh;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    padding: 2rem 0;

    & > .typeCards {
      position: absolute;
      min-height: 300px;
      max-width: 1240px;
      top: -40px;
      left: 50%;
      transform: translate(-50%, 0%);
      border: 1px solid #e5e5e5;
      width: 100%;
      border-radius: 35px;
      background: white;

      & > .selectSections {
        display: flex;
        gap: 1.5rem;
        justify-content: space-evenly;
        border-bottom: 1px solid #e5e5e5;
        border-radius: 35px 35px 0 0;
        padding: 0.75rem 2rem;

        & > div {
          cursor: pointer;
          padding: 0.25rem 1.5rem;

          &:hover {
            font-weight: 600;
          }
        }

        & > .selectedSectionItem {
          background: var(--main-color);
          color: white;
          border-radius: 35px;
        }
      }

      & > .sectionContent {
        padding: 2rem 4rem;
        padding-bottom: 0;
        display: flex;
        gap: 1.5rem;

        & > .eachContent {
          border-radius: 35px;
          padding: 20px;
          width: 25%;
          border: 1px solid #e5e5e5;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 15px;
          aspect-ratio: 1/0.7;
          cursor: pointer;
          transition: all ease-in 0.3s;

          & > img {
            height: 80%;
          }

          & > .title {
          }

          &:hover {
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          }
        }
      }
    }

    & > .allCards {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1.5rem;
      width: 100%;
      max-width: 1400px;
      margin: 0 auto;
      padding: 2rem 1.5rem;

      & > .eachNumbersContent {
        border: 1px solid #e5e5e5;
        border-radius: 35px;
        // aspect-ratio: 1/0.7;
        padding: 6% 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;
        height: 50%;
        width: 20%;
        max-height: 200px;

        & > .eachNumber {
          font-size: 1.8rem;
          font-weight: 600;
          white-space: nowrap;
        }

        & > .eachTitle {
          font-size: 1.05rem;
          margin-top: 10px;
          white-space: nowrap;
        }
      }
    }
  }
}

@keyframes slideInFromLeft {
  from {
    transform: translateX(-500%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes slideInFromRight {
  from {
    transform: translateX(500%);
  }
  to {
    transform: translateX(0);
  }
}

// media query for mobile

@media (max-width: 768px) {
  .typeSection {
    height: 65vh;
    margin-top: 20rem;
    & > .topSection {
      display: none;
    }

    & > .bottomSection {
      & > .typeCards {
        & > .sectionContent {
          padding: 2rem 1rem;
          padding-bottom: 0;
          display: flex;
          flex-direction: column;
          gap: 1.5rem;

          & > .eachContent {
            width: 100%;
          }
        }
      }

      & > .allCards {
        flex-direction: column;
        gap: 1.5rem;

        & > .eachNumbersContent {
          width: 100%;
          height: 174px;
          background: #fafafa;
        }
      }
    }
  }
}

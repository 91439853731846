@import url("https://fonts.googleapis.com/css2?family=Avenir:ital,wght@0,300;0,400;0,600;0,700;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sen:wght@0,300;0,400;0,600;0,700;1,400&display=swap");

body {
  margin: 0;
  font-family: "Avenir", "Sen", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sad {
  position: relative;
}
.overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

@media screen and (max-width: 800px) {
  html {
    font-size: 82%;
  }
}

@media (min-width: 1600px) {
  html {
    font-size: 110%;
  }
}

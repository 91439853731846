.navbarv2 {
  height: 5rem;
  display: flex;
  background: white;
  border-bottom: 1px solid #e7e7e7;

  & > .searchBar {
    width: -webkit-fill-available;
    height: 100%;
    border-left: 1px solid #e7e7e7;
    border-right: 1px solid #e7e7e7;
    min-width: 150px;

    & > input {
      width: 100%;
      height: 100%;
      padding: 1rem;
      font-weight: 500;
      outline: none;
      border: none;
    }
  }

  & > .profileDiv {
    border-left: 1px solid #e7e7e7;
    border-right: 1px solid #e7e7e7;
    width: max-content;
    position: relative;
    cursor: pointer;

    & > :nth-child(1) {
      display: flex;
      height: 100%;
      align-items: center;
      padding: 1rem;
      gap: 0.5rem;
      font-size: 0.9rem;
      color: var(--font-color);
      white-space: nowrap;
      position: relative;
      z-index: 6;
      background: white;

      & > img {
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
      }

      & > .profileLetter {
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        font-size: 0.8rem;
        background: var(--main-color);
        color: white;
        font-weight: 550;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    & > .dropdownContent {
      position: absolute;
      top: 100%;
      // border: 1px solid green;
      // width: 100%;
      left: -1px;
      right: -1px;
      z-index: 5;
      background: white;
      border-left: 1px solid #e7e7e7;
      border-right: 1px solid #e7e7e7;
      border-bottom: 1px solid #e7e7e7;
      transition: all linear 0.3s;
      overflow: scroll;
      max-height: 500px;
      display: flex;
      flex-direction: column;

      & > .eachContent {
        padding: 1.5rem 1rem;
        font-size: 1rem;
        font-weight: 500;
        color: var(--font-color);
        transition: all linear 0.2s;
        text-decoration: none;
        display: flex;
        align-items: center;
        gap: 10px;

        & > svg {
          width: 1.2rem;
          height: 1.2rem;
          stroke: var(--font-color);
        }

        &:hover {
          background: var(--main-color);
          color: white;

          & > svg {
            stroke: white;
          }
        }
      }
    }
  }
  & > .notificationIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 5rem;
    width: 5rem;
    position: relative;

    & > svg {
      width: 1.5rem;
      height: 1.5rem;
      fill: var(--font-color);
      cursor: pointer;
    }

    & > .cartIcon {
      display: flex;
      align-items: center;
      position: relative;

      & > img {
        padding: 0.5rem 1rem;
      }

      & > .totalItems {
        background: var(--main-color);
        color: white;
        font-size: 0.9rem;
        font-weight: 500;
        position: absolute;
        top: 5px;
        right: 5px;
        aspect-ratio: 1/1;
        width: 1.5rem;
        height: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
      }
    }

    & > .dropdownContent {
      position: absolute;
      top: 120%;
      right: 1rem;
      z-index: 5;
      background: white;
      border-radius: 10px;
      padding: 1rem;
      border: 1px solid #e1e1e1;
      transition: all linear 0.3s;
      overflow: scroll;
      max-height: 500px;
      width: 400px;
      overflow-y: scroll;
      padding-bottom: 0;

      & > .singleNotification {
        display: flex;
        flex-direction: column;
        gap: 0.4rem;
        margin-bottom: 2rem;

        & > .heading {
          display: flex;
          align-items: center;
          gap: 1px;

          & > div {
            display: flex;
            align-items: center;
            gap: 3px;
          }

          & > .title {
            font-size: 0.8rem;
            font-weight: 500;

            & > img {
              height: 1rem;
              border-radius: 3px;
            }
          }

          & > .duration {
            font-size: 0.7rem;
            font-weight: 400;
          }

          & > .closeBtn {
            margin-left: auto;
            font-size: 0.8rem;
            font-weight: 550;
            cursor: pointer;
          }
        }

        & > .notificationTitle {
          font-size: 0.9rem;
          font-weight: 500;
          margin: 3px 0;
        }

        & > .notificationMessage {
          font-size: 0.75rem;
          font-weight: 400;
        }

        & > .notificationImage {
          width: 100%;
          object-fit: contain;
          border-radius: 10px;
        }

        & > .notificationBtn {
          border-radius: 5px;
          border: 1px solid #e1e1e1;
          box-shadow: 0px 4px 4px 0px #00000040;
          background: #fff;
          font-weight: 550;
          cursor: pointer;
          font-size: 0.8rem;
          padding: 0.5rem;
          text-align: center;
          transition: all linear 0.3s;

          &:hover {
            background: var(--main-color);
            color: white;
          }
        }
      }
    }
  }
}

.customDropdown {
  position: relative;
  display: flex;
  width: max-content;
  min-width: 240px;
  //   border: 1px solid blue;
  max-width: 400px;
  height: 100%;
  cursor: pointer;
  border-radius: 10px;

  & > .dropdownBtn {
    display: flex;
    gap: 1.5rem;
    padding: 1rem;
    align-items: center;
    justify-content: space-between;
    font-size: 1rem;
    font-weight: 520;
    width: 100%;
    user-select: none;
    white-space: nowrap;
    position: relative;
    z-index: 6;
    background: white;

    & > :nth-child(1) {
      width: 95%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  & > .dropdownContent {
    position: absolute;
    top: 100%;
    // border: 1px solid green;
    // width: 100%;
    left: -1px;
    right: -1px;
    z-index: 5;
    background: white;
    border-left: 1px solid #e7e7e7;
    border-right: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
    transition: all linear 0.3s;
    overflow: scroll;
    max-height: 500px;
    transition: all linear 0.5s;

    & > .eachContent {
      padding: 1.5rem 1rem;
      font-size: 1rem;
      font-weight: 500;
      color: var(--font-color);
      transition: all linear 0.2s;

      &:hover {
        background: var(--main-color);
        color: white;
      }
    }
  }
}

.onLoginCompanyForm {
  position: absolute;
  inset: 0;
  background: #00000080;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;

  & > .formContainer {
    width: 70%;
    height: 85%;
    aspect-ratio: 1/1.5;
    background: white;
    z-index: 1;
    border-radius: 10px;

    & > .header {
      padding: 1rem 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--main-color);
      border-radius: 10px 10px 0 0;

      & > img {
        height: 2rem;
        object-fit: contain;
      }
    }

    & > .formBody {
      height: calc(100% - 4rem);
      padding: 2rem;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        display: block;
        width: 7px; /* Set the width of the table's scrollbar */
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        height: 7px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(
          --font-color
        ); /* Set the color of the scrollbar thumb */
        border-radius: 5px; /* Set the border-radius of the thumb */
        height: 80px;
        transition: all linear 0.3s;
        cursor: pointer;
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: var(
          --main-color
        ); /* Set the color when the scrollbar thumb is hovered */
      }

      & > .title {
        font-size: 1rem;
        font-weight: 520;
        margin: 1rem 0;
        margin-bottom: 2rem;
      }

      & > .inputFields {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        & > .dropdownParent {
          display: flex;
          flex-direction: column;
          gap: 0.6rem;

          & > .title {
            font-size: 0.9rem;
            font-weight: 500;
          }
        }

        & > .inputContainers {
          display: flex;
          gap: 2rem;

          & > div {
            flex-direction: column-reverse;

            & > label {
              border-radius: 10px;

              & > img {
                border-radius: 10px;
              }
            }
          }
        }

        & > .submitBtn {
          padding: 0.6rem 1.5rem;
          border-radius: 10px;
          background: var(--main-color);
          color: white;
          font-weight: 550;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          width: max-content;
          margin-top: 2rem;
          transition: all linear 0.3s;

          &:hover {
            scale: 1.05;
          }
        }
      }
    }
  }
}

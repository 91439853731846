.toggleParent {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 0.85rem;
}

/* ToggleButton.css */
.toggle-container {
  width: 60px;
  height: 25px;
  border: 1px solid #e5e5e5;
  border-radius: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  transition: all linear 0.4s;
}

.cylinder {
  width: 100%;
  height: 60%;
  background-color: white;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px;
  transition: all linear 0.4s;
}

.circle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: var(--main-color);
  border-radius: 50%;
  transition: all linear 0.4s;
}

.circle.toggled {
  transition: all linear 0.4s;
  right: 3px;
}

.toggleParentDashboard {
  padding: 0.7rem 1.5rem;
  position: relative;

  & > .toggleNameDashboard {
    font-size: 0.8rem;
    font-weight: 500;
    position: absolute;
    top: calc(100% + 1rem);
    left: 50%;
    transform: translate(-50%, -50%);
  }

  & > .toggleName {
    font-size: 0.7rem;
    font-weight: 500;
  }
}

.membersForms {
  min-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 1.5rem;
  max-height: 55vh;
  overflow-y: scroll;

  & > .title {
    font-size: 1rem;
    font-weight: 550;
    text-align: left;
    margin-bottom: 0.5rem;
  }

  & > .eachInsurance {
    display: grid;
    grid-template-columns: 50px repeat(1, 1fr) 130px;
    align-items: center;
    gap: 0.4rem;
    font-size: 0.9rem;
    text-align: left;
    margin: 0.5rem 0;

    & > .relativeDiv {
      position: relative;

      & > div {
        min-width: unset;
      }
    }
  }

  & > .gradeSubSelections {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 0.3rem 0;

    & > div {
      width: 50%;

      & > :nth-child(2) {
        border-radius: 10px;
        // border: 1px solid rgba(229, 229, 229, 0.4);
        border: none;
      }
    }

    & > .dropdownWithTitle {
      & > :nth-child(1) {
        font-size: 0.9rem;
        margin-bottom: 9px;
        font-weight: 500;
        text-align: left;
      }

      & > :nth-child(2) {
        width: 100%;
        & > div {
          border-radius: 10px;
          border: 1px solid rgba(229, 229, 229, 0.4);
          max-height: 200px;
        }
      }
    }
  }

  & > .btnsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;

    & > button {
      padding: 0.5rem 1.5rem;
      cursor: pointer;
      border: none;
      outline: none;
      border-radius: 10px;
      font-weight: 500;
    }

    & > :nth-child(1) {
      background: white;
      border: 1px solid #e5e5e5;
    }
    & > :nth-child(2) {
      background: var(--main-color);
      color: white;
      border: 1px solid #e5e5e5;
    }
  }
}

.membersDetailsForm {
  min-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 1.5rem;
  max-height: 55vh;
  overflow-y: scroll;

  & > .title {
    font-size: 1rem;
    font-weight: 550;
    text-align: left;
  }

  & > .formFields {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;

    li {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
      display: block;
    }

    span {
      & > span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        display: block;
      }
    }

    & > div {
      & > input {
        margin: 0;
      }

      & > div {
        border-radius: 10px;
        border: 1px solid rgba(229, 229, 229, 0.4);
      }
    }
  }

  & > .btnsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;

    & > button {
      padding: 0.5rem 1.5rem;
      cursor: pointer;
      border: none;
      outline: none;
      border-radius: 10px;
      font-weight: 500;
    }

    & > :nth-child(1) {
      background: white;
      border: 1px solid #e5e5e5;
    }
    & > :nth-child(2) {
      background: var(--main-color);
      color: white;
      border: 1px solid #e5e5e5;
    }
  }
}

.membersDetailsForm {
  min-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
  padding: 1rem 1.5rem;
  max-height: 55vh;
  overflow-y: scroll;

  & > .loadingImage {
    position: absolute;
    inset: 0;
    pointer-events: none;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    & > img {
      height: 5rem;
      animation: heartbeat 1.3s infinite;
    }

    @keyframes heartbeat {
      0% {
        transform: scale(0.75);
      }
      20% {
        transform: scale(1);
      }
      40% {
        transform: scale(0.75);
      }
      60% {
        transform: scale(1);
      }
      80% {
        transform: scale(0.75);
      }
      100% {
        transform: scale(0.75);
      }
    }
  }

  & > .title,
  .subTitle {
    font-size: 1rem;
    font-weight: 550;
    text-align: left;
  }

  & > .subTitle {
    text-decoration: underline;
    text-align: left;
  }

  & > .detailsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    & > :nth-child(1) {
      display: flex;
      flex-direction: column;
      gap: 5px;
      align-items: flex-start;

      & > :nth-child(1) {
        font-size: 0.9rem;
        font-weight: 550;
      }
      & > :nth-child(2) {
        font-size: 0.8rem;
      }
    }

    & > :nth-child(2) {
      font-size: 0.9rem;
      font-weight: 500;
    }
  }

  & > .messageContainer {
    font-size: 0.9rem;
    font-weight: 550;
    text-align: left;
    font-style: italic;
  }
}

.bulkUpload {
  min-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 1.5rem;
  max-height: 55vh;
  overflow-y: scroll;

  & > .loadingImage {
    position: absolute;
    inset: 0;
    pointer-events: none;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    & > img {
      height: 5rem;
      animation: heartbeat 1.3s infinite;
    }

    @keyframes heartbeat {
      0% {
        transform: scale(0.75);
      }
      20% {
        transform: scale(1);
      }
      40% {
        transform: scale(0.75);
      }
      60% {
        transform: scale(1);
      }
      80% {
        transform: scale(0.75);
      }
      100% {
        transform: scale(0.75);
      }
    }
  }

  & > .title {
    font-size: 1rem;
    font-weight: 550;
    text-align: left;
  }

  & > .fieldsContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    & > .singleInput {
      max-width: 300px;

      & > div {
        & > div {
          border-radius: 10px;
        }
      }
    }

    & > .selectInsuredType,
    .selectOtherOptions {
      display: flex;
      gap: 1.2rem;
      margin: 1.2rem 0;

      & > div {
        position: relative;
        width: 200px;

        & > label {
          display: flex;
          align-items: center;
          gap: 0.75rem;
          position: relative;
          white-space: nowrap;
          left: 0;
          top: 0;
          transform: translate(0, 0);

          & > :last-child {
            margin-left: 1rem;
          }
        }
      }
    }

    & > .selectOtherOptions {
      display: flex;
      gap: 1rem;
      align-items: center;
      max-width: 600px;

      & > div {
        & > label {
          & > :last-child {
            font-size: 0.9rem;
            margin-left: 2.5rem;
          }
        }
      }
    }

    & > .sumInsuredInputs {
      max-width: 400px;

      & > .gradedInputs {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        & > .eachGrade {
          display: flex;
          // justify-content: space-between;
          gap: 1rem;
          align-items: center;

          & > .addInput {
            visibility: hidden;
            pointer-events: none;
          }
        }

        & > :last-child {
          & > .addInput {
            visibility: visible;
            pointer-events: unset;
          }

          // & > div {
          //   pointer-events: none;
          // }
        }
      }
    }

    & > .listContainer {
      list-style: disc;
      margin-top: 1rem;
      margin-left: 1.5rem;
      width: calc(100% - 3rem);

      & > li {
        padding: 10px 0;
        margin: 0;
      }
    }

    & > .manyInputs {
      & > .title {
        font-size: 0.9rem;
        margin-bottom: 10px;
        font-weight: 500;
      }

      & > .gridContainer {
        display: grid;
        gap: 1rem;
        grid-template-columns: repeat(3, 1fr);
      }
    }

    & > .switchTable {
      tr {
        display: grid;
        gap: 1rem;
        grid-template-columns: 250px repeat(3, 1fr);
        //   align-items: center;

        & > td {
          padding: 0.5rem 1.2rem;

          img {
            cursor: pointer;
            transition: all linear 0.3s;

            &:hover {
              scale: 1.25;
            }
          }
        }

        & > :nth-child(2),
        :nth-child(3),
        :nth-child(4) {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  & > .bulkDocuments {
    display: flex;
    gap: 1rem;

    img,
    svg {
      height: 4rem;
      width: 4rem;
    }

    & > div {
      padding: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
      font-weight: 400;
      gap: 1rem;
      border-radius: 10px;
      border: 1px solid #e1e1e1;
      width: 12rem;
      height: 12rem;
      aspect-ratio: 1/1;
      transition: all linear 0.3s;

      label {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        & > div {
          text-align: center;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 100%;
        }
      }

      & > span {
        text-overflow: "ellipse";
        overflow: "hidden";
        white-space: "nowrap";
        text-align: center;
      }

      &:hover {
        border: 1px solid var(--main-color);
      }
    }
  }

  & > .btnsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;

    & > button {
      padding: 0.5rem 1.5rem;
      cursor: pointer;
      border: none;
      outline: none;
      border-radius: 10px;
      font-weight: 500;
    }

    & > :nth-child(1) {
      background: white;
      border: 1px solid #e5e5e5;
    }
    & > :nth-child(2),
    .coloredBtn {
      background: var(--main-color);
      color: white;
      border: 1px solid #e5e5e5;
    }
  }
}

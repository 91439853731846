.verifyEmployee {
  position: fixed;
  inset: 0;
  z-index: 8;
  display: flex;
  justify-content: center;
  align-items: center;

  & > .overlay {
    background: #00000080;
    position: absolute;
    inset: 0;
  }

  & > .verifyBox {
    overflow-y: scroll;
    padding: 1.2rem;
    background: white;
    border-radius: 10px;
    position: relative;
    z-index: 2;
    width: 96%;
    height: 96%;
    display: flex;

    & > .leftContainer {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      min-width: 50%;
      position: relative;
      padding-top: 8rem;

      & > .headerContainer {
        position: absolute;
        top: 1.5rem;
        left: 0rem;
        right: 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > .title {
          font-size: 1.5rem;
          font-weight: 550;
          margin-top: 1.2rem;
        }

        & > .stepTracker {
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 3rem;
          font-size: 0.9rem;
          width: 50%;
          max-width: 250px;

          &::before {
            content: "";
            position: absolute;
            height: 1.5px;
            width: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: #e1e1e1;
          }

          & > div {
            width: 2rem;
            height: 2rem;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            font-weight: 500;
            background: #e1e1e1;

            & > .stepTitle {
              position: absolute;
              bottom: -3.5rem;
              left: 50%;
              transform: translate(-50%, -50%);
              font-size: 0.7rem;
              text-align: center;
            }
          }

          & > .currentStep {
            background: var(--main-color);
            color: white;

            & > .stepTitle {
              color: var(--font-color);
            }
          }
        }
      }

      & > .submitBtn {
        margin-top: auto;
        padding: 0.6rem 2.5rem;
        border-radius: 35px;
        background: var(--main-color);
        color: white;
        font-weight: 500;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: auto;
        width: max-content;
        margin-top: 2rem;
        transition: all linear 0.3s;

        &:hover {
          scale: 1.05;
        }
      }

      & > .userDetailsContainer {
        padding: 1rem;
        border-radius: 10px;
        background: #f5f5f5;
        overflow-y: auto;
        width: 100%;
        // margin: auto;

        & > .title {
          font-size: 0.9rem;
          font-weight: 500;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 1rem 0;
        }

        & > .eachDetails {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          margin-bottom: 2rem;

          & > .threeDivContainer {
            display: flex;
            gap: 1rem;
            align-items: center;
            justify-content: space-between;

            & > :nth-child(1) {
              width: 50%;
            }

            & > :nth-child(2) {
              width: 25%;

              & > div {
                position: relative;
                & > input {
                  margin-top: 0;
                }
              }

              & > input {
                margin-top: 0;
              }
            }

            & > :nth-child(3) {
              width: 25% !important;

              & > input {
                margin-top: 0;
              }
            }

            & > .ageBox {
              padding: 1rem 1.5rem;
              border-radius: 10px;
              border: 1px solid rgba(229, 229, 229, 0.4);
              font-size: 0.8rem;
              background: #ffffff;
            }
          }

          & > .twoDivContainer {
            display: flex;
            gap: 1rem;
            align-items: center;
            justify-content: space-between;

            & > :nth-child(1) {
              width: 50%;
            }

            & > :nth-child(2) {
              width: 50%;
            }
          }

          & > .singleDivContainer {
            display: flex;
            gap: 1rem;
            align-items: center;
            justify-content: space-between;

            & > :nth-child(1) {
              width: 30%;
            }

            & > .ageBox {
              padding: 1rem 1.5rem;
              border-radius: 10px;
              border: 1px solid rgba(229, 229, 229, 0.4);
              font-size: 0.8rem;
              background: #ffffff;
            }
          }
        }

        & > .submitBtn {
          margin-top: auto;
          padding: 0.6rem 2.5rem;
          border-radius: 35px;
          background: var(--main-color);
          color: white;
          font-weight: 500;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: auto;
          margin-bottom: 2rem;
          width: max-content;
          transition: all linear 0.3s;

          &:hover {
            scale: 1.05;
          }
        }
      }
    }

    & > .rightContainer {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      min-width: 50%;
      padding: 1rem;
      width: 50%;

      & > .mainContainer {
        width: 100%;
        height: 100%;
        background: var(--main-color);
        border-radius: 20px;
        padding: 3rem 2.5rem;
        padding-bottom: 1rem;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2rem;

        & > .title {
          font-size: 2rem;
          font-weight: 550;
        }

        & > .desc {
          font-size: 1.2rem;
          font-weight: 500;
          text-align: center;
        }

        & > .toggleSections {
          display: flex;
          justify-content: center;
          gap: 1rem;
          width: 100%;

          & > div {
            background: inherit;
            border: 1.5px solid white;
            border-radius: 35px;
            color: white;
            padding: 0.5rem 1.2rem;
            width: max-content;
            font-size: 0.8rem;
            font-weight: 550;
            height: max-content;
            white-space: nowrap;
            text-align: center;
            cursor: pointer;
          }

          & > .selectedToggle {
            color: var(--main-color);
            background: white;
          }
        }

        & > .sectionDetails {
          width: 100%;
          height: 100%;
          overflow-y: scroll;
          display: flex;
          flex-direction: column;
          gap: 1rem;

          & > .eachDetail {
            background: rgba(255, 255, 255, 0.6);
            width: 100%;
            padding: 1rem;
            border-radius: 35px;
            font-size: 1rem;
            font-weight: 550;
            line-height: 1.6;
            display: flex;
            gap: 1.5rem;
            align-items: center;

            & > img {
              height: 100%;
              object-fit: contain;
            }

            & > span {
              color: var(--main-color);
            }
          }
        }

        & > .allInsurance {
          display: flex;
          flex-direction: column;
          gap: 1.2rem;
          width: 100%;
          overflow-y: scroll;
          padding-right: 10px;

          &::-webkit-scrollbar {
            display: block;
            width: 7px; /* Set the width of the table's scrollbar */
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            height: 7px;
          }

          &::-webkit-scrollbar-thumb {
            background-color: var(
              --font-color
            ); /* Set the color of the scrollbar thumb */
            border-radius: 5px; /* Set the border-radius of the thumb */
            height: 80px;
            transition: all linear 0.3s;
            cursor: pointer;
          }

          &::-webkit-scrollbar-thumb:hover {
            background-color: var(
              --main-color
            ); /* Set the color when the scrollbar thumb is hovered */
          }

          & > .eachInsurance {
            margin: 1rem 0;
            width: 100%;

            & > .title {
              font-weight: 520;
              margin: 1rem 0;
            }

            & > .boxContainer {
              display: flex;
              flex-direction: column;
              gap: 0.5rem;
              padding: 1rem;
              border-radius: 10px;
              background: #ffffff99;
              color: var(--main-color);
              padding-bottom: 1.5rem;

              & > .eachItem {
                font-size: 0.9rem;
                font-weight: 520;

                & > .title {
                  margin: 0.5rem 0;
                }

                & > .valueItem {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  padding: 0.6rem 1rem;
                  background: #ffffff99;
                  border-radius: 10px;

                  & > span {
                    display: flex;
                    align-items: center;
                  }
                }
              }
            }
          }
        }
      }

      & > .successContainer {
        background: white;
        width: 45%;
        height: 90%;
        border-radius: 20px;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2rem;

        & > img {
          height: 6rem;
        }

        & > .title {
          font-size: 1.5rem;
          font-weight: 520;
        }

        & > ol {
          list-style: decimal;
          font-size: 1rem;
        }

        & > .submitBtn {
          margin-top: 2rem;
          padding: 0.6rem 2.5rem;
          border-radius: 35px;
          background: var(--main-color);
          color: white;
          font-weight: 500;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          width: max-content;
          transition: all linear 0.3s;

          &:hover {
            scale: 1.05;
          }
        }
      }
    }
  }
}

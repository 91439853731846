.wellnessComingSoon {
  background: white;
  width: 100%;
  height: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;

  & > img {
    width: 100%;
  }

  & > .title {
    font-size: 2rem;
    font-weight: 550;
  }

  & > .desc {
    font-size: 1rem;
  }
}

.toggle-switch {
  width: 50px;
  height: 27px;
  // background-color: #ccc;
  border: 1px solid #e7e7e7;
  border-radius: 25px;
  position: relative;
  cursor: pointer;
}

.slider {
  position: absolute;
  top: 2.5px;
  bottom: 2.5px;
  left: 2.5px;
  width: 20px;
  height: 20px;
  background-color: var(--main-color);
  border-radius: 50%;
  transition: 0.3s;
}

.active {
  transform: translateX(23px);
}

.topSection {
  padding: 15px;
  display: grid;
  grid-template-columns: 30% 65%;
  background-color: white;
  gap: 5%;
  border-radius: 15px;
  border: 1px solid #e9ebf3;
  .leftSection {
    // background-color: red;
    // border-radius: 15px;
    .balanceLabel {
      color: var(--main-color);
      font-family: "Avenir", "Sen", sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      padding-bottom: 10px;
    }
    .balanceStyle {
      color: var(--main-color);
      font-family: "Avenir", "Sen", sans-serif;
      font-size: 35px;
      font-style: normal;
      font-weight: 600;
    }
    .btnContainer {
      margin-top: 30px;
      border-radius: 5px;
      background: var(--opacity-main);
      width: 202px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;

      text-align: center;
      font-family: "Avenir", "Sen", sans-serif;
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
    }
  }
  .rightSection {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 18px;
    .cardStyle {
      padding: 10px 10px;
      border-radius: 10px;
      border: 1px solid #e9ebf3;
      background: #fff;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      img {
        width: 27px;
        height: 25px;
      }
      .cLabel {
        color: var(--main-color);
        font-family: "Avenir", "Sen", sans-serif;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
      }
      .cValue {
        color: var(--main-color);
        font-family: "Avenir", "Sen", sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
      }
    }
  }
}

.quote {
  height: 100vh;
  width: 100%;

  & > .topSection {
    height: 65vh;
    background: var(--main-color);
    position: relative;

    & > .quoteFormContainer {
      width: 100%;
      height: 100%;
      position: relative;
      top: 100%;
      left: 50%;
      bottom: -30vh;
      max-width: 1400px;
      width: 100%;
      transform: translate(-50%, -50%);
      display: flex;
      gap: 2rem;
      z-index: 2;
      max-height: 550px;

      & > div {
        height: 100%;
        border-radius: 35px;
        background: white;
        border: 1px solid rgba(229, 229, 229, 1);
      }

      & > .leftDiv {
        width: 70%;
        position: relative;
        border-radius: 35px;

        & > div {
          border-radius: 35px;
        }

        & > .topDiv {
          height: 4.5rem;
          padding: 0.75rem 3rem;
          display: flex;
          align-items: center;
          gap: 1.5rem;
          border-radius: 35px 35px 0 0;
          border-bottom: 1px solid rgba(229, 229, 229, 1);
          overflow-x: scroll;

          & > div {
            text-align: center;
            font-size: 0.9rem;
            cursor: pointer;
            padding: 10px 2rem;
            border-radius: 35px;
            font-size: 500;
            white-space: nowrap;
            transition: all linear 0.3s;

            &:hover {
              text-decoration: underline;
            }
          }

          & > .selectedFormOption {
            background: var(--main-color);
            color: white;
            font-weight: 550;

            &:hover {
              text-decoration: none;
              scale: 1.05;
            }
          }
        }

        & > .bottomDiv {
          height: calc(100% - 4.5rem);
          padding: 2rem 3rem;
          position: relative;
          overflow: hidden;

          & > .transitionSection {
            margin: 2rem 3rem;
            position: absolute;
            inset: 0;
            display: flex;
            flex-direction: column;
            gap: 2.5rem;
            transition: all linear 0.3s;

            & > .title {
              font-size: 1.6rem;
              font-weight: 450;
            }

            & > .inputContainer {
              display: flex;
              flex-direction: column;
              gap: 1.5rem;

              & > .message {
                margin: 1.2rem 0;
                font-weight: 500;
                font-size: 1rem;
              }

              & > .selectedOne {
                display: flex;
                flex-direction: column;
                gap: 1rem;
                padding: 2.5rem 4.5rem;
                border-radius: 35px;
                border: 1px solid rgba(229, 229, 229, 1);
                cursor: pointer;
                transition: all linear 0.3s;

                & > img {
                  width: 40px;
                  height: 40px;
                }

                & > div {
                  font-size: 1rem;
                  font-weight: 500;
                  text-align: center;
                }

                &:hover {
                  border: 1px solid var(--main-color);
                }
              }

              & > .inputField {
                border: 1px solid rgba(229, 229, 229, 1);
                padding: 1rem 1.5rem;
                border-radius: 35px;
                font-size: 1rem;
                font-weight: 500;
                max-width: 500px;
                transition: all linear 0.3s;

                &::placeholder {
                  opacity: 0.5;
                }

                &:hover {
                  border: 1px solid var(--main-color);
                }

                &:focus {
                  border: 1px solid var(--main-color);
                }
              }

              & > .assetsContainer {
                display: flex;
                flex-wrap: wrap;
                gap: 1.5rem;
                // padding-top: 1rem;

                & > .eachAsset {
                  padding: 1rem;
                  border-radius: 20px;
                  border: 1px solid #e5e5e5;
                  width: 20%;
                  aspect-ratio: 1/1;
                  display: flex;
                  position: relative;
                  overflow: hidden;
                  background: white;
                  // opacity: 0.5;
                  // pointer-events: none;
                  transition: all linear 0.3s;

                  &:hover {
                    border: 1px solid #5f6163;
                    box-shadow: 0px 4px 4px 0px #00000040;
                  }

                  & > div {
                    width: 50%;
                  }

                  & > :nth-child(1) {
                    height: 50%;
                    display: flex;
                    align-items: flex-end;
                    justify-content: flex-start;
                    font-size: 1.1rem;
                    font-weight: 550;
                  }

                  & > :nth-child(2) {
                    display: flex;
                    align-items: flex-end;
                    justify-content: flex-end;

                    & > .shadeArea {
                      position: absolute;
                      bottom: 0;
                      right: -2rem;
                      background: var(--opacity-main) 14;
                      border-radius: 0 75px 0 25px;
                      rotate: -90deg;
                      top: 65%;
                      width: 60%;
                    }

                    & > svg {
                      width: 55%;
                      object-fit: contain;
                      margin-right: 20%;
                      margin-bottom: 30%;
                    }
                  }
                }

                & > .selectedAsset {
                  border: 2px solid var(--main-color);
                }
              }
            }

            & > .changeOption {
              max-width: 450px;

              & > .title {
                font-size: 0.8rem;
                text-decoration: underline;
                font-weight: 550;
                padding-bottom: 1.2rem;
              }

              & > .options {
                display: flex;
                flex-direction: column;
                gap: 1.5rem;
                font-size: 1rem;

                & > div {
                  cursor: pointer;
                  transition: all linear 0.3s;

                  &:hover {
                    transform: translateX(15px);
                  }
                }
              }

              & > .uploadDocument {
                border: 1px solid #e5e5e5;
                border-radius: 15px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 15px;
                width: 100%;
                font-size: 0.9rem;

                &:hover {
                  border: 1px solid var(--main-color);

                  & > img {
                    scale: 1.25;
                  }
                }

                & > img {
                  width: 50px;
                  height: 50px;
                  transition: all linear 0.3s;
                }

                & > span {
                  font-size: 0.9rem;
                }

                & > .textArea {
                  width: 100%;
                  padding: 1rem;
                  border: none;
                  outline: none;
                  border-radius: 15px;

                  &::placeholder {
                    color: #5f616380;
                  }
                }
              }
            }

            & > .btnsContainer {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-top: auto;

              & > div {
                padding: 0.5rem 4rem;
                border-radius: 35px;
                font-size: 1.1rem;
                font-weight: 500;
                cursor: pointer;
                transition: all linear 0.3s;

                &:hover {
                  font-size: 1.15rem;
                }
              }

              & > :nth-child(1) {
                border: 1px solid rgba(229, 229, 229, 1);
              }

              & > :last-child {
                background: var(--main-color);
                color: white;
              }
            }
          }
        }

        & > .successMessage {
          position: absolute;
          inset: 0;
          z-index: 5;
          background: white;
          border-radius: 35px;
          padding: 2rem 4rem;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          gap: 2rem;

          & > img {
            width: 60px;
          }

          & > .title {
            font-size: 1.5rem;
            font-weight: 500;
            text-align: center;
          }

          & > .desc {
            font-size: 1.2rem;
            font-weight: 550;
            color: var(--main-color);
            text-align: center;
          }

          & > .stepsList {
            width: max-content;
            line-height: 1.85;
            font-size: 0.95rem;

            & > li {
              & > span {
                & > span {
                  color: var(--main-color);
                  font-weight: 550;
                  padding: 2px;
                  cursor: pointer;
                  border-bottom: 1.5px solid transparent;
                  transition: all linear 0.1s;

                  &:hover {
                    border-bottom: 1.5px solid var(--main-color);
                  }
                }
              }
            }
          }
        }

        & > .loadingMessage {
          position: absolute;
          inset: 0;
          z-index: 5;
          background: white;
          border-radius: 35px;
          padding: 2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          gap: 4rem;

          & > img {
            width: 150px;
            animation: scaleAnimation 2s infinite;
          }

          @keyframes scaleAnimation {
            0% {
              transform: scale(1);
            }
            50% {
              transform: scale(1.2);
            }
            100% {
              transform: scale(1);
            }
          }

          & > div {
            font-size: 1.2rem;
            text-align: center;
          }
        }
      }

      & > .rightDiv {
        width: 30%;
        padding: 0.4rem;

        & > .topDivider {
          background: var(--main-color);
          border-radius: 35px 35px 0 0;
          height: 140px;
          position: relative;

          & > img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%);
            height: 140px;
            width: 140px;
            border-radius: 50%;
          }
        }

        & > .bottomContent {
          padding: 0 2rem;
          padding-top: 90px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: 1rem;
          height: calc(100% - 150px);

          & > .name {
            font-size: 1.2rem;
            font-weight: 550;
            text-align: center;
          }

          & > .desc {
            font-size: 0.9rem;
            text-align: center;
          }

          & > .btnsContainer {
            display: flex;
            flex-direction: column;
            gap: 0.6rem;

            & > div {
              padding: 0.7rem 2rem;
              text-align: center;
              border-radius: 35px;
              font-size: 0.85rem;
              font-weight: 500;
              cursor: pointer;
              transition: all linear 0.3s;

              &:hover {
                scale: 1.05;
              }
            }

            & > :nth-child(1) {
              border: 1px solid #e5e5e5;
            }
            & > :nth-child(2) {
              color: white;
              background: var(--main-color);
              display: flex;
              justify-content: center;
              align-items: center;

              & > img {
                width: 15px;
                height: 15px;
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
  }

  & > .bottomSection {
    height: 35vh;
    width: 100%;
    position: relative;
  }
}

.eachInput {
  & > .title {
    font-size: 0.9rem;
  }

  & > .inputContainer {
    padding: 1rem 1.5rem;
    border-radius: 35px;
    border: 1px solid #e5e5e566;
    font-size: 0.8rem;
    background: white;
    margin-top: 1rem;
    width: 100%;
    max-width: 500px;

    &:focus {
      outline: var(--main-color);
      background: white;
      border: 1.5px solid var(--main-color);
    }
  }
}

//custom dropdown

.eachCustomDropdown {
  position: relative;
  min-width: 200px;
  height: 6rem;
  cursor: pointer;

  & > .inputContainer {
    padding: 1rem 1.5rem;
    border-radius: 35px;
    border: 1px solid rgba(229, 229, 229, 1);
    font-size: 0.8rem;
    background: white;
    width: 80%;
    max-width: 400px;
    position: absolute;
    top: 0;
    z-index: 2;
    max-height: 250px;
    overflow-y: scroll;
    background: white;
    transition: all ease-in 0.2s;

    &:hover {
      background: #e5e5e5;

      input {
        background: #e5e5e566;
      }
    }

    & > span {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > :nth-child(1) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        opacity: 0.5;

        & > img {
          width: 20px;
          height: 20px;
          border-radius: 50%;
        }
      }
    }

    &:focus {
      outline: var(--main-color);
      background: white;
      border: 1.5px solid var(--main-color);
    }

    & > ul {
      list-style: none;
      cursor: pointer;
      margin: 0;
      margin-top: 1rem;

      & > li {
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: 1rem 0rem;

        & > img {
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }

        & > div {
          & > :first-child {
            font-size: 1rem;
            font-weight: 550;
          }

          & > :last-child {
            font-size: 0.8rem;
            padding-top: 3px;
          }
        }
      }

      & > li:has(input) {
        padding: 0;

        & > input {
          height: 100%;
          width: 100%;
          border: none;
          outline: none;
          padding: 0.75rem 0rem;
          color: var(--font-color);
          transition: all ease-in 0.2s;
          background: inherit;

          :focus {
            border: 2px solid var(--main-color);
          }
        }
      }
    }

    ::-webkit-scrollbar {
      width: 0.5em; /* Adjust the width as needed */
      background-color: transparent; /* Adjust the background color as needed */
      display: none;
    }

    ::-webkit-scrollbar-thumb {
      background-color: transparent; /* Adjust the thumb color as needed */
      display: none;
    }
  }
}

.profile {
  height: 100%;

  & > .navbar {
    padding: 0rem 2rem;
    display: flex;
    justify-content: space-between;
    background: white;

    & > .leftContainer {
      display: flex;

      & > .navbarOption {
        padding: 1.7rem 1.5rem;
        font-size: 1rem;
        font-weight: 400;
        width: max-content;
        cursor: pointer;
      }

      & > .navbarOptionSelected {
        color: var(--main-color);
        font-weight: 600;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          height: 4px;
          left: 0;
          right: 0;
          bottom: 0;
          border-radius: 35px 35px 0 0;
          background: var(--main-color);
        }
      }
    }

    & > .rightContainer {
      display: flex;
      align-items: center;
      gap: 1rem;

      & > .searchDiv {
        border: 1px solid #e5e5e5;
        border-radius: 35px;
        position: relative;
        width: max-content;

        & > input {
          border-radius: 35px;
          outline: none;
          border: none;
          padding: 0.5rem 4rem;
          padding-left: 3rem;
          font-size: 0.8rem;
          width: 100%;
          max-width: 300px;

          :focus {
            border: 2px solid var(--main-color);
          }

          &::placeholder {
            opacity: 0.4;
          }
        }

        & > svg {
          position: absolute;
          top: 50%;
          left: 1rem;
          transform: translate(0%, -50%);
          height: 15px;
          width: 15px;
        }
      }
    }
  }

  & > .rahastoreDetails {
    // overflow-y: scroll;
    height: calc(100% - 4rem);
    background: #e5e5e536;
    overflow-y: scroll;
    position: relative;

    & > .mainFlex {
      width: 100%;
      background: white;
      border-radius: 35px;

      & > img {
        width: 100%;
        object-fit: contain;
      }
    }

    & > .insuranceCompanies {
      & > .title {
        font-size: 1.2rem;
        font-weight: 550;
        padding: 2rem 0;
      }

      & > .allCompanies {
        display: flex;
        align-items: center;
        gap: 1rem;

        & > :nth-child(1) {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          gap: 1rem;
          width: -webkit-fill-available;

          & > div {
            border-radius: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid var(--font-color);
            font-weight: 500;
            background: white;
            font-size: 1rem;
            cursor: pointer;
            padding: 0.6rem;
          }

          & > .selectedCompany {
            border: 1px solid var(--main-color);
            color: var(--main-color);
            font-weight: 550;
          }
        }

        & > .allCompaniesBtn {
          color: white;
          background: var(--main-color);
          padding: 0.6rem 1.5rem;
          border: none;
          border-radius: 35px;
          white-space: nowrap;
          font-weight: 500;
          cursor: pointer;
          font-size: 1rem;
        }
      }
    }

    & > .companyDetailed {
      width: 100%;
      height: 35%;
      background: white;
      border-radius: 35px;
      margin-top: 2rem;
      padding: 2rem;

      & > .companyTitle {
        font-size: 1.2rem;
        font-weight: 550;
      }

      & > .allItems {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        justify-content: space-between;
        gap: 1.5rem;
        flex-wrap: wrap;
        margin-top: 1.5rem;
        padding-bottom: 2rem;

        & > .eachWellnessItem {
          max-width: 120px;
          cursor: pointer;
          transition: all linear 0.3s;

          & > img {
            width: 100%;
            border-radius: 30px;
            aspect-ratio: 1/1;
            transition: all linear 0.3s;
            border: 0.5px solid #e5e5e5;
          }

          & > div {
            font-size: 1rem;
            font-weight: 500;
            padding: 1rem 0;
            text-align: center;
            transition: all linear 0.3s;
          }

          &:hover {
            & > img {
              box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            }

            & > div {
              font-weight: 550;
            }
          }
        }
      }
    }
  }
}

.sidebarMenu {
  width: 45%;
  max-width: 500px;
  position: absolute;
  top: 1px;
  bottom: 0;
  right: -150%;
  background: white;
  border-left: 0.25px solid #e5e5e5;
  border-top: 0.25px solid #e5e5e5;
  border-bottom: 0.25px solid #e5e5e5;
  transition: all linear 0.3s;

  & > .eachItemDetailed {
    height: 100%;
    overflow-y: scroll;
    padding-bottom: 2rem;
    display: flex;
    flex-direction: column;

    & > .emptyCard {
      height: 130px;
      padding: 1rem;
      position: relative;

      & > img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        position: absolute;
        inset: 0;
      }

      & > div {
        font-size: 0.9rem;
        font-weight: 500;
        color: white;
        cursor: pointer;
        transition: all linear 0.3s;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    & > .allDetails {
      position: relative;
      padding: 0 1rem;

      & > .mainDetail {
        display: flex;
        gap: 1rem;
        transform: translateY(-25px);

        & > img {
          background: white;
          border-radius: 20px;
          width: 90px;
          height: 90px;
          border: 0.5px solid #e5e5e5;
        }

        & > div {
          line-height: 1.5;
          margin-top: auto;
          padding-bottom: 5px;

          & > :nth-child(1) {
            font-size: 1rem;
            font-weight: 500;
          }
          & > :nth-child(2) {
            font-size: 0.75rem;
          }
        }
      }

      & > .desc {
        margin-top: 1rem;

        & > .title {
          font-size: 0.9rem;
          font-weight: 500;
        }

        & > .para {
          font-size: 0.85rem;
          margin-top: 1.2rem;
          line-height: 1.6;
        }
      }

      & > .offerings {
        margin-top: 2.5rem;

        & > .title {
          font-size: 0.9rem;
          font-weight: 500;
        }

        & > .titleBig {
          font-size: 1.4rem;
          font-weight: 550;
        }

        & > .allOfferings {
          margin-top: 1.2rem;

          & > .eachOffering {
            margin-bottom: 1rem;
            border: 1px solid #e5e5e5;
            border-radius: 20px;
            padding: 1rem;
            display: flex;
            gap: 1rem;
            position: relative;

            & > :nth-child(1) {
              width: 100px;
              height: 100px;
              padding: 1rem;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 10px;

              & > svg {
                height: 100%;
              }

              & > img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 10px;
              }
            }

            & > :nth-child(2) {
              display: flex;
              flex-direction: column;
              justify-content: space-evenly;

              & > :nth-child(1) {
                & > .title {
                  font-size: 0.9rem;
                  font-weight: 500;
                }

                & > .learnBtn {
                  font-size: 0.7rem;
                  font-weight: 500;
                  text-decoration: underline;
                  margin-top: 10px;
                  cursor: pointer;
                  transition: all linear 0.3s;

                  &:hover {
                    color: #04bfda;
                  }
                }

                & > .desc {
                  font-size: 0.7rem;
                  font-weight: 500;
                  margin-top: 10px;
                  cursor: pointer;
                }
              }

              & > .priceDiv {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: auto;

                & > :nth-child(1) {
                  font-size: 0.9rem;
                  font-weight: 500;

                  & > span {
                    text-decoration: line-through;
                    opacity: 0.5;
                    margin-left: 5px;
                  }
                }

                & > .btn {
                  font-size: 0.6rem;
                  padding: 7px 1.5rem;
                  border-radius: 35px;
                  cursor: pointer;
                  border: 1px solid #e5e5e5;
                  transition: all linear 0.3s;

                  &:hover {
                    background: var(--main-color);
                    color: white;
                    border: 1px solid var(--main-color);
                  }
                }

                & > .addButton {
                  display: flex;
                  justify-content: space-between;
                  gap: 10px;

                  & > .btns {
                    font-size: 1rem;
                    background: #e5e5e5;
                    width: 1.5rem;
                    height: 1.5rem;
                    text-align: center;
                    border-radius: 5px;
                    cursor: pointer;
                  }

                  & > :nth-child(2) {
                    width: 1rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  }
                }
              }
            }

            & > img {
              position: absolute;
              top: -0.5rem;
              right: -0.5rem;
              width: 1.5rem;
              height: 1.5rem;
              cursor: pointer;
            }
          }
        }
      }
    }

    & > .bottomDiv {
      margin: 2rem 1rem;
      margin-top: auto;

      & > .buyDetails {
        & > .title {
          font-size: 1rem;
          font-weight: 500;
          margin-bottom: 1rem;
        }

        & > .priceBreakdown {
          box-shadow: 0px 2px 2px 0px #00000040;
          border: 1px solid #e7e7e7;
          border-radius: 10px;

          & > div {
            padding: 1rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 0.9rem;

            & > :nth-child(1) {
              font-weight: 550;
            }
          }

          & > :nth-child(1) {
            border-bottom: 1px solid #e7e7e7;
          }
        }
      }

      & > .buyBtn {
        background: var(--main-color);
        color: white;
        font-size: 1rem;
        font-weight: 500;
        text-align: center;
        padding: 0.5rem;
        border-radius: 35px;
        cursor: pointer;
        border: 1px solid var(--main-color);
        margin-top: 3rem;
        transition: all linear 0.3s;

        &:hover {
          background: white;
          color: var(--main-color);
        }
      }
    }
  }

  & > .allWellnessItems {
    padding: 1.5rem;

    & > .title {
      font-size: 1.2rem;
      font-weight: 550;
      padding: 1rem 0 2rem 0;
    }

    & > .allItems {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      justify-content: space-between;
      gap: 1.5rem;
      flex-wrap: wrap;

      & > .eachWellnessItem {
        max-width: 150px;
        cursor: pointer;
        transition: all linear 0.3s;

        & > img {
          width: 100%;
          border-radius: 30px;
          aspect-ratio: 1/1;
          transition: all linear 0.3s;
          border: 0.5px solid #e5e5e5;
        }

        & > div {
          font-size: 1rem;
          font-weight: 500;
          padding: 1rem 0;
          text-align: center;
          transition: all linear 0.3s;
        }

        &:hover {
          & > img {
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
          }

          & > div {
            font-weight: 550;
          }
        }
      }
    }
  }
}

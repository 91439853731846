.shipAndDelivery,
.refundAndCancellation {
  color: white;
  max-width: 1400px;
  margin: 0 auto;
  padding-top: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: center;

  & > .contentDiv {
    max-width: 1100px;
    margin: 0 auto;
    width: 100%;

    & > .title {
      font-size: 2.5rem;
      font-weight: 550;
      // text-align: center;
    }

    .subTitle {
      font-size: 1.2rem;
      font-weight: 450;
      text-decoration: underline;
    }

    & > .threeColumnTable {
      margin: 1.5rem 0;
      & > thead {
        background: #e1e1e1;
        th {
          text-align: center;
        }
      }

      & > thead,
      tbody {
        width: 100%;
        border: 1px solid white;
      }

      tr {
        border-bottom: 1px solid white;

        & > :nth-child(2) {
          padding: 0 15px;
          border-left: 1px solid white;
          border-right: 1px solid white;
        }
      }

      td {
        padding: 1rem;

        & > div {
          padding: 1rem;
        }
        & > :nth-child(1) {
          border-bottom: 1px solid white;
        }
      }
    }

    p {
      font-size: 1rem;
      margin-top: 1rem;
    }

    ol {
      margin-left: 3rem;
    }
  }

  & > .title {
    font-size: 2.5rem;
    font-weight: 550;
    text-align: center;
  }

  .subTitle {
    font-size: 1.2rem;
    font-weight: 450;
    text-decoration: underline;
  }

  & > .desc {
    font-size: 1.1rem;
    padding-left: 2rem;
    max-width: 1100px;
    margin: 0 auto;
    margin-top: 2rem;
  }

  & > .allPolicies {
    padding-left: 2rem;
    max-width: 1100px;
    margin: 0 auto;
    margin-top: 2rem;

    & > .eachPolicy {
      line-height: 1.7;

      & > span {
        font-size: 1.2rem;
        font-weight: 5900;
      }

      & > ul,
      ol {
        font-size: 1rem;
        margin-top: 1rem;
        padding-left: 0.5rem;
        list-style: none;
      }

      & > div {
        font-size: 1rem;
        margin-top: 1rem;
        padding-left: 0.5rem;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .shipAndDelivery,
  .refundAndCancellation {
    padding: 0 1rem;
    padding-top: 170px;
  }
}

.privacy {
  height: 100vh;
  overflow-y: scroll;
}
.privacyContainer {
  padding: 2rem;
  padding-top: 15rem;
  max-width: 1400px;
  margin: 0 auto;
  font-size: 1.2rem;
  line-height: 1.5;
  font-family: "Roboto", sans-serif;
  color: #333;
}
h1 {
  font-weight: 700;
}
h3 {
  font-weight: 700;
  font-size: 1.5rem;
}
h6 {
  font-weight: 700;
  font-size: 1.2rem;
}
p {
  font-size: 1.2rem;
}
li {
  margin-bottom: 1rem;
}

//custom checkbox start...

.custom-checkbox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  display: inline-block;
  width: 24px; /* Adjusted width to accommodate the tick mark */
  height: 24px; /* Adjusted height to accommodate the tick mark */
}

.custom-checkbox input {
  opacity: 0;
  width: 0;
  height: 0;
}

.checkmark {
  position: absolute;
  top: 1px;
  left: 1px;
  height: 22px; /* Adjusted height to accommodate the tick mark */
  width: 22px; /* Adjusted width to accommodate the tick mark */
  background-color: #fff;
  border: 2px solid #ccc;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkedBox {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background: var(--main-color);
}

.checkmark.checked {
  /* No longer need to set background color or border here */
  border: 1px solid #e5e5e5;
}

.checkmark svg {
  width: 16px;
  height: 16px;
  color: var(--main-color);
}

/* Add additional styles for the green tick mark */
.checkmark.checked svg {
  display: block;
  /* Replace 'green_tick.png' with the actual path to your green tick mark image */
}

//custom checkbox end...

.overlay {
  position: fixed;
  inset: 0;
  background: #00000066;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;

  & > .overlayContent {
    background: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding: 3.5rem;

    & > .logo {
      width: 100px;
      height: 100px;
    }

    & > .message {
      font-size: 1.25rem;
      font-weight: 550;
      padding: 1.5rem 0;
      text-align: center;
    }
  }
}

//loading animation

.loadinganim_raha {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 4rem);
}

.loading__anim {
  width: 8vw;
  height: 8vw;
  max-width: 200px;
  max-height: 200px;
  display: inline-block;
  border: 7px solid rgba(111, 167, 149, 0.8);

  border-right-color: rgb(46, 198, 151);
  border-bottom-color: rgb(46, 198, 151);
  border-radius: 50%;
  animation: rotate 600ms infinite linear;
}

.rahaLogoloading {
  width: 240px;
  object-fit: contain;
  animation: heartbeat 1.3s infinite;
}

.copy_icon {
  fill: #5f6163;
  transition: all linear 0.2s;

  &:hover {
    font-weight: 550;
    fill: var(--main-color);
  }
}

@keyframes heartbeat {
  0% {
    transform: scale(0.75);
  }
  20% {
    transform: scale(1);
  }
  40% {
    transform: scale(0.75);
  }
  60% {
    transform: scale(1);
  }
  80% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(0.75);
  }
}

@keyframes rotate {
  to {
    transform: rotate(1turn);
  }
}

.visitInput {
  & > .title {
    font-size: 1rem;
    font-weight: 500;
  }

  & > .inputContainer {
    border-radius: 5px;
    padding: 0.5rem;
    width: 100%;
    font-size: 0.9rem;
    outline: none;
    border: 1px solid #e5e5e566;
    transition: all linear 0.15s;

    &::placeholder {
      color: #e5e5e5;
    }

    &:focus {
      border: 1px solid var(--main-color);
      background: white;
    }
  }

  & > .LoginButton {
  }
}

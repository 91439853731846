.membersForms {
  min-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 1.5rem;
  max-height: 70vh;
  overflow-y: scroll;

  & > .title {
    font-size: 1rem;
    font-weight: 550;
    text-align: left;
  }

  & > .eachInsurance {
    display: grid;
    grid-template-columns: 50px repeat(1, 1fr) 130px;
    align-items: center;
    gap: 0.4rem;
    font-size: 0.9rem;
    text-align: left;
    margin: 0.5rem 0;

    & > .relativeDiv {
      position: relative;

      & > div {
        min-width: unset;
      }
    }
  }

  & > .btnsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;

    & > button {
      padding: 0.5rem 1.5rem;
      cursor: pointer;
      border: none;
      outline: none;
      border-radius: 10px;
      font-weight: 500;
    }

    & > :nth-child(1) {
      background: white;
      border: 1px solid #e5e5e5;
    }
    & > :nth-child(2) {
      background: var(--main-color);
      color: white;
      border: 1px solid #e5e5e5;
    }
  }
}

.membersDetailsForm {
  min-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 1.5rem;
  max-height: 70vh;
  overflow-y: scroll;

  & > .title {
    font-size: 1rem;
    font-weight: 550;
    text-align: left;
  }

  & > .formFields {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;

    & > div {
      & > input {
        margin: 0;
      }
    }
  }

  & > .btnsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;

    & > button {
      padding: 0.5rem 1.5rem;
      cursor: pointer;
      border: none;
      outline: none;
      border-radius: 10px;
      font-weight: 500;
    }

    & > :nth-child(1) {
      background: white;
      border: 1px solid #e5e5e5;
    }
    & > :nth-child(2) {
      background: var(--main-color);
      color: white;
      border: 1px solid #e5e5e5;
    }
  }
}

.memberAddDependent {
  min-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 1.5rem;
  max-height: 70vh;
  overflow-y: scroll;

  & > .title {
    font-size: 1rem;
    font-weight: 550;
    text-align: left;
  }

  & > .allDependents {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 1rem;
  }

  & > .addDependentBtn {
    display: flex;
    gap: 10px;
    font-weight: 500;
    cursor: pointer;
    font-size: 0.9rem;
    margin: 1rem 0;
    transition: all linear 0.3s;

    & > svg {
      stroke: var(--font-color);
      transition: all linear 0.3s;
    }

    &:hover {
      color: var(--main-color);
      & > svg {
        stroke: var(--main-color) !important;
      }
    }
  }

  & > .btnsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;

    & > button {
      padding: 0.5rem 1.5rem;
      cursor: pointer;
      border: none;
      outline: none;
      border-radius: 10px;
      font-weight: 500;
    }

    & > :nth-child(1) {
      background: white;
      border: 1px solid #e5e5e5;
    }
    & > :nth-child(2) {
      background: var(--main-color);
      color: white;
      border: 1px solid #e5e5e5;
    }
  }
}

.membersDetailsForm {
  min-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
  padding: 1rem 1.5rem;
  max-height: 70vh;
  overflow-y: scroll;

  & > .title,
  .subTitle {
    font-size: 1rem;
    font-weight: 550;
    text-align: left;
  }

  & > .subTitle {
    text-decoration: underline;
    text-align: left;
  }

  & > .detailsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    & > :nth-child(1) {
      display: flex;
      flex-direction: column;
      gap: 5px;
      align-items: flex-start;

      & > :nth-child(1) {
        font-size: 0.9rem;
        font-weight: 550;
      }
      & > :nth-child(2) {
        font-size: 0.8rem;
      }
    }

    & > :nth-child(2) {
      font-size: 0.9rem;
      font-weight: 500;
    }
  }
}

.bulkUpload {
  min-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 1.5rem;
  max-height: 70vh;
  overflow-y: scroll;

  & > .title {
    font-size: 1rem;
    font-weight: 550;
    text-align: left;
  }

  & > .bulkDocuments {
    display: flex;
    gap: 1rem;

    & > div {
      padding: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
      font-weight: 400;
      gap: 1rem;
      border-radius: 10px;
      border: 1px solid white;
      transition: all linear 0.3s;

      &:hover {
        border: 1px solid #e5e5e5;
      }
    }
  }

  & > .btnsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;

    & > button {
      padding: 0.5rem 1.5rem;
      cursor: pointer;
      border: none;
      outline: none;
      border-radius: 10px;
      font-weight: 500;
    }

    & > :nth-child(1) {
      background: white;
      border: 1px solid #e5e5e5;
    }
    & > :nth-child(2) {
      background: var(--main-color);
      color: white;
      border: 1px solid #e5e5e5;
    }
  }
}

.bulkUploadMemberUpdated {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 1.5rem;
  max-height: 70vh;
  overflow-y: scroll;

  & > .title {
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    margin-left: auto;
  }

  & > .bulkDocuments {
    display: flex;
    gap: 1rem;

    & > div {
      padding: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
      font-weight: 400;
      gap: 1rem;
      border-radius: 10px;

      & > .diffTypes {
        display: flex;

        & > div {
          padding: 0.5rem 1rem;
          font-size: 0.85rem;
          white-space: nowrap;
        }

        & > :nth-child(2) {
          border-left: 1px solid #e5e5e5;
          border-right: 1px solid #e5e5e5;
        }
      }

      & > .balanceContainer {
        display: flex;
        flex-direction: column;
        gap: 6px;
        justify-content: center;

        & > :nth-child(1) {
          font-size: 0.8rem;
          font-weight: 550;
        }
      }
    }
  }
}

.formUpdateCompleted {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 1.5rem;
  max-height: 70vh;
  overflow-y: scroll;
  color: var(--font-color) !important;

  & > .title {
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    margin-left: auto;
  }

  & > .bulkDocuments {
    display: flex;
    gap: 1rem;

    & > div {
      padding: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
      font-weight: 400;
      gap: 1rem;
      border-radius: 10px;

      & > .diffTypes {
        display: flex;

        & > div {
          padding: 0.5rem 1rem;
          font-size: 0.85rem;
          white-space: nowrap;
        }

        & > :nth-child(2) {
          border-left: 1px solid #e5e5e5;
          border-right: 1px solid #e5e5e5;
        }
      }

      & > .balanceContainer {
        display: flex;
        flex-direction: column;
        gap: 6px;
        justify-content: center;

        & > :nth-child(1) {
          font-size: 0.8rem;
          font-weight: 550;
        }
      }

      & > .contactUs {
        & > .title {
          font-size: 1rem;
          text-align: center;
          margin-bottom: 1rem;
        }

        & > .contactDiv {
          padding: 1rem;
          border: 1px solid #e5e5e5;
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          gap: 1rem;
          text-align: center;

          & > div {
            font-size: 0.8rem;
            display: flex;
            gap: 0.5rem;
            align-items: center;

            & > .iconDiv {
              display: flex;
              align-items: center;
              font-size: 1rem;
              color: var(--main-color);
              fill: var(--main-color);
            }
          }
        }
      }

      & > span {
        text-align: center;
      }
    }
  }
}

//custom inputs

.eachInput {
  height: 100%;

  & > .title {
    font-size: 0.9rem;
  }

  & > .fileAction {
    display: flex;
    padding: 1rem 2.5rem;
    // border: 1.5px solid rgb(229, 229, 229);
    border-radius: 35px;
    align-items: center;
    gap: 15px;
    width: 100%;
    // max-width: 450px;
    cursor: pointer;
    justify-content: center;
    flex-direction: column;

    & > img {
      // width: 2rem;
      height: 4rem;
    }

    & > span {
      font-size: 1rem;
      font-weight: 400;
      transition: all linear 0.3s;
    }
  }

  & > .inputContainer {
    padding: 1rem 1.5rem;
    border-radius: 35px;
    border: 1.5px solid rgb(229, 229, 229);
    font-size: 0.8rem;
    background: #e5e5e566;
    margin-top: 1rem;
    width: 100%;
    max-width: 500px;

    &:focus {
      outline: var(--main-color);
      background: white;
      border: 1.5px solid var(--main-color);
    }
  }
}

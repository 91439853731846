.discoverTab {
  height: 100%;
  overflow-y: scroll;

  & > .title {
    font-size: 1rem;
    font-weight: 550;
    margin: 4rem 0 1rem;
    padding: 0 10px;
  }

  & > .desc {
    font-size: 0.9rem;
  }

  & > .header {
    padding-bottom: 1rem;

    & > div {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & > .subTabs {
        display: flex;
        gap: 1rem;
        padding: 0 10px;

        & > div {
          font-size: 1rem;
          cursor: pointer;
          padding-bottom: 5px;
          transition: all linear 0.3s;
        }

        & > .selectedTab {
          border-bottom: 1px solid var(--font-color);
          padding: 4px;
          &:hover {
            background-color: #00000040;
          }
        }
        & > .nonSelectedTab {
          padding: 4px;
          &:hover {
            background-color: #00000040;
          }
        }
      }

      & > .locationFilter {
        display: flex;
        align-items: center;
        font-size: 0.9rem;
        gap: 5px;
        position: relative;

        & > label {
        }

        & > .city {
          font-weight: 550;
          text-decoration: underline;
          cursor: pointer;
          position: relative;

          &:hover {
            & > .addressPopover {
              display: flex;
            }
          }

          & > .addressPopover {
            display: none;
            position: absolute;
            width: 374px;
            border-radius: 15px;
            border: 1px solid #e9ebf3;
            background: #fff;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            top: 20px;
            right: 0;
            padding: 1.2rem 1rem;
            flex-direction: column;
            gap: 1.5rem;

            & > .location {
              display: flex;
              gap: 0.5rem;
              align-items: center;
              font-size: 1rem;

              & > span {
                font-weight: 520;
              }
            }

            & > .eachAddressField {
              display: flex;
              flex-direction: column;
              gap: 0.5rem;
              font-size: 0.9rem;

              & > :nth-child(1) {
                font-weight: 520;
              }

              & > :nth-child(2) {
                font-weight: 400;
              }
            }

            & > .greenBtn {
              width: 100%;
            }
          }
        }

        & > .svgContainer {
          position: relative;

          & > svg {
            cursor: pointer;
            position: relative;

            &:hover {
              color: #00000040;
            }
          }

          &:hover {
            & > .myselfDropdownOpen {
              display: unset;
            }
          }

          & > .myselfDropdownOpen {
            display: none;
            position: absolute;
            width: 374px;
            border-radius: 15px;
            border: 1px solid #e9ebf3;
            background: #fff;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            top: 20px;
            right: 0;
            & > input {
              width: 100%;
              border: none;
              padding: 16px;
              border-radius: 15px;
            }
            & > .myRelate {
              padding: 0 16px 16px 16px;
              width: 100%;
              & > .eachRelate {
                width: 100%;
                padding: 10px;
                border-radius: 10px;
                border: 1px solid #e9ebf3;
                background: #fff;
                margin: 10px 0;
                & > h6 {
                  font-size: 14px;
                }
                & > p {
                  font-size: 12px;
                }
              }
            }
          }
        }

        & > .myselfDropdownOpen {
          position: absolute;
          width: 374px;
          border-radius: 15px;
          border: 1px solid #e9ebf3;
          background: #fff;
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
          top: 30px;
          right: 0;
          & > input {
            width: 100%;
            border: none;
            padding: 16px;
            border-radius: 15px;
          }
          & > .myRelate {
            padding: 0 16px 16px 16px;
            width: 100%;
            & > .eachRelate {
              width: 100%;
              padding: 10px;
              border-radius: 10px;
              border: 1px solid #e9ebf3;
              background: #fff;
              margin: 10px 0;
              & > h6 {
                font-size: 14px;
              }
              & > p {
                font-size: 12px;
              }
            }
          }
        }
      }

      & > .filterDiv {
        display: flex;
        justify-content: space-between;
        margin-top: 2rem;
        padding: 0 10px;

        .searchDiv {
          display: flex;
          border: 1px solid #e5e5e5;
          border-radius: 10px;
          // width: 75%;
          max-width: 550px;
          position: relative;

          & > .menuDropdown {
            position: absolute;
            top: 130%;
            left: 0;
            background: white;
            border-radius: 10px;
            border: 1px solid #dddddd;
            box-shadow: 0px 4px 4px 0px #00000040;
            padding: 1rem;
            width: 600px;
            max-height: 80vh;
            overflow-y: scroll;
            display: flex;
            z-index: 1;

            &::-webkit-scrollbar {
              width: 0px !important;
              height: 0px !important;
            }

            &::-webkit-scrollbar-track {
              background: transparent;
            }

            & > .mainMenu {
              display: flex;
              flex-direction: column;
              gap: 1rem;
              width: 50%;
              padding: 1rem 0;
              padding-bottom: 3rem;

              & > .eachItem {
                display: flex;
                align-items: center;
                gap: 1rem;
                padding: 0.8rem 1rem;
                border-radius: 15px 0 0 15px;
                cursor: pointer;
                // transition: all linear 0.3s;

                & > .imageDiv {
                  border: 1px solid #dddddd;
                  padding: 5px;
                  border-radius: 50%;
                  height: 2.5rem;
                  width: 2.5rem;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background: white;

                  & > img {
                    width: 75%;
                    object-fit: contain;
                  }
                }

                & > :nth-child(2) {
                  font-size: 1rem;
                  font-weight: 450;
                  white-space: nowrap;
                }
              }

              & > .eachItemSelected {
                background: #f1f4f6bf;
                font-weight: 550;
              }
            }

            & > .subMenu {
              width: 50%;
              background: #f1f4f6bf;
              padding: 1rem;
              padding-bottom: 3rem;

              & > .subHeading {
                font-weight: 600;
                // padding-top: 0.7rem;
              }

              & > div {
                padding: 1.2rem 0;
                font-size: 1rem;
                border-bottom: 1px solid #e7e7e7;
                cursor: pointer;
                height: 4rem;
                padding-left: 10px;
                display: flex;
                align-items: center;

                &:hover {
                  background: white;
                }
              }
            }
          }

          & > .menuDropdownWellness {
            position: absolute;
            top: 110%;
            left: 0;
            background: white;
            border-radius: 10px;
            border: 1px solid #dddddd;
            box-shadow: 0px 4px 4px 0px #00000040;
            max-height: 50vh;
            overflow-y: scroll;
            display: flex;
            z-index: 1;

            &::-webkit-scrollbar {
              width: 0px !important;
              height: 0px !important;
            }

            &::-webkit-scrollbar-track {
              background: transparent;
            }

            & > .mainMenuWellness {
              display: flex;
              flex-direction: column;

              & > .eachTypewellness {
                padding: 6px 16px;
                cursor: pointer;
                &:hover {
                  background-color: #00000040;
                }
              }
            }
          }

          & > .searchDropdown {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0.5rem 1rem;
            min-width: 150px;
            background: white;
            border-radius: 10px 0 0 10px;
            border-right: 1px solid #e5e5e5;
            cursor: pointer;
          }

          & > .inputContainer {
            width: -webkit-fill-available;
            padding: 0.5rem 1rem;
            padding-right: 4rem;
            border-radius: 10px;
            outline: none;
            border: none;
          }
        }

        .locationFilter {
          display: flex;
          align-items: center;
          font-size: 0.9rem;
          gap: 5px;
          position: relative;

          & > label {
          }

          & > .city {
            font-weight: 550;
            text-decoration: underline;
            cursor: pointer;
          }

          & > svg {
            cursor: pointer;
            &:hover {
              color: #00000040;
            }
          }

          & > .myselfDropdownOpen {
            position: absolute;
            width: 374px;
            border-radius: 15px;
            border: 1px solid #e9ebf3;
            background: #fff;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            top: 30px;
            right: 0;
            & > input {
              width: 100%;
              border: none;
              padding: 16px;
            }
            & > .myRelate {
              padding: 0 16px 16px 16px;
              width: 100%;
              & > .eachRelate {
                width: 100%;
                padding: 10px;
                border-radius: 10px;
                border: 1px solid #e9ebf3;
                background: #fff;
                margin: 10px 0;
                & > h6 {
                  font-size: 14px;
                }
                & > p {
                  font-size: 12px;
                }
              }
            }
          }
        }

        .logoImage {
          height: 3rem;
          max-width: 6rem;
        }

        & > .majorSearch {
          width: 65%;
          display: flex;
          flex-direction: column;
          gap: 1.5rem;

          & > .breadCrumb {
            display: flex;
            font-size: 0.9rem;
            gap: 5px;

            & > :last-child {
              font-weight: 550;
              text-decoration: underline;
            }
          }
        }

        & > .filterCompany {
          width: 35%;
          display: flex;
          flex-direction: column;
          gap: 1.5rem;
          align-items: flex-end;
        }
      }

      & > .addressSelf {
        font-size: 1rem;
        padding: 0 10px;
        display: flex;
        width: 300px;
        margin-left: auto;
        text-align: end;
      }
    }
  }

  & > .discoverOverflow {
    height: calc(100% - 120px - 1rem);
    overflow-y: scroll;

    & > .title {
      font-size: 1rem;
      font-weight: 550;
      margin: 4rem 0 1rem;
      padding: 0 10px;
    }

    & > .allTests {
      // display: flex;
      // flex-wrap: wrap;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      justify-content: space-between;
      gap: 1.5rem;
      gap: 1rem;
      padding: 0 10px;

      & > .eachTest {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background: #e1e1e1;
        border-radius: 10px;
        aspect-ratio: 1/1;
        width: 100%;
        //   min-width: 150px;
        padding: 1.2rem;
        transition: all linear 0.3s;

        &:hover {
          background: var(--main-color);
          color: white;
        }

        & > img {
          height: 5rem;
          margin-bottom: 10px;
        }

        & > .title {
          font-size: 0.9rem;
          text-align: center;
          font-weight: 600;
          margin-top: 1rem;
        }

        & > div {
          font-size: 1rem;
          text-align: center;
          font-weight: 550;
          margin-top: 0.6rem;
        }
      }

      & > .eachCompany {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background: #e1e1e1;
        border-radius: 10px;
        aspect-ratio: 1/1;
        width: 100%;
        //   min-width: 150px;
        padding: 1.2rem;
        transition: all linear 0.3s;

        &:hover {
          background: var(--main-color);
          color: white;
        }

        & > img {
          height: 3rem;
          width: 5rem;
          margin-bottom: 10px;
        }
      }

      & > .selectedTest {
        background: var(--main-color);
        color: white;
      }
    }

    & > .buytests {
      margin-top: 3rem;

      & > .title {
        font-size: 1rem;
        font-weight: 550;
        margin: 4rem 0 1rem;
        padding: 0 10px;
      }

      & > .allTestsBuy {
        display: flex;
        gap: 1.2rem;
        overflow-x: scroll;
        padding: 10px;

        & > .eachTest {
          border: 1px solid #e1e1e1;
          border-radius: 10px;

          & > .testDesc {
            padding: 1.2rem;
            display: flex;
            gap: 10px;

            & > img {
              border-radius: 10px;
              height: 100%;
              aspect-ratio: 1/1;
            }

            & > :nth-child(2) {
              font-size: 0.9rem;
              display: flex;
              flex-direction: column;
              gap: 5px;

              & > .title {
                font-weight: 550;
              }

              & > .desc {
              }

              & > .price {
                display: flex;
                justify-content: flex-end;
                font-weight: 550;
                margin-top: auto;
              }
            }
          }

          & > .btnsContainer {
            display: flex;
            font-size: 0.9rem;
            border-top: 1px solid #e1e1e1;

            & > div {
              width: 50%;
              text-align: center;
              cursor: pointer;
              font-weight: 550;
              padding: 1rem;
            }

            & > :nth-child(1) {
              border-right: 1px solid #e1e1e1;
            }
          }
        }

        & > .eachShopByBrand {
          max-width: 321px;
          transition: box-shadow 0.3s;
          padding: 10px;
          border-radius: 10px;
          border: 1px solid #e9ebf3;

          &:hover {
            box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
          }

          & > img {
            // width: 33%;
            // max-width: 300px;
            border-radius: 10px;
          }

          & > .brandContent {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 10px 0;

            & > .companyDetails {
              display: flex;
              gap: 5px;
              align-items: center;

              & > img {
                height: 3rem;
                aspect-ratio: 1/1;
                border-radius: 10px;
              }

              & > div {
                display: flex;
                gap: 5px;
                flex-direction: column;

                & > :nth-child(1) {
                  font-size: 1rem;
                  font-weight: 600;
                }

                & > :nth-child(2) {
                  font-size: 0.8rem;
                }
              }

              & > :nth-child(1) {
                border: 1px solid #e5e5e5;
                border-radius: 10px;
                background: black;
                color: white;
                font-size: 0.8rem;
                font-weight: 550;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 3rem;
              }
            }

            // &>.buyBtn {
            //   display: flex;
            //   justify-content: center;
            //   align-items: center;
            //   cursor: pointer;
            //   border-radius: 10px;
            //   background: white;
            //   font-size: 0.8rem;
            //   font-weight: 550;
            //   padding: 5px 1rem;
            //   outline: none;
            //   border: 1px solid #e5e5e5;
            // }
          }

          & > .paraDescription {
            font-size: 12px;
            max-width: 312px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        & > .eachTestServices {
          border: 1px solid #e1e1e1;
          border-radius: 10px;
          width: 33%;

          & > .testDesc {
            padding: 1.2rem;
            display: flex;
            gap: 10px;

            & > img {
              border-radius: 10px;
              height: 100%;
              aspect-ratio: 1/1;
            }

            & > :nth-child(1) {
              font-size: 0.9rem;
              display: flex;
              flex-direction: column;
              gap: 10px;

              & > .title {
                font-weight: 550;
              }

              & > .desc {
              }

              & > .price {
                display: flex;
                justify-content: flex-end;
                font-weight: 550;
                margin-top: auto;
                background: #e5e5e580;
                padding: 0.5rem 1.5rem;
                border-radius: 35px;
                width: max-content;
              }
            }
          }

          & > .btnsContainer {
            display: flex;
            font-size: 0.9rem;
            border-top: 1px solid #e1e1e1;

            & > div {
              width: 100%;
              text-align: center;
              cursor: pointer;
              font-weight: 550;
              padding: 1rem;
            }

            // & > :nth-child(1) {
            //   border-right: 1px solid #e1e1e1;
            // }
          }
        }
      }
    }

    & > .getServices {
      // margin-top: 2rem;
      // overflow-y: scroll;

      & > .title {
        font-size: 1rem;
        font-weight: 550;
        margin: 4rem 0 1rem;
        padding: 0 10px;
      }

      & > .allServicesList {
        // display: flex;
        // gap: 1.2rem;
        // overflow-x: scroll;
        // flex-wrap: wrap;
        // width: 90vw;
        // padding: 10px;
        // height: 100vh;

        & > div > div {
          // display: flex;
          gap: 1rem;
          overflow-x: scroll;
          flex-wrap: wrap;
          width: 100%;
          padding: 10px;
          display: grid;
          grid-template-columns: repeat(2, 1fr);

          & > .eachService {
            border: 1px solid #e9ebf3;
            display: flex;
            border-radius: 12px;
            transition: box-shadow 0.3s;

            &:hover {
              box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
            }

            & > .serviceIMG {
              width: 180px;
              height: 175px;

              & > img {
                width: 139px;
                height: 174px;
                border-radius: 12px;
              }
            }

            & > .nameDescBtn {
              width: 430px;
              padding: 20px;
              & > .nameDB {
                color: #5f6163;
                font-size: 16px;
                font-weight: 700;
                line-height: 24px;
              }
              & > .NdescriptionB {
                font-size: 12px;
                max-width: 312px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                margin: 1em 0;
              }
              & > .providedBy {
                color: #5f6163;
                font-size: 12px;
                font-weight: 700;
                line-height: 12px;
                margin-bottom: 1em;
              }
              & > .btnGroup {
                margin-top: 10px;
                display: flex;
                justify-content: space-between;
                & > .priceTag {
                  border-radius: 20px;
                  background: rgba(229, 229, 229, 0.5);
                  padding: 4px 26px;
                  font-weight: 700;
                }
                & > .lrTotalItem {
                  display: flex;
                  & > .leftTotalItem {
                    border-top-left-radius: 20px;
                    border-bottom-left-radius: 20px;
                    background: rgba(229, 229, 229, 0.5);
                    padding: 4px 26px;
                    border: none;

                    &:hover {
                      background: var(--main-color);
                      color: white;
                    }
                  }
                  & > .itemCount {
                    background: rgba(229, 229, 229, 0.5);
                    padding: 4px 26px;
                    font-weight: 700;
                    min-width: 74px;
                    text-align: center;
                  }
                  & > .rightTotalItem {
                    border-top-right-radius: 20px;
                    border-bottom-right-radius: 20px;
                    background: rgba(229, 229, 229, 0.5);
                    padding: 4px 26px;
                    border: none;

                    &:hover {
                      background: var(--main-color);
                      color: white;
                    }
                  }
                }
              }
            }
          }

          & > .eachOffering {
            margin-bottom: 1rem;
            border: 1px solid #e5e5e5;
            border-radius: 20px;
            // padding: 1rem;
            display: flex;
            background: white;
            position: relative;
            transition: all linear 0.3s;

            &:hover {
              box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
            }

            & > :nth-child(1) {
              width: 160px;
              // height: 100px;
              padding: 1rem;
              display: flex;
              justify-content: center;
              align-items: center;
              // border-radius: 10px;
              border-right: 1px solid #e5e5e5;

              & > svg {
                height: 80% !important;
                aspect-ratio: 1.5/1 !important;
              }

              & > img {
                width: 80% !important;
                height: 80% !important;
                object-fit: cover;
                border-radius: 10px;
                aspect-ratio: 1.5/1 !important;
              }
            }

            & > :nth-child(2) {
              display: flex;
              flex-direction: column;
              justify-content: space-evenly;
              padding: 1rem;
              width: calc(100% - 160px);

              & > :nth-child(1) {
                display: flex;
                flex-direction: column;
                gap: 10px;

                & > .title {
                  font-size: 0.9rem;
                  font-weight: 550;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;

                  & > span {
                    & > svg {
                      cursor: pointer;
                    }
                  }
                }

                & > .learnBtn {
                  font-size: 0.7rem;
                  font-weight: 500;
                  text-decoration: underline;
                  margin-top: 10px;
                  cursor: pointer;
                  transition: all linear 0.3s;

                  &:hover {
                    color: #04bfda;
                  }
                }

                & > .desc {
                  font-size: 0.8rem;
                  font-weight: 500;
                  // margin-top: 10px;
                  cursor: pointer;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  /* Limit to three lines */
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }

              & > .priceDiv {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 10px;
                gap: 1rem;

                & > :nth-child(1) {
                  font-size: 0.95rem;
                  font-weight: 550;
                  padding: 9px 10px;
                  white-space: nowrap;
                  color: var(--main-color);

                  & > span {
                    text-decoration: line-through;
                    opacity: 0.5;
                    margin-left: 5px;
                    font-size: 0.8rem;
                    font-weight: 500;
                    color: var(--font-color);
                  }
                }

                & > :nth-child(2) {
                  width: -webkit-fill-available;
                  padding: 7px 10px;
                }

                & > .btn {
                  font-size: 0.8rem;
                  padding: 7px 1.5rem;
                  border-radius: 35px;
                  cursor: pointer;
                  border: 1px solid #e5e5e5;
                  transition: all linear 0.3s;
                  background: #e5e5e5;

                  &:hover {
                    background: var(--main-color);
                    color: white;
                    border: 1px solid var(--main-color);
                  }
                }

                & > .addButton {
                  display: flex;
                  justify-content: space-between;
                  gap: 10px;

                  & > .btns {
                    font-size: 1rem;
                    // background: #e5e5e5;
                    width: 1.5rem;
                    height: 1.5rem;
                    text-align: center;
                    border-radius: 5px;
                    cursor: pointer;
                  }

                  & > :nth-child(2) {
                    width: 1rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 0.7rem;
                  }
                }
              }

              & > .addButton {
                display: flex;
                justify-content: space-between;
                gap: 10px;
                border: 1px solid #e9ebf3;
                width: 50%;
                max-width: 150px;

                & > .btns {
                  font-size: 1.1rem;
                  // background: #e5e5e5;
                  // width: 1.5rem;
                  height: 100%;
                  text-align: center;
                  border-radius: 5px;
                  cursor: pointer;
                  padding: 0.25rem;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  aspect-ratio: 1/1;
                  transition: all linear 0.3s;

                  &:hover {
                    background: var(--main-color);
                    color: white;
                  }
                }

                & > :nth-child(2) {
                  width: 1rem;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-size: 0.85rem;
                  padding: 0.25rem;
                  font-weight: 500;
                }
              }
            }

            & > img {
              position: absolute;
              top: -0.5rem;
              right: -0.5rem;
              width: 1.5rem;
              height: 1.5rem;
              cursor: pointer;
            }
          }
        }
      }
    }

    & > .ordersContainer {
      display: flex;
      gap: 1rem;

      & > .orderDetails {
        width: calc(100% - 400px);
        // border: 1px solid #E9EBF3
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        & > .eachOrder {
          border-radius: 10px;
          border: 1px solid #e1e1e1;

          .header {
            padding: 1.2rem;
            display: flex;
            align-items: center;
            justify-content: space-between;

            & > :nth-child(1) {
              display: grid;
              grid-template-columns: repeat(3, 1fr);
              gap: 1.5rem;

              & > div {
                display: flex;
                flex-direction: column;
                gap: 2px;

                & > .title {
                  font-size: 0.8rem;
                }

                & > .value {
                  font-size: 0.9rem;
                  font-weight: 500;
                }
              }
            }

            & > .detailsBtn {
              display: flex;
              flex-direction: column;
              gap: 2px;
              align-items: flex-end;

              & > .title {
                font-size: 0.8rem;
              }

              & > .btnsContainer {
                display: flex;
                color: #5092ff;
                font-weight: 500;
                font-size: 0.9rem;
                display: flex;

                & > :nth-child(1) {
                  border-right: 1px solid var(--font-color);
                }

                & > div {
                  padding: 0 0.5rem;
                  cursor: pointer;
                  transition: all linear 0.3s;

                  &:hover {
                    color: var(--main-color);
                  }
                }
              }
            }
          }

          & > .allProducts {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            padding: 0 1.2rem;
            background: white;
            border-radius: 10px;
            padding-bottom: 2rem;

            & > .eachProduct {
              padding: 1.2rem 0;

              & > .productDetails {
                padding: 1.2rem;
                display: flex;
                gap: 1.2rem;

                & > .details {
                  display: flex;
                  flex-direction: column;
                  width: calc(100% - 250px);

                  & > div {
                    & > .title {
                      font-size: 1rem;
                      font-weight: 520;
                    }

                    & > .checkoutType {
                      font-size: 0.8rem;
                    }
                  }

                  & > .product {
                    display: flex;
                    gap: 1rem;

                    & > img {
                      border-radius: 15px;
                      height: 6rem;
                    }

                    & > .details {
                      display: flex;
                      flex-direction: column;
                      gap: 8px;

                      & > .title {
                        font-size: 1rem;
                        font-weight: 500;
                      }

                      & > .desc {
                        font-size: 0.8rem;

                        & > span {
                          font-weight: 520;
                        }
                      }

                      & > .price {
                        & > :nth-child(1) {
                          color: var(--main-color);
                          font-weight: 520;
                        }

                        & > :nth-child(2) {
                          font-weight: 350;
                        }
                      }
                    }
                  }
                }

                & > .productBtns {
                  display: flex;
                  flex-direction: column;
                  gap: 1rem;
                  width: 250px;
                  justify-content: center;
                  align-items: center;

                  & > div {
                    text-align: center;
                    padding: 0.8rem 1rem;
                    border-radius: 10px;
                    border: 0.5px solid #e9ebf3;
                    box-shadow: 0px 2px 2px 0px #00000026;
                    cursor: pointer;
                    white-space: nowrap;
                    font-size: 0.8rem;
                    font-weight: 500;
                    width: 100%;
                    transition: all linear 0.3s;

                    &:hover {
                      background: var(--main-color);
                      color: white;
                    }
                  }
                }
              }

              & > .footer {
                background: white;
                box-shadow: 0px 2px 2px 0px #00000040;
                border: 1px solid #e9ebf3;

                & > div {
                  .buyBtn {
                    padding: 0.7rem 1rem;
                    background: var(--main-color);
                    border-radius: 10px;
                    color: white;
                    cursor: pointer;
                    font-size: 0.85rem;
                  }
                }
              }
            }
          }
        }
      }

      & > .yourDetails {
        width: 400px;
        border-radius: 15px;
        border: 1px solid #e9ebf3;
        margin-top: 2rem;

        & > .header {
          font-size: 1rem;
          font-weight: 500;
          padding: 1rem;
        }

        & > .favContainer {
          display: flex;
          flex-direction: column;
          padding: 1.2rem 0.8rem;
          gap: 1rem;
          background: white;

          & > .eachFav {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;

            & > .imageContainer {
              border: 1px solid #e9ebf3;
              border-radius: 10px;
              padding: 3rem 2rem;
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;

              & > img {
                width: 100%;
                max-width: 150px;
                object-fit: contain;
              }
            }

            & > .detailsContainer {
              display: flex;
              flex-direction: column;
              gap: 0.75rem;
              margin-top: 1rem;

              & > .title {
                font-size: 1.1rem;
                font-weight: 550;
              }

              & > .provider {
                display: flex;
                gap: 2px;
                font-size: 0.85rem;

                & > span {
                  font-weight: 520;
                }
              }

              & > .priceDiv {
                font-size: 1rem;
                font-weight: 520;
                display: flex;
                align-items: center;
                gap: 10px;

                & > .currentText {
                  color: var(--main-color);
                }

                & > .slashedPrice {
                  text-decoration: line-through;
                  font-size: 0.95rem;
                }
              }

              & > .addButton {
                display: flex;
                justify-content: space-between;
                gap: 10px;
                border: 1px solid #e9ebf3;
                width: 50%;
                max-width: 150px;

                & > .btns {
                  font-size: 1.1rem;
                  // background: #e5e5e5;
                  // width: 1.5rem;
                  height: 100%;
                  text-align: center;
                  border-radius: 5px;
                  cursor: pointer;
                  padding: 0.25rem;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  aspect-ratio: 1/1;
                  transition: all linear 0.3s;

                  &:hover {
                    background: var(--main-color);
                    color: white;
                  }
                }

                & > :nth-child(2) {
                  width: 1rem;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-size: 0.85rem;
                  padding: 0.25rem;
                  font-weight: 500;
                }
              }
            }
          }
        }
      }
    }
  }

  .subTabs {
    display: flex;
    gap: 1rem;
    padding: 0 10px;

    & > div {
      font-size: 1rem;
      cursor: pointer;
      padding-bottom: 5px;
      transition: all linear 0.3s;
    }

    & > .selectedTab {
      border-bottom: 1px solid var(--font-color);
      padding: 4px;
      &:hover {
        background-color: #00000040;
      }
    }
    & > .nonSelectedTab {
      padding: 4px;
      &:hover {
        background-color: #00000040;
      }
    }
  }

  .locationFilter {
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    gap: 5px;
    position: relative;

    & > label {
    }

    & > .city {
      font-weight: 550;
      text-decoration: underline;
      cursor: pointer;
      position: relative;

      &:hover {
        & > .addressPopover {
          display: flex;
        }
      }

      & > .addressPopover {
        display: none;
        position: absolute;
        width: 374px;
        border-radius: 15px;
        border: 1px solid #e9ebf3;
        background: #fff;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        top: 20px;
        right: 0;
        padding: 1.2rem 1rem;
        flex-direction: column;
        gap: 1.5rem;

        & > .location {
          display: flex;
          gap: 0.5rem;
          align-items: center;
          font-size: 1rem;

          & > span {
            font-weight: 520;
          }
        }

        & > .eachAddressField {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          font-size: 0.9rem;

          & > :nth-child(1) {
            font-weight: 520;
          }

          & > :nth-child(2) {
            font-weight: 400;
          }
        }

        & > .greenBtn {
          width: 100%;
        }
      }
    }

    & > svg {
      cursor: pointer;
      &:hover {
        color: #00000040;
      }
    }

    & > .myselfDropdownOpen {
      position: absolute;
      width: 374px;
      border-radius: 15px;
      border: 1px solid #e9ebf3;
      background: #fff;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      top: 30px;
      right: 0;
      & > input {
        width: 100%;
        border: none;
        padding: 16px;
        border-radius: 15px;
      }
      & > .myRelate {
        padding: 0 16px 16px 16px;
        width: 100%;
        & > .eachRelate {
          width: 100%;
          padding: 10px;
          border-radius: 10px;
          border: 1px solid #e9ebf3;
          background: #fff;
          margin: 10px 0;
          & > h6 {
            font-size: 14px;
          }
          & > p {
            font-size: 12px;
          }
        }
      }
    }
  }

  .filterDiv {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    padding: 0 10px;

    .searchDiv {
      display: flex;
      border: 1px solid #e5e5e5;
      border-radius: 10px;
      // width: 75%;
      max-width: 550px;
      position: relative;

      & > .menuDropdown {
        position: absolute;
        top: 130%;
        left: 0;
        background: white;
        border-radius: 10px;
        border: 1px solid #dddddd;
        box-shadow: 0px 4px 4px 0px #00000040;
        padding: 1rem;
        width: 600px;
        max-height: 80vh;
        overflow-y: scroll;
        display: flex;
        z-index: 1;

        &::-webkit-scrollbar {
          width: 0px !important;
          height: 0px !important;
        }

        &::-webkit-scrollbar-track {
          background: transparent;
        }

        & > .mainMenu {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          width: 50%;
          padding: 1rem 0;
          padding-bottom: 3rem;

          & > .eachItem {
            display: flex;
            align-items: center;
            gap: 1rem;
            padding: 0.8rem 1rem;
            border-radius: 15px 0 0 15px;
            cursor: pointer;
            // transition: all linear 0.3s;

            & > .imageDiv {
              border: 1px solid #dddddd;
              padding: 5px;
              border-radius: 50%;
              height: 2.5rem;
              width: 2.5rem;
              display: flex;
              justify-content: center;
              align-items: center;
              background: white;

              & > img {
                width: 75%;
                object-fit: contain;
              }
            }

            & > :nth-child(2) {
              font-size: 1rem;
              font-weight: 450;
              white-space: nowrap;
            }
          }

          & > .eachItemSelected {
            background: #f1f4f6bf;
            font-weight: 550;
          }
        }

        & > .subMenu {
          width: 50%;
          background: #f1f4f6bf;
          padding: 1rem;
          padding-bottom: 3rem;

          & > .subHeading {
            font-weight: 600;
            // padding-top: 0.7rem;
          }

          & > div {
            padding: 1.2rem 0;
            font-size: 1rem;
            border-bottom: 1px solid #e7e7e7;
            cursor: pointer;
            height: 4rem;
            padding-left: 10px;
            display: flex;
            align-items: center;

            &:hover {
              background: white;
            }
          }
        }
      }

      & > .menuDropdownWellness {
        position: absolute;
        top: 110%;
        left: 0;
        background: white;
        border-radius: 10px;
        border: 1px solid #dddddd;
        box-shadow: 0px 4px 4px 0px #00000040;
        max-height: 50vh;
        overflow-y: scroll;
        display: flex;
        z-index: 1;

        &::-webkit-scrollbar {
          width: 0px !important;
          height: 0px !important;
        }

        &::-webkit-scrollbar-track {
          background: transparent;
        }

        & > .mainMenuWellness {
          display: flex;
          flex-direction: column;

          & > .eachTypewellness {
            padding: 6px 16px;
            cursor: pointer;
            &:hover {
              background-color: #00000040;
            }
          }
        }
      }

      & > .searchDropdown {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem 1rem;
        min-width: 150px;
        background: white;
        border-radius: 10px 0 0 10px;
        border-right: 1px solid #e5e5e5;
        cursor: pointer;
      }

      & > .inputContainer {
        width: -webkit-fill-available;
        padding: 0.5rem 1rem;
        padding-right: 4rem;
        border-radius: 10px;
        outline: none;
        border: none;
      }
    }

    .locationFilter {
      display: flex;
      align-items: center;
      font-size: 0.9rem;
      gap: 5px;
      position: relative;

      & > label {
      }

      & > .city {
        font-weight: 550;
        text-decoration: underline;
        cursor: pointer;
      }

      & > svg {
        cursor: pointer;
        &:hover {
          color: #00000040;
        }
      }

      & > .myselfDropdownOpen {
        position: absolute;
        width: 374px;
        border-radius: 15px;
        border: 1px solid #e9ebf3;
        background: #fff;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        top: 30px;
        right: 0;
        & > input {
          width: 100%;
          border: none;
          padding: 16px;
        }
        & > .myRelate {
          padding: 0 16px 16px 16px;
          width: 100%;
          & > .eachRelate {
            width: 100%;
            padding: 10px;
            border-radius: 10px;
            border: 1px solid #e9ebf3;
            background: #fff;
            margin: 10px 0;
            & > h6 {
              font-size: 14px;
            }
            & > p {
              font-size: 12px;
            }
          }
        }
      }
    }

    .logoImage {
      height: 3rem;
      max-width: 6rem;
    }

    & > .majorSearch {
      width: 65%;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      & > .breadCrumb {
        display: flex;
        font-size: 0.9rem;
        gap: 5px;

        & > :last-child {
          font-weight: 550;
          text-decoration: underline;
        }
      }
    }

    & > .filterCompany {
      width: 35%;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      align-items: flex-end;
    }
  }

  .addressSelf {
    font-size: 1rem;
    padding: 0 10px;
    display: flex;
    width: 300px;
    margin-left: auto;
    text-align: end;
  }

  & > .allTests {
    // display: flex;
    // flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    justify-content: space-between;
    gap: 1.5rem;
    gap: 1rem;
    padding: 0 10px;

    & > .eachTest {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      background: #e1e1e1;
      border-radius: 10px;
      aspect-ratio: 1/1;
      width: 100%;
      //   min-width: 150px;
      padding: 1.2rem;
      transition: all linear 0.3s;

      &:hover {
        background: var(--main-color);
        color: white;
      }

      & > img {
        height: 5rem;
        margin-bottom: 10px;
      }

      & > .title {
        font-size: 0.9rem;
        text-align: center;
        font-weight: 600;
        margin-top: 1rem;
      }

      & > div {
        font-size: 1rem;
        text-align: center;
        font-weight: 550;
        margin-top: 0.6rem;
      }
    }

    & > .eachCompany {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      background: #e1e1e1;
      border-radius: 10px;
      aspect-ratio: 1/1;
      width: 100%;
      //   min-width: 150px;
      padding: 1.2rem;
      transition: all linear 0.3s;

      &:hover {
        background: var(--main-color);
        color: white;
      }

      & > img {
        height: 3rem;
        width: 5rem;
        margin-bottom: 10px;
      }
    }

    & > .selectedTest {
      background: var(--main-color);
      color: white;
    }
  }

  & > .buytests {
    margin-top: 3rem;

    & > .title {
      font-size: 1rem;
      font-weight: 550;
      margin: 4rem 0 1rem;
      padding: 0 10px;
    }

    & > .allTestsBuy {
      display: flex;
      gap: 1.2rem;
      overflow-x: scroll;
      padding: 10px;

      & > .eachTest {
        border: 1px solid #e1e1e1;
        border-radius: 10px;

        & > .testDesc {
          padding: 1.2rem;
          display: flex;
          gap: 10px;

          & > img {
            border-radius: 10px;
            height: 100%;
            aspect-ratio: 1/1;
          }

          & > :nth-child(2) {
            font-size: 0.9rem;
            display: flex;
            flex-direction: column;
            gap: 5px;

            & > .title {
              font-weight: 550;
            }

            & > .desc {
            }

            & > .price {
              display: flex;
              justify-content: flex-end;
              font-weight: 550;
              margin-top: auto;
            }
          }
        }

        & > .btnsContainer {
          display: flex;
          font-size: 0.9rem;
          border-top: 1px solid #e1e1e1;

          & > div {
            width: 50%;
            text-align: center;
            cursor: pointer;
            font-weight: 550;
            padding: 1rem;
          }

          & > :nth-child(1) {
            border-right: 1px solid #e1e1e1;
          }
        }
      }

      & > .eachShopByBrand {
        max-width: 321px;
        transition: box-shadow 0.3s;
        padding: 10px;
        border-radius: 10px;
        border: 1px solid #e9ebf3;

        &:hover {
          box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
        }

        & > img {
          // width: 33%;
          // max-width: 300px;
          border-radius: 10px;
        }

        & > .brandContent {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 10px 0;

          & > .companyDetails {
            display: flex;
            gap: 5px;
            align-items: center;

            & > img {
              height: 3rem;
              aspect-ratio: 1/1;
              border-radius: 10px;
            }

            & > div {
              display: flex;
              gap: 5px;
              flex-direction: column;

              & > :nth-child(1) {
                font-size: 1rem;
                font-weight: 600;
              }

              & > :nth-child(2) {
                font-size: 0.8rem;
              }
            }

            & > :nth-child(1) {
              border: 1px solid #e5e5e5;
              border-radius: 10px;
              background: black;
              color: white;
              font-size: 0.8rem;
              font-weight: 550;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 3rem;
            }
          }

          // &>.buyBtn {
          //   display: flex;
          //   justify-content: center;
          //   align-items: center;
          //   cursor: pointer;
          //   border-radius: 10px;
          //   background: white;
          //   font-size: 0.8rem;
          //   font-weight: 550;
          //   padding: 5px 1rem;
          //   outline: none;
          //   border: 1px solid #e5e5e5;
          // }
        }

        & > .paraDescription {
          font-size: 12px;
          max-width: 312px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      & > .eachTestServices {
        border: 1px solid #e1e1e1;
        border-radius: 10px;
        width: 33%;

        & > .testDesc {
          padding: 1.2rem;
          display: flex;
          gap: 10px;

          & > img {
            border-radius: 10px;
            height: 100%;
            aspect-ratio: 1/1;
          }

          & > :nth-child(1) {
            font-size: 0.9rem;
            display: flex;
            flex-direction: column;
            gap: 10px;

            & > .title {
              font-weight: 550;
            }

            & > .desc {
            }

            & > .price {
              display: flex;
              justify-content: flex-end;
              font-weight: 550;
              margin-top: auto;
              background: #e5e5e580;
              padding: 0.5rem 1.5rem;
              border-radius: 35px;
              width: max-content;
            }
          }
        }

        & > .btnsContainer {
          display: flex;
          font-size: 0.9rem;
          border-top: 1px solid #e1e1e1;

          & > div {
            width: 100%;
            text-align: center;
            cursor: pointer;
            font-weight: 550;
            padding: 1rem;
          }

          // & > :nth-child(1) {
          //   border-right: 1px solid #e1e1e1;
          // }
        }
      }
    }
  }

  & > .getServices {
    // margin-top: 2rem;
    // overflow-y: scroll;

    & > .title {
      font-size: 1rem;
      font-weight: 550;
      margin: 4rem 0 1rem;
      padding: 0 10px;
    }

    & > .allServicesList {
      // display: flex;
      // gap: 1.2rem;
      // overflow-x: scroll;
      // flex-wrap: wrap;
      // width: 90vw;
      // padding: 10px;
      // height: 100vh;

      & > div > div {
        // display: flex;
        gap: 1rem;
        overflow-x: scroll;
        flex-wrap: wrap;
        width: 100%;
        padding: 10px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        & > .eachService {
          border: 1px solid #e9ebf3;
          display: flex;
          border-radius: 12px;
          transition: box-shadow 0.3s;

          &:hover {
            box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
          }

          & > .serviceIMG {
            width: 180px;
            height: 175px;

            & > img {
              width: 139px;
              height: 174px;
              border-radius: 12px;
            }
          }

          & > .nameDescBtn {
            width: 430px;
            padding: 20px;
            & > .nameDB {
              color: #5f6163;
              font-size: 16px;
              font-weight: 700;
              line-height: 24px;
            }
            & > .NdescriptionB {
              font-size: 12px;
              max-width: 312px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
              margin: 1em 0;
            }
            & > .providedBy {
              color: #5f6163;
              font-size: 12px;
              font-weight: 700;
              line-height: 12px;
              margin-bottom: 1em;
            }
            & > .btnGroup {
              margin-top: 10px;
              display: flex;
              justify-content: space-between;
              & > .priceTag {
                border-radius: 20px;
                background: rgba(229, 229, 229, 0.5);
                padding: 4px 26px;
                font-weight: 700;
              }
              & > .lrTotalItem {
                display: flex;
                & > .leftTotalItem {
                  border-top-left-radius: 20px;
                  border-bottom-left-radius: 20px;
                  background: rgba(229, 229, 229, 0.5);
                  padding: 4px 26px;
                  border: none;

                  &:hover {
                    background: var(--main-color);
                    color: white;
                  }
                }
                & > .itemCount {
                  background: rgba(229, 229, 229, 0.5);
                  padding: 4px 26px;
                  font-weight: 700;
                  min-width: 74px;
                  text-align: center;
                }
                & > .rightTotalItem {
                  border-top-right-radius: 20px;
                  border-bottom-right-radius: 20px;
                  background: rgba(229, 229, 229, 0.5);
                  padding: 4px 26px;
                  border: none;

                  &:hover {
                    background: var(--main-color);
                    color: white;
                  }
                }
              }
            }
          }
        }

        & > .eachOffering {
          margin-bottom: 1rem;
          border: 1px solid #e5e5e5;
          border-radius: 20px;
          // padding: 1rem;
          display: flex;
          background: white;
          position: relative;

          & > :nth-child(1) {
            width: 160px;
            // height: 100px;
            padding: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            // border-radius: 10px;
            border-right: 1px solid #e5e5e5;

            & > svg {
              height: 80% !important;
              aspect-ratio: 1.5/1 !important;
            }

            & > img {
              width: 80% !important;
              height: 80% !important;
              object-fit: cover;
              border-radius: 10px;
              aspect-ratio: 1.5/1 !important;
            }
          }

          & > :nth-child(2) {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            padding: 1rem;
            width: calc(100% - 160px);

            & > :nth-child(1) {
              display: flex;
              flex-direction: column;
              gap: 10px;

              & > .title {
                font-size: 0.9rem;
                font-weight: 550;
                display: flex;
                align-items: center;
                justify-content: space-between;

                & > span {
                  & > svg {
                    cursor: pointer;
                  }
                }
              }

              & > .learnBtn {
                font-size: 0.7rem;
                font-weight: 500;
                text-decoration: underline;
                margin-top: 10px;
                cursor: pointer;
                transition: all linear 0.3s;

                &:hover {
                  color: #04bfda;
                }
              }

              & > .desc {
                font-size: 0.8rem;
                font-weight: 500;
                // margin-top: 10px;
                cursor: pointer;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                /* Limit to three lines */
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }

            & > .priceDiv {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: 10px;
              gap: 1rem;

              & > :nth-child(1) {
                font-size: 0.95rem;
                font-weight: 550;
                padding: 9px 10px;
                white-space: nowrap;
                color: var(--main-color);

                & > span {
                  text-decoration: line-through;
                  opacity: 0.5;
                  margin-left: 5px;
                  font-size: 0.8rem;
                  font-weight: 500;
                  color: var(--font-color);
                }
              }

              & > :nth-child(2) {
                width: -webkit-fill-available;
                padding: 7px 10px;
              }

              & > .btn {
                font-size: 0.8rem;
                padding: 7px 1.5rem;
                border-radius: 35px;
                cursor: pointer;
                border: 1px solid #e5e5e5;
                transition: all linear 0.3s;
                background: #e5e5e5;

                &:hover {
                  background: var(--main-color);
                  color: white;
                  border: 1px solid var(--main-color);
                }
              }

              & > .addButton {
                display: flex;
                justify-content: space-between;
                gap: 10px;

                & > .btns {
                  font-size: 1rem;
                  // background: #e5e5e5;
                  width: 1.5rem;
                  height: 1.5rem;
                  text-align: center;
                  border-radius: 5px;
                  cursor: pointer;
                }

                & > :nth-child(2) {
                  width: 1rem;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-size: 0.7rem;
                }
              }
            }

            & > .addButton {
              display: flex;
              justify-content: space-between;
              gap: 10px;
              border: 1px solid #e9ebf3;
              width: 50%;
              max-width: 150px;

              & > .btns {
                font-size: 1.1rem;
                // background: #e5e5e5;
                // width: 1.5rem;
                height: 100%;
                text-align: center;
                border-radius: 5px;
                cursor: pointer;
                padding: 0.25rem;
                display: flex;
                justify-content: center;
                align-items: center;
                aspect-ratio: 1/1;
                transition: all linear 0.3s;

                &:hover {
                  background: var(--main-color);
                  color: white;
                }
              }

              & > :nth-child(2) {
                width: 1rem;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 0.85rem;
                padding: 0.25rem;
                font-weight: 500;
              }
            }
          }

          & > img {
            position: absolute;
            top: -0.5rem;
            right: -0.5rem;
            width: 1.5rem;
            height: 1.5rem;
            cursor: pointer;
          }
        }
      }
    }
  }

  & > .companyDesc {
    margin-bottom: 2rem;

    & > .title {
      font-size: 1rem;
      font-weight: 550;
      margin-bottom: 0.5rem;
    }

    & > .desc {
      font-size: 1rem;
      margin-top: 0.5rem;
    }
  }

  & > .subMenuSelection {
    margin-top: 1rem;
    margin-bottom: 2rem;

    & > .title {
      font-size: 1rem;
      font-weight: 550;
      margin-bottom: 0.5rem;
    }

    & > .allSubItems {
      display: flex;
      gap: 1rem;
      flex-wrap: wrap;
      margin-top: 1rem;

      & > div {
        padding: 0.5rem 1.5rem;
        border-radius: 35px;
        cursor: pointer;
        background: #e1e1e1;
        font-weight: 520;
        font-size: 0.9rem;
      }

      & > .selectedSubItems {
        background: var(--main-color);
        color: white;
      }
    }
  }

  & > .offerings {
    // margin-top: 2.5rem;
    height: 100%;

    & > .paymentBreadcrumb {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 4rem;
      font-weight: 500;
      max-width: 500px;
      font-size: 0.8rem;
      padding: 0 2rem;
      margin-bottom: 2rem;
      position: relative;

      &::before {
        position: absolute;
        content: "";
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 2px;
        background: #e1e1e1;
        width: calc(100% - 4rem);
      }

      & > div {
        background: #e1e1e1;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
      }

      & > .currentBreadcrumb {
        background: var(--main-color);
        color: white;
      }

      & > :nth-child(1) {
        &::before {
          position: absolute;
          content: "Confirm Units";
          top: 140%;
          left: 50%;
          transform: translate(-50%, -50%);
          white-space: nowrap;
          color: var(--font-color);
        }
      }

      & > :nth-child(2) {
        &::before {
          position: absolute;
          content: "Member(s)";
          top: 140%;
          left: 50%;
          transform: translate(-50%, -50%);
          white-space: nowrap;
          color: var(--font-color);
        }
      }

      & > :nth-child(3) {
        &::before {
          position: absolute;
          content: "Review";
          top: 140%;
          left: 50%;
          transform: translate(-50%, -50%);
          white-space: nowrap;
          color: var(--font-color);
        }
      }

      & > :nth-child(4) {
        &::before {
          position: absolute;
          content: "Payment";
          top: 140%;
          left: 50%;
          transform: translate(-50%, -50%);
          white-space: nowrap;
          color: var(--font-color);
        }
      }
    }

    & > .buttonsContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 2rem;
      width: 100%;
      padding: 1rem 0;

      & > button {
        all: unset;
        padding: 0.6rem 1.5rem;
        border-radius: 35px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
        font-weight: 520;
        width: 40%;
        max-width: 300px;
        // transition: all linear 0.3s;

        // &:hover {
        //   scale: 1.05;
        // }
      }

      & > .successBtn {
        background: var(--main-color);
        color: white;
      }

      & > .greyBtn {
        background: white;
        border: 1px solid #e5e5e5;
      }
    }

    & > .paymentContainer {
      height: calc(100% - 14rem);
      background: white;
      border-radius: 10px;
    }

    & > .title {
      font-size: 0.9rem;
      font-weight: 500;
    }

    & > .titleBig {
      font-size: 1.4rem;
      font-weight: 550;
      margin: 0.5rem 0;
    }

    & > .flexContainer {
      display: flex;
      gap: 1rem;
      height: calc(100% - 9rem);
      width: 100%;

      & > div {
        // width: 50%;
        height: calc(100% - 1.2rem);
      }

      & > .allOfferings {
        overflow-y: scroll;
        padding-right: 1.15rem;
        padding-top: 1rem;
        width: calc(100% - 460px);

        & > .eachOffering {
          margin-bottom: 1rem;
          border: 1px solid #e5e5e5;
          border-radius: 20px;
          // padding: 1rem;
          display: flex;
          background: white;

          position: relative;

          & > :nth-child(1) {
            width: 160px;
            // height: 100px;
            padding: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            // border-radius: 10px;
            border-right: 1px solid #e5e5e5;

            & > svg {
              height: 80% !important;
              aspect-ratio: 1.5/1 !important;
            }

            & > img {
              width: 80% !important;
              height: 80% !important;
              object-fit: cover;
              border-radius: 10px;
              aspect-ratio: 1.5/1 !important;
            }
          }

          & > :nth-child(2) {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            padding: 1rem;
            width: calc(100% - 160px);

            & > :nth-child(1) {
              display: flex;
              flex-direction: column;
              gap: 10px;

              & > .title {
                font-size: 0.9rem;
                font-weight: 550;
                display: flex;
                align-items: center;
                justify-content: space-between;

                & > span {
                  & > svg {
                    cursor: pointer;
                  }
                }
              }

              & > .learnBtn {
                font-size: 0.7rem;
                font-weight: 500;
                text-decoration: underline;
                margin-top: 10px;
                cursor: pointer;
                transition: all linear 0.3s;

                &:hover {
                  color: #04bfda;
                }
              }

              & > .desc {
                font-size: 0.8rem;
                font-weight: 500;
                // margin-top: 10px;
                cursor: pointer;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                /* Limit to three lines */
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }

            & > .priceDiv {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: 10px;
              gap: 1rem;

              & > :nth-child(1) {
                font-size: 0.95rem;
                font-weight: 550;
                padding: 9px 10px;
                white-space: nowrap;
                color: var(--main-color);

                & > span {
                  text-decoration: line-through;
                  opacity: 0.5;
                  margin-left: 5px;
                  font-size: 0.8rem;
                  font-weight: 500;
                  color: var(--font-color);
                }
              }

              & > :nth-child(2) {
                width: -webkit-fill-available;
                padding: 7px 10px;
              }

              & > .btn {
                font-size: 0.8rem;
                padding: 7px 1.5rem;
                border-radius: 35px;
                cursor: pointer;
                border: 1px solid #e5e5e5;
                transition: all linear 0.3s;
                background: #e5e5e5;

                &:hover {
                  background: var(--main-color);
                  color: white;
                  border: 1px solid var(--main-color);
                }
              }

              & > .addButton {
                display: flex;
                justify-content: space-between;
                gap: 10px;

                & > .btns {
                  font-size: 1rem;
                  // background: #e5e5e5;
                  width: 1.5rem;
                  height: 1.5rem;
                  text-align: center;
                  border-radius: 5px;
                  cursor: pointer;
                }

                & > :nth-child(2) {
                  width: 1rem;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-size: 0.7rem;
                }
              }
            }

            & > .addButton {
              display: flex;
              justify-content: space-between;
              gap: 10px;
              border: 1px solid #e9ebf3;
              width: 50%;
              max-width: 150px;

              & > .btns {
                font-size: 1.1rem;
                // background: #e5e5e5;
                // width: 1.5rem;
                height: 100%;
                text-align: center;
                border-radius: 5px;
                cursor: pointer;
                padding: 0.25rem;
                display: flex;
                justify-content: center;
                align-items: center;
                aspect-ratio: 1/1;
                transition: all linear 0.3s;

                &:hover {
                  background: var(--main-color);
                  color: white;
                }
              }

              & > :nth-child(2) {
                width: 1rem;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 0.85rem;
                padding: 0.25rem;
                font-weight: 500;
              }
            }
          }

          & > img {
            position: absolute;
            top: -0.5rem;
            right: -0.5rem;
            width: 1.5rem;
            height: 1.5rem;
            cursor: pointer;
          }
        }

        & > .noCartItems {
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 450;
          flex-direction: column;
          gap: 1rem;
          height: 100%;

          & > .message {
            font-size: 1rem;
          }
        }
      }

      & > .noCartItems {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        flex-direction: column;
        gap: 1rem;
        width: calc(100% - 460px);

        & > .message {
          font-size: 1.2rem;
        }

        & > .submitBtn {
          border-radius: 35px;
          padding: 0.7rem 3rem;
        }
      }

      & > .detailedPrice {
        border: 1px solid #e5e5e5;
        border-radius: 20px;
        background: white;
        overflow-y: scroll;
        width: 460px;

        .flexContainer {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .boldTitle {
          font-size: 1rem;
          font-weight: 520;
        }

        .normalWords {
          font-size: 1rem;
        }

        .greenBtn {
          background: var(--main-color);
          color: white;
          padding: 7px 1rem;
          border-radius: 35px;
          font-weight: 450;
          font-size: 0.9rem;
          cursor: pointer;
        }

        .greyBtn {
          border: 1px solid #e5e5e5;
          padding: 7px 1rem;
          border-radius: 35px;
          font-weight: 450;
          font-size: 0.9rem;
        }

        & > .subTotal {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          gap: 1rem;
          padding: 1.2rem;
          border-bottom: 1px solid #e5e5e5;
        }

        & > .totallingContainer {
          padding: 1.2rem 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          gap: 1rem;
          border-bottom: 1px solid #e5e5e5;
          margin: 0 1.2rem;
        }

        & > .grandDiv {
          padding: 1.2rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          gap: 1rem;

          & > .agreeTerms {
            display: flex;
            gap: 1rem;
            position: relative;
            margin-top: 1rem;

            & > div {
              position: relative;
            }

            & > :nth-child(1) {
              width: 2.2rem;
            }

            & > .term {
              font-size: 0.9rem;
            }
          }

          & > .successBtn {
            padding: 0.6rem 1.5rem;
            color: white;
            background: var(--main-color);
            font-weight: 520;
            border-radius: 35px;
            cursor: pointer;
            transition: all linear 0.3s;
            outline: none;
            width: 100%;
            border: none;

            &:hover {
              scale: 1.05;
            }
          }

          & > .failedBtn {
            padding: 0.6rem 1.5rem;
            border-radius: 35px;
            color: var(--font-color);
            border: 1px solid #e5e5e5;
            font-weight: 520;
            cursor: pointer;
            transition: all linear 0.3s;
            outline: none;
            width: 100%;

            &:hover {
              scale: 1.05;
            }
          }

          & > .title {
            font-size: 1.2rem;
            font-weight: 600;
            text-align: left;
            display: flex;
            width: 100%;
            align-items: flex-start;
          }

          & > .checkoutMethod {
            border: 1px solid #e9ebf3;
            border-radius: 15px;
            padding: 1rem;
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            cursor: pointer;

            & > :nth-child(1) {
              font-size: 1rem;
              font-weight: 515;
            }

            & > :nth-child(2) {
              font-size: 0.85rem;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}

.clientOnboardingForm {
  overflow-y: scroll;
  height: 100%;
  padding-bottom: 2rem;

  & > .title {
    font-size: 1.5rem;
    font-weight: 550;
    margin-bottom: 1rem;
  }

  .subTitles {
    font-size: 1.2rem;
    font-weight: 550;
    margin-top: 1rem;
  }

  .desc {
    font-size: 0.8rem;
  }

  .tableContainer {
    overflow: scroll;

    .tableParent {
      // width: 100%;
      border: 1px solid #e5e5e5;
      border-radius: 10px;
      margin-top: 1rem;
      overflow: scroll;

      thead {
        background: #e1e1e1;
        border-radius: 10px 10px 0 0;
        width: 140%;

        & > tr {
          border-radius: 10px 10px 0 0;
        }
      }

      tbody {
        width: 140%;

        & > tr {
          border-top: 1px solid #e5e5e5;
          background: white;
        }
      }

      th,
      td {
        padding: 1rem 0.5rem;
        white-space: nowrap;
      }

      th {
        text-align: left;
        font-weight: 550;
      }

      tr {
        display: grid;
        grid-template-columns: 40px 100px repeat(5, 1fr) 60px;
        padding: 0 0.5rem;

        & > :first-child {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          font-size: 1.2rem;
        }

        & > :nth-child(2) {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        & > :last-child {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          font-size: 1.2rem;
        }
      }
    }
  }

  & > .formFields {
    font-size: 0.9rem;

    .flexContainer {
      display: flex;
      align-items: center;
      gap: 1rem;

      & > div {
        width: 50%;
      }
    }

    .eachFieldEqual {
      display: flex;
      align-items: center;
      gap: 1.2rem;

      & > .title {
        width: max-content;
        font-size: 0.9rem;
        font-weight: 550;
        text-align: left;
      }

      & > div {
        width: -webkit-fill-available;
      }
    }

    & > .basicDetails {
      display: flex;
      gap: 1rem;

      & > .companyDetails {
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
        width: 50%;

        & > .logoUpload {
          display: flex;
          align-items: center;
          gap: 1rem;

          & > span {
            font-weight: 500;
          }
        }
      }

      & > .documentForm {
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        & > .flexContainerThree {
          display: flex;
          gap: 1rem;
          align-items: center;

          & > svg {
            font-size: 1.5rem;
          }

          & > div {
            width: 45%;
          }
        }
      }
    }

    & > .newSection {
      margin-top: 1rem;

      & > .policyFields {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1.2rem;
        margin-top: 1rem;

        & > .eachField {
          display: flex;
          align-items: center;
          gap: 1rem;

          & > .title {
            width: 150px;
            font-size: 0.9rem;
            font-weight: 550;
            text-align: left;
          }

          & > div {
            width: -webkit-fill-available;
          }
        }
      }

      & > .footerBoxes {
        display: flex;
        gap: 1.2rem;

        & > div {
          display: flex;
          gap: 1rem;
          width: 50%;
          padding: 1rem;
          border-radius: 10px;
          background: #d9d9d94d;
        }

        & > :nth-child(1) {
          & > :nth-child(1) {
            display: flex;
            flex-direction: column;
            gap: 1rem;
          }
        }

        & > :nth-child(2) {
          flex-direction: column;
        }

        .eachField {
          display: flex;
          align-items: center;
          gap: 1rem;

          & > .title {
            width: 150px;
            font-size: 0.9rem;
            font-weight: 550;
            text-align: left;
          }

          & > div {
            width: -webkit-fill-available;
          }
        }
      }
    }

    & > .checkBox {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin: 2rem 0;

      & > p {
        font-size: 1rem;
        margin-bottom: 0;
      }

      & > div {
        display: flex;
        gap: 10px;
        align-items: center;

        & > label {
          display: flex;
          gap: 5px;
          align-items: center;
        }
      }
    }
  }
}

.tableContainer {
  overflow-y: auto;
  height: calc(100% - 14rem);
  width: 100%;
  /* Set the container width to 100% or any other desired value */
  // overflow-x: auto; /* Allow horizontal scrolling */
  // border-left: 0.25px solid #e5e5e5;
  // border-right: 0.25px solid #e5e5e5;
  position: relative;
  border: 1px solid #dddddd;

  &::-webkit-scrollbar {
    display: block;
    width: 7px;
    /* Set the width of the table's scrollbar */
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    height: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--font-color);
    /* Set the color of the scrollbar thumb */
    border-radius: 5px;
    /* Set the border-radius of the thumb */
    height: 80px;
    transition: all linear 0.3s;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: var(--main-color);
    /* Set the color when the scrollbar thumb is hovered */
  }

  table {
    border-collapse: collapse;
    min-width: 100%;
    /* Set the table width to 110% to ensure horizontal scrollbar */
    margin-bottom: 20px;
    table-layout: fixed;
    /* Ensures equal column width for horizontally scrolling table */
    width: max-content;
    height: 100%;

    th,
    td {
      // border: 1px solid #dddddd;
      text-align: left;
      white-space: normal;
      word-wrap: break-word;
      padding: 0.75rem 1rem;
      position: relative;
      display: flex;
      align-items: center;
      border-right: none;
      border-top: none;
      font-size: 0.85rem;

      & > input {
        width: 1.25rem;
        height: 1.25rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      & > .processing {
        background: #f8cd70;
        color: white;
        padding: 0.5rem 1.5rem;
        border-radius: 10px;
        font-weight: 500;
      }

      & > .processed {
        background: var(--opacity-main);
        color: white;
        padding: 0.5rem 1.5rem;
        border-radius: 10px;
        font-weight: 500;
      }

      & > .rejected {
        background: #ea0f0f;
        color: white;
        padding: 0.5rem 1.5rem;
        border-radius: 10px;
        font-weight: 500;
      }
    }

    th {
      white-space: nowrap;
      font-weight: 550;
      font-size: 0.85rem;
      padding: 0.7rem 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > svg {
        cursor: pointer;
      }
    }

    thead {
      background: #e1e1e1;

      & > tr {
        & > th {
          border-top: none;
        }
      }
    }

    tbody {
      position: relative;
      background: white;

      & > tr {
        border-bottom: 1px solid #dddddd;
        // border-top: none;
        position: relative;
      }

      & > :last-child {
        border: none;

        & > td {
          background: none !important;
        }

        .addNew {
          all: unset;
          padding: 0.6rem 1rem;
          width: 100%;
          background: #e5e5e5;
          font-weight: 520;
          cursor: pointer;
          text-align: center;
          border-radius: 35px;
        }
      }
    }

    tr {
      & > :nth-child(1) {
        border-left: none;
        width: 50px;
      }

      & > :last-child {
        border-right: none;
        width: 50px;
        // grid-column: 1;
      }
    }

    tr {
      display: grid;
      grid-template-columns: min-content repeat(15, 1fr);
    }

    tr > :first-child {
      /* Set the width you want for the first child */
      width: 50px;
      /* Replace 200px with your desired width */
      /* Position the first child in the first column */
      grid-column: 1;
    }

    .detailedItem {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      justify-content: flex-start;

      & > :nth-child(1) {
        padding: 0.5rem;
        border: 0.5px solid #e5e5e5;
        border-radius: 50%;
        width: 3rem;
        height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;

        & > img {
          width: 70%;
          height: 70%;
        }
      }

      & > :nth-child(2) {
        display: flex;
        flex-direction: column;
        gap: 0.2rem;

        & > :nth-child(1) {
          font-size: 0.9rem;
          font-weight: 550;
          white-space: nowrap;
        }

        & > :nth-child(2) {
          font-size: 0.8rem;
          font-weight: 400;
        }
      }
    }

    td {
      position: relative;
      transition: all linear 0.3s;

      &:hover {
        background: #8080800d;
      }

      & > .dropdownContent {
        position: absolute;
        top: 70%;
        left: 55%;
        padding: 1rem 0;
        background: white;
        z-index: 6;
        border: 1px solid #e1e1e1;
        box-shadow: 0px 4px 4px 0px #00000040;
        border-radius: 10px;
        min-width: 250px;

        & > .eachFilterItem {
          padding: 0.7rem 1.5rem;
          font-size: 1rem;
          font-weight: 400;
          cursor: pointer;
          white-space: nowrap;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          width: 100%;
          // padding-right: 4rem;

          & > svg {
            rotate: -90deg;
          }

          & > .subDropdown {
            display: none;
            min-width: 250px;
            left: 100%;
            top: 0;
            color: var(--font-color);
          }

          &:hover {
            color: var(--main-color);

            & > .subDropdown {
              display: block;
            }
          }
        }
      }

      .dropdownContent {
        position: absolute;
        top: 70%;
        left: 55%;
        padding: 1rem 0;
        background: white;
        z-index: 6;
        border: 1px solid #e1e1e1;
        box-shadow: 0px 4px 4px 0px #00000040;
        border-radius: 10px;
        min-width: 250px;

        & > .eachFilterItem {
          padding: 0.7rem 1.5rem;
          font-size: 1rem;
          font-weight: 400;
          cursor: pointer;
          white-space: nowrap;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          width: 100%;
          // padding-right: 4rem;

          & > span {
            display: flex;
            gap: 10px;
            align-items: center;

            & > svg {
              height: 1rem;
              width: 1rem;
            }
          }

          & > svg {
            rotate: -90deg;
          }

          & > .subDropdown {
            display: none;
            min-width: 250px;
            left: 100%;
            top: 0;
            color: var(--font-color);
          }

          &:hover {
            color: var(--main-color);

            & > .subDropdown {
              display: block;
            }
          }
        }
      }
    }
  }
}

.terms {
  height: 100vh;
  overflow-y: scroll;
}
.termsContainer {
  padding: 2rem;
  padding-top: 15rem;
  max-width: 1400px;
  margin: 0 auto;
  font-size: 1.2rem;
  line-height: 1.5;
  font-family: "Roboto", sans-serif;
  color: #333;
}
h1 {
  font-weight: 700;
}
h3 {
  font-weight: 700;
  font-size: 1.5rem;
}
h6 {
  font-weight: 700;
  font-size: 1.2rem;
}
p {
  font-size: 1.2rem;
}

.numberList {
  margin-left: 1.2rem;

  & > li {
    font-weight: 600;
    font-size: 1.5rem;
  }
}

.letterList {
  list-style: upper-latin;
  margin-left: 1.2rem;
}

.romanList {
  list-style: lower-roman;
  margin-left: 1.2rem;
}

.paragraphTitle {
  font-size: 1.2rem;
  font-weight: 500;
  text-decoration: underline;
}

.smallLetterList {
  list-style: lower-latin;
  margin-left: 1.2rem;
}

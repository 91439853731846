.master {
  // padding: 1.5rem 1rem;
  // padding-bottom: 1rem;
  height: 100%;
  position: relative;
  // padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;

  & > .contentContainer {
    background: white;
    width: 100%;
    height: 100%;
    // border-radius: 35px 0 0 35px;
    padding: 2rem;
    overflow: hidden;
  }
}

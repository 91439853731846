.gmap-container {
  width: 100%;
  height: 100%;
  //   border: 1px solid red;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .map-content {
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  .map-controls {
    position: absolute;
    z-index: 2;
    display: flex;
    top: 2rem;
    left: 50%;
    .reset-gBtn {
      background: white;
      padding: 1rem 2rem;
      font-weight: 500;
      cursor: pointer;
    }
  }
}

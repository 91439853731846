.popupWrapper {
  padding: 28px;
}
.popupTitle {
  color: var(--main-color);
  // font-family: "Avenir", "Sen", sans-serif;
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 20px;
}
.dropdown {
  border-radius: 5px;
  border: 1px solid #e9ebf3;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 46px;
  padding: 0px 20px;
}
.inputStyle {
  border-radius: 5px;
  border: 1px solid #e9ebf3;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 46px;
  padding: 0px 20px;
  width: 100%;
  margin-top: 20px;
}
.textareaStyle {
  margin-top: 20px;
  border-radius: 5px;
  border: 1px solid #e9ebf3;
  width: 100%;
  padding: 10px 20px;
}
.bottomContainer {
  margin-top: 20px;
  border-radius: 10px;
  border: 1px solid #e9ebf3;
  width: 100%;
  padding: 10px 20px;
  background-color: rgba(217, 217, 217, 0.3);
  display: flex;
  justify-content: space-evenly;
  gap: 20px;
}
.calendarWrapper {
  border-radius: 5px;
  background: #fff;
  height: 46px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 12px;
  padding-right: 20px;
  width: 50%;

  color: var(--main-color);
  font-family: "Avenir", "Sen", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
}
.calendarIcon {
  width: 24px;
  height: 24px;
}
.uploadContainer {
  margin-top: 10px;
  width: 40%;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  background: #fff;
  height: 179px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.upldImg {
  width: 52px;
  height: 52px;
}
.upldTxt {
  color: var(--main-color);
  text-align: center;
  font-family: "Avenir", "Sen", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  padding-top: 20px;
}

.dropdown1 {
  border-radius: 5px;
  border: 1px solid #e9ebf3;
  background: #fff;
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  height: 200px;
  padding: 0px 20px;
}
.individualQuote {
  &:hover {
    background-color: #f2f2f2;
    cursor: pointer;
  }
  div:nth-child(1) {
    color: var(--main-color);
    font-family: "Avenir", "Sen", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
  div:nth-child(2) {
    color: var(--main-color);
    font-family: "Avenir", "Sen", sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}
.addBtn {
  margin-top: 20px;
  border-radius: 5px;
  background: var(--opacity-main);
  width: 236px;
  height: 45.564px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Avenir", "Sen", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  &:hover {
    background-color: white;
    border: 1px solid #e7e7e7;
    color: black;
  }
}

.prelogin {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > .logo {
    width: 15%;
    max-width: 200px;
    object-fit: contain;
  }

  & > .message {
    font-size: 1.2rem;
    padding: 3rem 0;
  }

  & > .allApps {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 2.5rem;
    margin-top: 2rem;

    & > .eachApp {
      cursor: pointer;
      color: inherit;
      text-decoration: none;

      &:hover {
        & > .appLogo {
          border: 1px solid rgba(46, 198, 151, 1);
        }
      }

      & > .appLogo {
        background: rgba(46, 198, 151, 0.05);
        border: 1px solid rgba(46, 198, 151, 0.05);
        border-radius: 10px;
        padding: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        aspect-ratio: 1/1;
        transition: all linear 0.2s;

        & > img {
          width: 120px;
          height: 120px;
          object-fit: contain;
        }
      }

      & > .appName {
        font-size: 1rem;
        font-weight: 400;
        padding: 1rem 0;
        text-align: center;
      }
    }
  }
}

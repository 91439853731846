.portals {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row-reverse;

  & > div {
    width: 50%;
    height: 100%;
    padding: 2.5rem;
  }

  & > .leftPart {
    background: var(--opacity-main) b3;
    overflow: hidden;
    padding-right: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    & > .navigations {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4rem;
      font-size: 1.5rem;
      font-weight: 550;

      & > div {
        padding-bottom: 7px;
        border-bottom: 3px solid transparent;
        transition: all linear 0.3s;
        color: #045c40;
        cursor: pointer;
        text-align: center;
      }

      & > .selectedOption {
        border-bottom: 3px solid #045c40;
      }
    }

    & > .imageContainer {
      height: -webkit-fill-available;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      & > img {
        width: 100%;
        max-height: 80%;
        object-fit: contain;
      }
    }
  }

  & > .rightPart {
    // background: var(--opacity-main)59;
    background: white;
    color: var(--font-color);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 5rem;
    position: relative;

    & > .contentCard {
      width: 100%;
      max-width: calc(700px - 10rem);
      position: relative;

      & > .navigations {
        position: absolute;
        left: -15%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        gap: 1rem;

        & > div {
          height: 100px;
          width: 5px;
          border-radius: 20px;
          background: var(--main-color);
          cursor: pointer;
        }
      }

      & > .title {
        font-size: 2rem;
        font-weight: 550;
      }

      & > .para {
        padding: 2rem 0;
        font-size: 1.2rem;
        font-weight: 500;
        line-height: 1.5;
        margin: 0;
      }

      // & > .points {
      //   list-style: none;
      //   padding: 2rem 0;
      //   margin: 0;
      //   padding-top: 0;

      //   & > li {
      //     font-size: 1.25rem;
      //     font-weight: 550;
      //     padding-bottom: 15px;
      //     display: flex;
      //     align-items: center;
      //   }

      //   & > li:before {
      //     content: "✓";
      //     font-size: 2rem;
      //     padding-right: 10px;
      //   }
      // }
      & > .points {
        color: "var(--main-color)";
        padding: 1rem 1rem;
        & > li {
          font-size: 1.3rem;
          font-weight: 700;
          padding-bottom: 8px;
        }
      }

      & > .cardBtn {
        border: 3px solid var(--font-color);
        border-radius: 15px;
        cursor: pointer;
        text-align: center;
        padding: 0.5rem 3rem;
        width: max-content;
        font-size: 1.2rem;
        font-weight: 550;
        transition: all linear 0.3s;

        &:hover {
          border: 3px solid #045c40;
          background: #045c40;
          color: white;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .portals {
    flex-direction: column;
    height: auto;

    & > div {
      width: 100% !important;
    }

    & > .leftPart {
      display: flex;
      flex-direction: column;
      gap: 5rem;
      justify-content: space-between;
      padding-bottom: 0;
    }

    & > .rightPart {
      padding: 5rem 6% !important;
    }
  }
}

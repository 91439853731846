.popupProfileForm {
  position: fixed;
  // inset: 0;
  z-index: 8;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
  // width: 75%;
  // height: 90%;
  // aspect-ratio: 1/1.5;
  border-radius: 10px;
  inset: 0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  & > .formContainer {
    // width: 75%;
    height: 85%;
    max-height: 750px;
    aspect-ratio: 1.5/1;
    background: white;
    z-index: 9;
    border-radius: 10px;

    & > .formBody {
      height: 100%;
      padding: 2rem;
      overflow-y: scroll;
      // background: rgba(229, 229, 229, 0.2117647059);

      & > .title {
        font-size: 1rem;
        font-weight: 520;
        margin: 1rem 0;
        margin-bottom: 2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        & > div {
          & > div {
            text-align: center;
            font-size: 0.75rem;
            font-weight: 450;
            margin-top: 0.5rem;
          }
        }
      }

      & > .profileImage {
        width: 3.5rem;
        height: 3.5rem;
        border-radius: 50%;
        margin-bottom: 2rem;
      }

      & > .inputFields {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 2rem;

        & > div {
          & > :nth-child(2) {
            & > input {
              border: 1px solid #e9ebf3;

              &:focus {
                border: 1px solid var(--main-color);
              }
            }
          }
        }

        & > .dropdownParent {
          display: flex;
          flex-direction: column;
          gap: 0.6rem;

          & > .title {
            font-size: 0.9rem;
            font-weight: 500;
          }
        }

        & > .inputContainers {
          display: flex;
          gap: 2rem;

          & > div {
            flex-direction: column-reverse;

            & > label {
              border-radius: 10px;

              & > img {
                border-radius: 10px;
              }
            }
          }
        }

        & > .submitBtn {
          padding: 0.6rem 1.5rem;
          border-radius: 10px;
          background: var(--main-color);
          color: white;
          font-weight: 550;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          width: max-content;
          margin-top: 2rem;
          transition: all linear 0.3s;

          &:hover {
            scale: 1.05;
          }
        }
      }
    }
  }

  & > .blackTiltContainer {
    position: absolute;
    inset: 0;
    z-index: 8;
    background: #00000080;
  }
}

.eachInput {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;

  & > .asteriskIcon {
    position: absolute;
    right: 10px;
    top: 10px;
    height: 10px;
  }

  & > .title {
    font-size: 0.9rem;
    margin-bottom: 10px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > span {
      cursor: pointer;

      & > svg {
        font-size: 0.9rem;
      }
    }
  }

  & > div {
    display: flex;
    gap: 0rem;
    align-items: center;
    position: relative;

    & > .submitBtn {
      font-size: 0.8rem;
      position: absolute;
      padding: 6.5px 1.2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      color: white;
      background: var(--main-color);
      top: 50%;
      right: 10px;
      transform: translate(0%, -50%);
      cursor: pointer;
    }

    & > img {
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
    }

    & > .inputContainer {
      padding: 1rem 1.5rem;
      border-radius: 10px;
      border: 1px solid #e5e5e566;
      font-size: 0.8rem;
      background: #ffffff;
      // margin-top: 1rem;
      width: 100%;

      :focus {
        // outline: var(--main-color);
        background: white;
        border: 1px solid var(--main-color);
      }
    }
  }
}

.imageUploadDiv {
  // line-height: 2;
  font-size: 0.9rem;
  // font-weight: 600;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  // margin-top: 2rem;
  position: relative;
  width: max-content;

  & > .title {
    position: absolute;
    right: 0;
    z-index: 2;
  }

  & > .imageDiv {
    // margin-top: 0.5rem;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    width: max-content;

    & > .editIcon {
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;
    }

    & > img {
      min-width: 5vw;
      min-height: 5vw;
      max-width: 5vw;
      max-height: 5vw;
      border-radius: 50%;
      border: 1px solid #e5e5e5;
      transition: all ease 0.4s;
      padding: 1rem;

      // &:hover {
      //   scale: 0.95;
      //   // border: 5px solid #44c2f4;
      // }
    }

    & > .overlayDiv {
      position: absolute;
      width: 20%;
      height: 20%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      min-width: 5vw;
      min-height: 5vw;
      max-width: 5vw;
      max-height: 5vw;
      display: flex;
      justify-content: center;
      align-items: center;

      & > img {
        width: 3rem;
        height: 3rem;
        animation: heartBeat 0.5s alternate infinite;
      }

      @keyframes heartBeat {
        0% {
          scale: 1;
        }
        100% {
          scale: 1.2;
        }
      }
    }
  }

  & > .uploadFileDiv {
    font-size: 1.1rem;
    transition: all ease 0.4s;
    width: 40%;

    &:hover {
      font-size: 1.3rem;
      font-weight: 550;
    }
    & > .uploadNewPicPlanB {
      display: none;
    }
  }

  & > .submitBtn {
    position: absolute;
    top: 50%;
    left: 6.5rem;
    transform: translate(0%, -50%);
    padding: 8px 1.2rem;
    border-radius: 7px;
    border: 1px solid #e1e1e1;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    font-weight: 500;
    white-space: nowrap;
    transition: all linear 0.3s;

    &:hover {
      background: var(--main-color);
      color: white;
    }
  }
}

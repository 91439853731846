.enrollmentForm {
  height: 100vh;
  display: flex;
  background: var(--main-color);
  background-image: url("../../assest//images/prelogin/beach.svg");
  background-size: 100% auto;
  background-repeat: no-repeat;
  position: relative;

  & > .redirectingMessage {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: var(--main-color);

    #msg {
      font-size: 2.2rem !important;
      font-weight: 550 !important;
    }
    p {
      font-size: 2.2rem !important;
      font-weight: 550 !important;
    }
  }

  & > .leftPanel {
    width: 35%;
    padding: 0 1.5rem;
    padding-bottom: 1.2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    & > .headPanel {
      background: white;
      border-radius: 0 0 15px 15px;
      padding: 1.5rem 3rem;
      width: max-content;

      & > img {
        height: 3.5rem;
        object-fit: contain;
      }
    }

    & > img {
      height: 7rem;
      max-width: 65%;
      object-fit: contain;
    }
  }

  & > .rightPanel {
    width: 65%;
    padding: 2.5rem 3rem;
    border-radius: 25px 0 0 25px;
    background: white;
    overflow-y: scroll;
    position: relative;
    padding-bottom: 8rem;
    position: relative;

    & > img {
      width: 100%;
      object-fit: contain;
      margin-bottom: 2rem;
    }

    & > .successMessage {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      line-height: 1.5;

      & > :nth-child(1) {
        font-size: 2rem;
        font-weight: 550;
      }
      & > :nth-child(2) {
        font-size: 1.1rem;
        font-weight: 400;
      }
    }

    & > .receiptBtn {
      margin-top: auto;
      color: white;
      font-size: 0.9rem;
      background: var(--main-color);
      border-radius: 35px;
      padding: 0.7rem 2rem;
      display: flex;
      justify-content: center;
      width: max-content;
      padding-right: 6rem;
      font-weight: 500;
      cursor: pointer;
      border: 1px solid var(--main-color);
      transition: all linear 0.3s;

      &:hover {
        background: white;
        color: var(--main-color);
      }
    }

    & > .headPanel {
      background: var(--main-color);
      border-radius: 0 0 15px 15px;
      padding: 0.7rem 1rem;
      width: max-content;
      margin-bottom: 3rem;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      & > img {
        height: 3rem;
        object-fit: contain;
      }
    }

    & > .title {
      font-size: 1.5rem;
      font-weight: 550;
      margin-bottom: 2rem;
    }

    .multipleInputs {
      display: flex;
      align-items: flex-end;
      gap: 1.5rem;

      & > .normalBtn {
        font-size: 0.9rem;
        padding: 0.8rem 2.5rem;
        border-radius: 25px;
        background: white;
        border: 1px solid #a4a6a7;
        font-weight: 400;
        cursor: pointer;
        transition: all linear 0.3s;

        &:hover {
          border: 1px solid var(--main-color);
        }
      }

      & > .coloredBtn {
        font-size: 0.9rem;
        padding: 0.8rem 2.5rem;
        border-radius: 25px;
        background: var(--main-color);
        color: white;
        font-weight: 500;
        cursor: pointer;
        border: 1px solid var(--main-color);
        transition: all linear 0.3s;

        &:hover {
          background: white;
          color: var(--main-color);
          border: 1px solid #a4a6a7;
        }
      }
    }

    .multipleInputsMobile {
      flex-direction: column;
      align-items: flex-start;

      & > div {
        width: 100%;
      }
    }

    & > .multipleInputsTop {
      display: grid;
      grid-template-columns: auto 2fr;
      grid-column-gap: 4rem;
    }

    .multipleInputsWithTitle {
      display: flex;
      flex-direction: column;
      gap: 2rem;

      & > .title {
        font-size: 1.1rem;
        font-weight: 500;
        position: relative;
        width: max-content;

        & > img {
          position: absolute;
          top: -5px;
          right: -15px;
        }
      }
    }

    .checkBoxParent {
      display: flex;
      align-items: center;
      margin-top: 2rem;
      gap: 8px;
      font-size: 0.9rem;
    }

    & > .personalDetails {
      margin-top: 4rem;

      & > .title {
        font-size: 1.1rem;
        font-weight: 500;
      }
    }

    & > .bottomBanner {
      position: fixed;
      z-index: 2;
      right: 0rem;
      bottom: 0rem;
      width: 65%;
      background: var(--main-color);
      padding: 1rem 3rem;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      color: white;
      border-radius: 0 0 0 25px;

      & > .priceDiv {
        & > :nth-child(1) {
          font-size: 1.6rem;
          font-weight: 600;
        }

        & > :nth-child(2) {
          font-size: 0.9rem;
          font-weight: 500;
        }
      }

      & > .submitBtn {
        font-size: 0.9rem;
        padding: 0.6rem 2.5rem;
        border-radius: 35px;
        border: 1.5px solid white;
        cursor: pointer;
        font-weight: 500;
        transition: all linear 0.3s;

        &:hover {
          color: var(--main-color);
          background: white;
        }
      }
    }

    .visistaInputs {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      // flex-wrap: wrap;
      gap: 1.2rem;
      margin-top: 1rem;

      & > .title {
        font-size: 0.9rem;
        font-weight: 500;
        width: 100px;
        position: relative;

        & > img {
          position: absolute;
          top: -6px;
          right: 2.5px;
        }
      }

      & > :nth-child(2) {
        width: -webkit-fill-available;
      }

      & > .visistaInputs {
        gap: 1.2rem;
        width: 50%;
        margin: 0;
      }

      & > :nth-child(2) {
        & > .title {
          width: max-content;
        }
      }
    }
  }

  & > div {
    height: 100%;
  }
}

.customRadio {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  margin-bottom: 2rem;

  & > .title {
    font-size: 1.1rem;
    font-weight: 500;
    position: relative;
    width: max-content;

    & > img {
      position: absolute;
      top: -5px;
      right: -20px;
    }
  }

  & > .radioParent {
    display: flex;
    flex-direction: column;

    & > .eachRadioBtn {
      display: flex;
      gap: 10px;
      align-items: center;
      margin-bottom: 0.5rem;

      & > label {
        margin: 0;
      }

      input[type="radio"] {
        border: 2px solid #5f6163;
      }

      input[type="radio"]:checked {
        -webkit-appearance: none; /* Disable default styles on Safari */
        -moz-appearance: none; /* Disable default styles on Firefox */
        appearance: none; /* Disable default styles on modern browsers */
        // width: 16px; /* Set the width of the custom radio button */
        // height: 16px; /* Set the height of the custom radio button */
        // border: 2px solid gray; /* Border color for the custom radio button */
        border-radius: 50%; /* Make it round */
        background: white; /* Background color when checked */
        width: 0.8rem;
        height: 0.8rem;
        border: 3.5px solid #5f6163;
      }
    }
  }
}

//custom dropdown

.eachCustomDropdown {
  position: relative;
  min-width: 200px;
  height: 3rem;
  cursor: pointer;
  margin-top: 1.2rem;
  margin-bottom: 0.4rem;
  transition: all linear 0.3s;

  & > .title {
    font-size: 0.85rem;
    font-weight: 400;
    position: absolute;
    top: -1.75rem;
    left: 0.5rem;
    position: relative;
    width: max-content;

    & > img {
      position: absolute;
      top: -5px;
      right: -20px;
    }
  }

  & > .inputContainer {
    padding: 1rem 1.5rem;
    border-radius: 10px;
    border: 1px solid #a4a6a7;
    font-size: 0.8rem;
    background: white;
    width: 100%;
    max-width: 500px;
    position: absolute;
    top: 0rem;
    z-index: 2;
    max-height: 400px;
    overflow-y: scroll;
    transition: all ease-in 0.2s;

    & > span {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &:focus {
      outline: var(--main-color);
      border: 1.5px solid var(--main-color);
      background: white;
    }

    & > ul {
      list-style: none;
      cursor: pointer;
      margin: 0;
      margin-top: 1rem;

      & > li {
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: 1rem 0rem;

        & > img {
          width: 20px;
          height: 20px;
          border-radius: 50%;
        }
      }

      & > li:has(input) {
        padding: 0;

        & > input {
          height: 100%;
          width: 100%;
          border: none;
          outline: none;
          padding: 0.75rem 0rem;
          color: var(--font-color);
          background: transparent;
          transition: all ease-in 0.2s;

          :focus {
            border: 2px solid var(--main-color);
          }
        }
      }
    }

    ::-webkit-scrollbar {
      width: 0.5em; /* Adjust the width as needed */
      background-color: transparent; /* Adjust the background color as needed */
      display: none;
    }

    ::-webkit-scrollbar-thumb {
      background-color: transparent; /* Adjust the thumb color as needed */
      display: none;
    }
  }
}

.eachInput {
  & > .title {
    font-size: 0.85rem;
    font-weight: 400;
    position: relative;
    width: max-content;

    & > img {
      position: absolute;
      top: -5px;
      right: -20px;
    }
  }

  & > .inputContainer {
    padding: 1rem 1.5rem;
    border-radius: 10px;
    border: 1px solid #a4a6a7;
    font-size: 0.8rem;
    margin-top: 8px;
    width: 100%;
    max-width: 500px;

    &:focus {
      outline: var(--main-color);
      border: 1.5px solid var(--main-color);
      background: white;
    }
  }
}

.eachInputDatePicker {
  position: relative;

  & > .title {
    font-size: 0.85rem;
    font-weight: 400;
    position: relative;
    width: max-content;

    & > img {
      position: absolute;
      top: -5px;
      right: -20px;
    }
  }

  & > .inputContainer {
    color: var(--font-color);
    padding: 1rem 1.5rem;
    border-radius: 10px;
    border: 1px solid #a4a6a7;
    font-size: 0.8rem;
    margin-top: 8px;
    width: 100%;
    max-width: 500px;
    transition: all linear 0.3s;
    position: relative;

    // &:focus {
    //   background: white;
    //   border: 1.5px solid var(--main-color);
    // }
    // &:hover {
    //   background: white;
    //   border: 1.5px solid var(--main-color);
    // }
  }

  & > .calendarIcon {
    position: absolute;
    bottom: 1rem;
    right: 15px;
    height: 1rem;
    width: 1rem;
    z-index: 5;
  }
}

input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
}

.submitBtn1 {
  font-size: 0.9rem;
  padding: 0.6rem 2.5rem;
  border-radius: 35px;
  border: 1.5px solid white;
  cursor: pointer;
  font-weight: 500;
  transition: all linear 0.3s;

  &:hover {
    color: var(--main-color);
    background: white;
  }
}

.buttonWrapper {
  display: flex;
}

.threeColumnTable {
  margin: 1.5rem 0;
  & > thead {
    background: #e1e1e1;
    th {
      text-align: center;
    }
  }

  & > thead,
  tbody {
    width: 100%;
    border: 1px solid white;
  }

  tr {
    border-bottom: 1px solid white;

    & > :nth-child(2) {
      padding: 0 15px;
      border-left: 1px solid white;
      border-right: 1px solid white;
    }
  }

  td {
    padding: 1rem;

    & > div {
      padding: 1rem;
    }
    & > :nth-child(1) {
      border-bottom: 1px solid white;
    }
  }
}

// media query fir buttonWrapper

@media (max-width: 768px) {
  .buttonWrapper {
    padding-top: 1rem;
  }
  .headerWrapper {
    display: flex;
    flex-direction: column;
    // align-items: center;
    margin-bottom: 2rem;
  }
  .successMessage {
    text-align: center;
  }
}

.headerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}
.headerTitle {
  font-size: 1.5rem;
  font-weight: 550;
}
.txnLink {
  font-size: 1.1rem;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
  transition: all linear 0.3s;

  &:hover {
    color: #a4a6a7;
  }
}

.navbar {
  border-radius: 35px;
  max-width: 1400px;
  position: fixed;
  z-index: 5;
  width: 100%;
  top: 40px;
  left: 50%;
  transform: translate(-50%, 0%);
  background: #cdf2e6;
  // overflow: hidden;
  transition: all ease-in 0.2s;

  & > .topSection {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    padding: 0.75rem 5rem;
    min-height: 50px;
    font-weight: 550;
    font-size: 1rem;
    font-weight: 550;
    font-size: 1rem;

    & > .categories {
      display: flex;
      gap: 1.5rem;
      color: var(--main-color);

      & > div {
        cursor: pointer;

        &:hover {
          border-bottom: 2px solid var(--main-color);
        }
      }

      & > a,
      div {
        cursor: pointer;
        color: inherit;
        text-decoration: none;
        position: relative;

        &:hover {
          border-bottom: 2px solid var(--main-color);

          & > .navOptionsDropdown {
            display: flex;
          }
        }

        & > .navOptionsDropdown {
          display: none;
          flex-direction: column;
          position: absolute;
          top: 100%;
          border-radius: 20px;
          background: white;
          transition: all linear 0.3s;

          & > a {
            all: unset;
            padding: 1rem 1.5rem;
            white-space: nowrap;
            color: var(--main-color);
            cursor: pointer;
            border-radius: 20px;
            transition: all linear 0.3s;

            &:hover {
              color: white;
              background: var(--main-color);
            }
          }
        }
      }
    }

    & > .selectCountry {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      cursor: pointer;
      transition: all ease-in 0.2s;
      padding-bottom: 4px;
      position: relative;

      & > span {
        color: #166d51;
        transition: all ease-in 0.2s;
      }

      & > .dropdown {
        position: absolute;
        top: 52px;
        left: 50%;
        transform: translate(-50%, 0%);
        z-index: 5;
        max-height: 400px;
        background: white;
        border-radius: 35px;
        overflow-y: scroll;
        border: 0.5px solid rgba(153, 153, 153, 1);
        color: var(--font-color);
        min-width: 200px;

        & > input {
          padding: 0.5rem 0;
          border: none;
          outline: none;
          text-align: left;
          font-size: 0.9rem;
          font-weight: 500;
          padding: 12px 15px;
        }

        & > div {
          padding: 0.5rem 0;
          text-align: center;
          cursor: pointer;
          text-align: left;
          font-size: 0.9rem;
          font-weight: 500;
          padding-left: 15px;
          transition: all linear 0.3s;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      &:hover {
        border-bottom: 2px solid var(--main-color);
      }

      & > svg {
        transition: all ease-in 0.2s;
      }

      & > .downArrow {
        width: 10px;
        height: 10px;
        fill: #166d51;
      }

      & > .downArrowWhite {
        fill: white;
      }
    }
  }

  & > .bottomSection {
    min-height: 60px;
    padding: 1.5rem 5rem;
    border-radius: 35px;
    background: white;
    display: flex;
    justify-content: space-between;

    & > .logoDiv {
      transition: all linear 0.3s;

      & > img {
        height: 100%;
        object-fit: contain;
        transition: all linear 0.3s;
      }

      &:hover {
        scale: 1.1;
      }
    }

    & > .navOptions {
      display: flex;
      align-items: center;
      font-size: 1rem;
      gap: 2rem;

      & > div {
        display: flex;
        gap: 2rem;

        & > div {
          cursor: pointer;
        }
      }

      & > .firstNavOptions {
        font-size: 0.9rem;
        width: max-content !important;
      }

      & > :nth-child(1) {
        // min-width: 200px;
        transition: all ease-in 0.4s;

        & > div {
          transition: all ease-in 0.2s;

          &:hover {
            font-weight: 600;
          }
        }

        & > .selectedOption {
          color: var(--main-color);
          font-weight: 550;
        }
      }

      & > .btnsContainer {
        font-weight: 500;
        gap: 0.75rem;

        & > div {
          padding: 0.5rem 1.5rem;
          border-radius: 35px;
          text-align: center;
          transition: all ease-in 0.4s;

          &:hover {
            scale: 1.05;
          }
        }
        & > a {
          padding: 0.5rem 1.5rem;
          border-radius: 35px;
          text-align: center;
          transition: all ease-in 0.4s;
          text-decoration: none;
          color: inherit;

          &:hover {
            scale: 1.05;
          }
        }

        & > :nth-child(1) {
          border: 0.5px solid #999999;
        }
        & > :nth-child(2) {
          color: white;
          background: var(--opacity-main);
        }
      }
    }
  }
}

.differentApps {
  height: 100vh;
  // padding: 6%;
  // padding-top: 5%;
  padding-bottom: 2%;
  display: flex;
  justify-content: center;
  align-items: center;

  & > .mainBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    & > .title {
      font-size: 2.5rem;
      font-weight: 600;
    }

    & > .appsSelection {
      display: flex;
      gap: 2rem;
      padding-top: 2.5rem;
      padding-bottom: 1rem;

      & > div {
        font-size: 1rem;
        font-weight: 550;
        padding-bottom: 10px;
        border-bottom: 3px solid transparent;
        cursor: pointer;
        color: var(--main-color);
        transition: all linear 0.3s;
        &:hover {
          border-bottom: 3px solid var(--main-color);
        }
      }

      & > .selectedApp {
        border-bottom: 3px solid var(--main-color);
      }
    }

    & > .appContent {
      background: var(--main-color);

      // border-radius: 25px;
      padding: 3rem 5rem;
      margin: 2rem 0 0 0;
      width: 100%;
      // max-width: 1400px;
      height: calc(100% - 10rem);
      position: relative;
      max-height: 550px;
      overflow: hidden;

      & > .eachAppCard {
        height: 100%;

        width: 100%;
        display: flex;
        gap: 2rem;
        transform: translateY(150%);
        position: absolute;
        inset: 0;
        padding: 3rem 0rem;
        max-width: 1400px;
        margin: 0px auto;
        transition: all linear 0.3s;

        & > .leftCard {
          width: 40%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          & > .title {
            font-size: 1.5rem;
            font-weight: 550;
            color: white;
          }

          // & > .points {
          //   list-style: none;
          //   color: white;
          //   padding: 1rem 0;
          //   margin: 0;

          //   & > li {
          //     font-size: 1.25rem;
          //     font-weight: 550;
          //     padding-bottom: 15px;
          //     display: flex;
          //     align-items: center;
          //   }

          //   & > li:before {
          //     content: "✓";
          //     font-size: 2rem;
          //     padding-right: 10px;
          //   }
          // }
          & > .points {
            color: white;
            padding: 0rem 1rem;
            padding-top: 1rem;
            margin: 0;
            & > li {
              font-size: 1.3rem;
              font-weight: 700;
              padding-top: 1rem;
              margin: 0;
            }
          }

          & > .cardBtn {
            border: 3px solid white;
            border-radius: 25px;
            cursor: pointer;
            text-align: center;
            margin: 2rem 0 0 0;
            padding: 0.5rem;
            color: white;
            width: 45%;
            font-size: 1rem;
            font-weight: 550;
          }
        }

        & > .rightCard {
          width: 60%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          border-radius: 25px;

          & > .coverImage {
            height: 100%;
            object-fit: contain;
            border-radius: 25px;
          }

          & > .cardsDiv {
            display: flex;
            gap: 1.2rem;
            // height: 100%;
            width: 100%;
            min-height: max-content;

            & > .eachCard {
              border-radius: 25px;
              background: white;
              width: 33%;

              & > img {
                width: 100%;
                object-fit: contain;
                max-width: 100%;
                border-radius: 25px 25px 0 0;
              }

              & > .content {
                padding: 1rem;

                & > .title {
                  font-size: 1.2rem;
                  font-weight: 550;
                  color: var(--main-color);
                }

                & > .desc {
                  font-size: 0.95rem;
                  font-weight: 450;
                  padding-top: 1rem;
                }
              }
            }
          }
        }
      }

      & > .currentAppCard {
        transform: translateY(0);
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .appsSelection {
    padding: 0 1rem;
    & > div {
      font-size: 1rem !important;
      text-align: center;
    }
  }

  .appContent {
    & > .eachAppCard {
      align-items: center;
      padding: 3rem !important;
    }
  }

  .leftCard {
    width: 100% !important;
  }

  .rightCard {
    display: none !important;
  }

  .mainBox {
    .title {
      padding: 0 1rem;
    }
  }
}

.profile {
  height: 100%;

  & > .navbar {
    padding: 0rem 2rem;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    & > .leftContainer {
      display: flex;

      & > .navbarOption {
        padding: 1.7rem 1.5rem;
        font-size: 1rem;
        font-weight: 400;
        width: max-content;
        cursor: pointer;
      }
      & > .navbarOptionSelected {
        color: var(--main-color);
        font-weight: 600;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          height: 4px;
          left: 0;
          right: 0;
          bottom: 0;
          border-radius: 35px 35px 0 0;
          background: var(--main-color);
        }
      }
    }

    & > .rightContainer {
    }
  }

  & > .brokerageDetails {
    padding: 2rem 2.5rem;
    padding-bottom: 0;
    // overflow-y: scroll;
    height: calc(100% - 5rem);
    background: #8080800d;
    position: relative;

    & > .title {
      font-size: 1.5rem;
      font-weight: 500;
      margin-bottom: 2.5rem;
      padding-top: 0.5rem;
    }

    & > .dropdownItems {
      display: flex;
      align-items: flex-start;
      gap: 1.5rem;

      & > .downloadListBtn {
        background: var(--main-color);
        color: white;
        font-size: 0.9rem;
        font-weight: 500;
        padding: 0.5rem 2rem;
        border-radius: 35px;
        text-align: center;
        cursor: pointer;
        border: 1px solid var(--main-color);
        transition: all linear 0.3s;

        &:hover {
          color: var(--main-color);
          background: white;
          border: 1px solid #e5e5e5;
        }
      }
    }

    & > .allLeads {
      border: 1px solid #e5e5e5;
      border-radius: 35px 35px 0 0;
      margin-top: 2rem;
      height: calc(100% - 10rem);
      overflow: hidden;
      background: white;

      & > .nav {
        padding: 0.75rem 1rem;
        border-bottom: 1px solid #e5e5e5;
        // border-bottom: none;
        border-radius: 35px 35px 0 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > .searchDiv {
          border: 1px solid #e5e5e5;
          border-radius: 35px;
          position: relative;
          width: max-content;

          & > input {
            border-radius: 35px;
            outline: none;
            border: none;
            padding: 0.5rem 3rem;
            font-size: 0.8rem;
            width: 100%;
            max-width: 300px;

            :focus {
              border: 2px solid var(--main-color);
            }

            &::placeholder {
              opacity: 0.4;
            }
          }

          & > svg {
            position: absolute;
            top: 50%;
            left: 1rem;
            transform: translate(0%, -50%);
            height: 15px;
            width: 15px;
          }
        }

        & > .actionBtn {
          display: flex;
          align-items: center;
          gap: 1rem;
          font-size: 0.9rem;

          & > div {
            border: 1px solid #e5e5e5;
            border-radius: 35px;
            padding: 0.5rem 2.5rem;
            font-weight: 500;
            cursor: pointer;
            transition: all ease-in 0.2s;

            &:hover {
              background: #e5e5e5;
              border: 1px solid var(--main-color);
              color: var(--main-color);
            }
          }

          & > :nth-child(1) {
            color: white;
            background: var(--main-color);
            border: 1px solid var(--main-color);
          }
        }
      }

      // & > .profileTable {
      //   overflow: auto;

      //   & > .header {
      //     display: flex;

      //     & > div {
      //       min-width: 15%;
      //       height: 100%;
      //       border-left: 0.25px solid #e5e5e5;
      //       border-right: 0.25px solid #e5e5e5;
      //       border-bottom: 0.5px solid #e5e5e5;
      //       padding: 1rem;
      //       white-space: nowrap;
      //       width: max-content;
      //     }

      //     & > :nth-child(1) {
      //       width: 10%;
      //       min-width: 10%;
      //     }
      //     & > :nth-child(4) {
      //       min-width: 25%;
      //       width: 25%;
      //     }
      //   }

      //   & > .body {
      //     display: flex;

      //     & > div {
      //       min-width: 15%;
      //       height: 100%;
      //       border-left: 0.25px solid #e5e5e5;
      //       border-right: 0.25px solid #e5e5e5;
      //       border-bottom: 0.5px solid #e5e5e5;
      //       padding: 1rem;
      //       white-space: nowrap;
      //       width: max-content;
      //     }

      //     & > :nth-child(1) {
      //       width: 10%;
      //       min-width: 10%;
      //     }

      //     & > :nth-child(4) {
      //       min-width: 25%;
      //       width: 25%;
      //     }

      //     & > .detailedItem {
      //       & > :nth-child(1) {
      //         width: 1.5rem;
      //         height: 1.5rem;
      //       }

      //       & > :nth-child(2) {
      //         display: flex;
      //         flex-direction: column;
      //         gap: 0.5rem;
      //         justify-content: space-between;

      //         & > .title {
      //           font-size: 1rem;
      //         }
      //         & > .desc {
      //           font-size: 0.8rem;
      //         }
      //       }
      //     }
      //   }
      // }
    }
  }
}

.eachCustomDropdown {
  position: relative;
  min-width: 200px;
  height: 6rem;
  cursor: pointer;

  & > .inputContainer {
    padding: 1rem 1.5rem;
    border-radius: 35px;
    border: 1px solid #e5e5e566;
    font-size: 0.8rem;
    background: white;
    width: 100%;
    max-width: 500px;
    position: absolute;
    bottom: 0.3rem;
    z-index: 2;
    max-height: 400px;
    overflow-y: scroll;
    border: 1.5px solid #e1e1e1;
    font-weight: 450;
    background: rgba(229, 229, 229, 0.4);
    transition: all linear 0.3s;

    &:focus {
      background: white;
      border: 1.5px solid var(--main-color);
    }

    &:hover {
      background: white;
      border: 1.5px solid var(--main-color);

      input {
        background: #e5e5e566;
      }
    }

    & > span {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > :nth-child(1) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        & > img {
          width: 20px;
          height: 20px;
          border-radius: 50%;
        }
      }
    }

    &:focus {
      outline: var(--main-color);
      border: 1.5px solid var(--main-color);
      background: white;
    }

    & > ul {
      list-style: none;
      cursor: pointer;
      margin: 0;
      margin-top: 1rem;

      & > li {
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: 1rem 0rem;

        & > img {
          width: 20px;
          height: 20px;
          border-radius: 50%;
        }
      }

      & > li:has(input) {
        padding: 0;

        & > input {
          height: 100%;
          width: 100%;
          border: none;
          outline: none;
          padding: 0.75rem 0rem;
          color: var(--font-color);
          background: transparent;
          transition: all ease-in 0.2s;

          :focus {
            border: 2px solid var(--main-color);
          }
        }
      }
    }

    ::-webkit-scrollbar {
      width: 0.5em; /* Adjust the width as needed */
      background-color: transparent; /* Adjust the background color as needed */
      display: none;
    }

    ::-webkit-scrollbar-thumb {
      background-color: transparent; /* Adjust the thumb color as needed */
      display: none;
    }
  }
}
// src/Table.scss
.tableContainer {
  overflow-y: auto;
  height: calc(100% - 4rem);
  width: 100%; /* Set the container width to 100% or any other desired value */
  overflow-x: auto; /* Allow horizontal scrolling */
  // border-left: 0.25px solid #e5e5e5;
  // border-right: 0.25px solid #e5e5e5;
  position: relative;

  &::-webkit-scrollbar {
    display: block;
    width: 7px; /* Set the width of the table's scrollbar */
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    height: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(
      --font-color
    ); /* Set the color of the scrollbar thumb */
    border-radius: 5px; /* Set the border-radius of the thumb */
    height: 80px;
    transition: all linear 0.3s;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: var(
      --main-color
    ); /* Set the color when the scrollbar thumb is hovered */
  }

  table {
    border-collapse: collapse;
    min-width: 240%; /* Set the table width to 110% to ensure horizontal scrollbar */
    margin-bottom: 20px;
    table-layout: fixed; /* Ensures equal column width for horizontally scrolling table */
    width: max-content;

    th,
    td {
      border: 1px solid #dddddd;
      text-align: left;
      white-space: normal;
      word-wrap: break-word;
      padding: 0.75rem 1rem;
      position: relative;
      display: flex;
      align-items: center;
      border-right: none;
      border-top: none;
      font-size: 0.85rem;

      & > input {
        width: 1.25rem;
        height: 1.25rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    th {
      white-space: nowrap;
      font-weight: 550;
      font-size: 0.85rem;
      padding: 0.7rem 1rem;
    }

    thead {
      background: #e1e1e1;
      & > tr {
        & > th {
          border-top: none;
        }
      }
    }

    tr {
      & > :nth-child(1) {
        border-left: none;
        width: 50px;
      }

      & > :last-child {
        border-right: none;
      }
    }

    tr {
      display: grid;
      grid-template-columns: min-content repeat(12, 1fr);

      th,
      td {
      }
    }

    tr > :first-child {
      /* Set the width you want for the first child */
      width: 50px; /* Replace 200px with your desired width */
      /* Position the first child in the first column */
      grid-column: 1;
    }

    .detailedItem {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      justify-content: flex-start;

      & > :nth-child(1) {
        padding: 0.5rem;
        border: 0.5px solid #e5e5e5;
        border-radius: 50%;
        width: 3rem;
        height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;

        & > img {
          width: 70%;
          height: 70%;
        }
      }

      & > :nth-child(2) {
        display: flex;
        flex-direction: column;
        gap: 0.2rem;

        & > :nth-child(1) {
          font-size: 0.9rem;
          font-weight: 550;
          white-space: nowrap;
        }
        & > :nth-child(2) {
          font-size: 0.8rem;
          font-weight: 400;
        }
      }
    }

    td {
      transition: all linear 0.3s;

      &:hover {
        background: #8080800d;
      }
    }
  }
}

.eachInput {
  & > .title {
    font-size: 0.9rem;
  }

  & > .inputContainer {
    padding: 1rem 1.5rem;
    border-radius: 35px;
    border: 1px solid #e5e5e566;
    font-size: 0.8rem;
    background: #e5e5e566;
    margin-top: 1rem;
    width: 100%;
    max-width: 500px;

    &:focus {
      outline: var(--main-color);
      border: 1.5px solid var(--main-color);
      background: white;
    }
  }
}

.eachInputDatePicker {
  & > .inputContainer {
    color: var(--font-color);
    border: 1.5px solid #e1e1e1;
    padding: 1rem 1.5rem;
    border-radius: 35px;
    border: 1px solid rgba(229, 229, 229, 0.4);
    font-size: 0.8rem;
    background: rgba(229, 229, 229, 0.4);
    margin-top: 1rem;
    width: 100%;
    max-width: 500px;
    transition: all linear 0.3s;

    // &:focus {
    //   background: white;
    //   border: 1.5px solid var(--main-color);
    // }
    // &:hover {
    //   background: white;
    //   border: 1.5px solid var(--main-color);
    // }
  }
}

.eachInputPickOne {
  & > .title {
    font-size: 0.9rem;
  }

  & > .selectOneAllOptions {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    font-size: 1rem;
    margin-top: 0.5rem;

    & > div {
      display: flex;
      text-align: center;
      width: max-content;
      padding: 0.5rem 1.5rem;
      cursor: pointer;
      font-size: 0.9rem;
      background: white;
      font-weight: 450;
      transition: all linear 0.3s;

      &:hover {
        color: white;
        border: 1px solid var(--main-color);
        background: var(--main-color);
      }
    }
  }
}

.addDependents {
  width: 70%;
  max-width: 500px;
  border-left: 0.5px solid #e5e5e5;
  // border-top: 0.5px solid #e5e5e5;
  position: absolute;
  top: 0px;
  bottom: 0;
  right: 0;
  padding: 2rem;
  overflow-y: scroll;
  background: white;
  z-index: 2;
  transition: all linear 0.3s;

  & > .title {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }

  & > .allInputsParent {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  & > .submitButton {
    padding: 0.8rem 1.5rem;
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    background: var(--main-color);
    color: white;
    border-radius: 35px;
    margin-top: 2rem;
  }
}

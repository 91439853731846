.brands {
  background: white;
  padding-top: 10rem;
  padding-bottom: 5rem;

  & > .brandsContainer {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;

    & > .title {
      font-size: 2.5rem;
      font-weight: 600;
      text-align: center;
    }

    & > .allBrands {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 2.5rem;
      margin-top: 5rem;
      padding-bottom: 4rem;

      & > div {
        display: flex;
        justify-content: center;
        align-items: center;

        & > img {
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  & > .demoContainer {
    width: 100%;
    background: var(--main-color);

    & > .content {
      width: 100%;
      max-width: 1400px;
      margin: 0 auto;
      padding: 5rem 3rem;
      color: white;
      font-size: 2rem;
      font-weight: 500;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 2rem;

      & > .para {
        width: 50%;
        max-width: 600px;
      }

      & > .btn {
        width: max-content;
        height: max-content;
        white-space: nowrap;
        cursor: pointer;
        padding: 1rem 2.5rem;
        border-radius: 35px;
        border: 1px solid white;
        font-size: 1rem;
        font-weight: 550;
        text-decoration: none;
        color: inherit;
        transition: all linear 0.3s;

        &:hover {
          background: white;
          color: var(--main-color);
        }
      }
    }
  }
}

